<template>
    <main-nav></main-nav>
    <div v-if="!didEnterPassword" class="flex min-h-screen items-center w-full justify-center">
        <div class="container flex justify-center items-center h-full">
            <div class="bg-black-800 max-w-lg flex flex-col justify-center items-center p-9 md:-mt-0">
                <div class="text-6xl p-9 text-black-500">
                    <i :class="`fa-thin fa-lock`"></i>
                </div>
                <div class="relative w-full py-9 max-w-lg mx-auto">
                    <input
                        autocomplete="new-password"
                        class="pl-10 pr-3 md:text-5xl text-3xl py-3 w-full rounded-full bg-black-600 text-black-100 border-transparent text-center"
                        type="password"
                        @input="onInput"
                        @keydown.enter="checkPassword"
                        placeholder="********"
                    />
                </div>
                <div :class="`${!didPassCheck && didPassCheck != null ? 'text-red-400' : 'text-transparent'} italic p-6`">Sorry password is incorrect</div>
            </div>
        </div>
    </div>

    <div class="container" v-if="isLoaded && didEnterPassword">
        <div class="flex flex-col pt-9">
            <div class="flex flex-col">
                <h2 class="text-6xl mb-9">Analytics</h2>
                <!-- days -->
                <div class="overflow-hidden relative flex w-full md:justify-center bg-black-700 mb-3">
                    <div class="relative overflow-x-scroll flex snap-x snap-mandatory px-6 py-3 gap-3 scrollbar-hide ml-4 md:ml-0">
                        <a
                            :href="`#${date}`"
                            :key="day"
                            v-for="(day, date) in days"
                            class="h-[40px] px-5 flex items-center justify-center bg-black-600 rounded-full whitespace-nowrap snap-start cursor-pointer hover:bg-teal-200 hover:text-black-600 transition-all"
                        >
                            <div>{{ day.date.format("MMM Do") }}</div>
                        </a>
                    </div>
                </div>

                <div v-for="(day, date) in days" :key="`day-${date}`" class="" :id="date">
                    <div class="my-20 mb-3 text-teal-200">
                        <div class="max-w-sm flex items-center justify-center">
                            <div class="text-4xl">{{ day.date.format("MMMM Do, YYYY") }}</div>
                            <a class="bg-teal-200 rounded-full ml-3 py-2 text-black-700 px-6 my-4 cursor-pointer whitespace-nowrap" @click="exportDay(day)">Export CSV</a>
                        </div>
                    </div>
                    <div class="mb-9">
                        <div v-for="session in day" class="mb-9 bg-black-500">
                            <div class="py-6 flex flex-col items-center justify-center h-full">
                                <router-link :to="`${session.url}`" class="text-white text-3xl mb-2 hover:underline">
                                    {{ session.title }}
                                </router-link>
                                <div class="flex gap-3">
                                    <div class="text-white text-sm">
                                        <i class="fa fa-user mr-1"> </i> {{ session.speakers.length }} {{ session.speakers.length > 1 ? "Speakers" : "Speaker" }}
                                    </div>
                                    <div class="text-white text-sm"><i class="fa fa-clock mr-1"> </i> {{ session.timeRange }}</div>
                                </div>
                            </div>
                            <!-- speaker tabs -->
                            <div class="w-full bg-black-700">
                                <!-- v-if="session.speakers.length > 1"  -->
                                <div class="p-3 flex flex-wrap text-sm font-medium text-center gap-3 mt-3 mb-6 bg-black-600">
                                    <div v-for="speaker in session.speakers" class="">
                                        <a
                                            @click="session.activeTab = speaker._id"
                                            :class="`flex py-3 px-3 cursor-pointer ${
                                                session.activeTab === speaker._id ? 'bg-teal-200 text-black-800' : 'bg-black-700 text-white '
                                            } `"
                                        >
                                            <div class="flex flex-col">
                                                <span>{{ speaker.name }}</span>
                                                <span class="text-2xs"
                                                    >{{ speaker.comments.length }} comments | {{ speaker.averageRating.toFixed(2) }} <i class="fa fa-star"></i>
                                                </span>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <!-- end speaker tabs -->

                                <!-- speakers content -->
                                <div
                                    v-for="speaker in session.speakers"
                                    :class="`dark:bg-gray-800 p-3 ${session.activeTab === speaker._id ? '' : 'hidden'}`"
                                    :id="`speaker-${speaker._id}`"
                                >
                                    <!-- name and photo -->
                                    <div>
                                        <div class="flex flex-col">
                                            <img :src="speaker.photo.url" alt="" class="w-20 h-20 mb-2 overflow-hidden object-cover" />
                                            <router-link class="text-white text-xl hover:underline" :to="`${session.url}?speaker=${speaker._id}`">
                                                {{ speaker.name }}
                                            </router-link>
                                        </div>
                                        <div>
                                            <div class="text-sm text-black-300">
                                                {{ speaker.jobTitle }}
                                            </div>
                                        </div>
                                    </div>

                                    <!-- session rating -->
                                    <div class="my-3">
                                        <div class="text-lg text-black-300">Average Rating</div>
                                        <div class="text-2xs">{{ speaker.ratings.length }} ratings | {{ speaker.totalRatings }} ratings total</div>
                                    </div>
                                    <!-- ratings -->
                                    <stars :rating="speaker.averageRating" :interactive="false" />
                                    <!-- comments -->
                                    <div class="mt-6">
                                        <div>
                                            <div class="py-3 text-xl">What are people saying...</div>
                                            <div class="text-2xs">{{ speaker.comments.length }} comments</div>
                                        </div>
                                        <div class="grid grid-cols-1 md:grid-cols-3 gap-4 mt-3">
                                            <div v-for="group in group(speaker.comments)" class="">
                                                <div class="grid gap-4 mb-4" v-for="comment in group" :key="comment.id">
                                                    <div>
                                                        <div class="p-3 bg-black-600 ital">
                                                            {{ comment.text }}
                                                        </div>
                                                        <div class="p-3 bg-black-500">
                                                            <div class="flex text-xs justify-between">
                                                                <div class="italic">- {{ comment.user.username }}</div>
                                                                <a class="hover:text-teal-200 hover:underline cursor-pointer" @click="translate(comment)">Translate</a>
                                                            </div>
                                                            <div class="flex gap-4">
                                                                <div v-for="r in comment.totalReactions">
                                                                    <span>{{ r.count }}</span>
                                                                    <i :class="`mt-3 ml-2 fa fa-${r.icon}`"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- <div v-for="commment in speaker.commments">
                                            {{ commment }}
                                        </div> -->
                                    </div>
                                </div>
                                <!-- end speakers content -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="error">
        {{ error }}
    </div>
    <main-footer></main-footer>
</template>

<script>
import moment from "moment-timezone";
import Stars from "@/components/Stars.vue";
import store from "@/store";
export default {
    name: "AnalyticsView",
    components: {
        Stars,
    },
    mixins: [],
    data() {
        return {
            secret: "ideo2024",
            isLoaded: false,
            days: {},
            error: null,
            didEnterPassword: false,
            didPassCheck: null,
        };
    },
    computed: {},
    methods: {
        async translate(comment) {
            console.log("translate", comment);

            let resp = await fetch(process.env.VUE_APP_API_URL + "/translate", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ text: comment.text, lang: store.state.language }),
            });

            let data = await resp.json();

            if (!resp.ok) {
                console.error(data.error);
            }

            comment.lang = comment.lang || {};
            comment.lang[store.state.language] = data.text;
            console.log(data);
        },
        checkPassword(e) {
            this.didPassCheck = null;
            let password = e.target.value;
            if (password === this.secret) {
                this.didEnterPassword = true;
                this.$cookies.set("didEnterPassword", password, "1d");
                this.loadData();
            } else {
                this.didPassCheck = false;
            }
        },
        group(items) {
            const firstColumn = items.filter((_, index) => index % 3 === 0);
            const secondColumn = items.filter((_, index) => index % 3 === 1);
            const thirdColumn = items.filter((_, index) => index % 3 === 2);
            let cols = [firstColumn, secondColumn, thirdColumn];

            // sort cols on total cols comments text length
            cols.sort((a, b) => {
                let aTotal = a.reduce((acc, curr) => acc + curr.text.length, 0);
                let bTotal = b.reduce((acc, curr) => acc + curr.text.length, 0);
                return bTotal - aTotal;
            });

            return cols;
        },

        exportDay(day) {
            // Initialize CSV content
            let csvContent = "data:text/csv;charset=utf-8,";
            csvContent += "Title,Company,Speaker,Av Rating,Comment\n"; // Adding column headers

            // Iterate through each session in the JSON data
            day.forEach((session) => {
                const sessionTitle = session.title;
                const company = session.company || "N/A"; // Default to 'N/A' if company is not available

                // Iterate through each speaker in the session
                session.speakers.forEach((speaker) => {
                    const speakerName = speaker.name;
                    const averageRating = speaker.averageRating || "N/A"; // Default to 'N/A' if averageRating is not available

                    // Iterate through each comment for the speaker
                    speaker.comments.forEach((comment) => {
                        // Construct the CSV row content
                        const rowContent = [
                            sessionTitle,
                            company,
                            speakerName,
                            averageRating,
                            comment.text.replace(/"/g, '""'), // Escape double quotes
                        ]
                            .map((field) => `"${field}"`)
                            .join(",");

                        // Add the row to the CSV content
                        csvContent += rowContent + "\n";
                    });
                });
            });

            // Encode the CSV content
            const encodedUri = encodeURI(csvContent);

            // Create a link element for the download
            const link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", `${day.date.format("MMMM-DD-YYYY")}.csv`);
            document.body.appendChild(link); // Required for Firefox

            // Simulate a click on the link to start the download
            link.click();

            // Remove the link after triggering the download
            document.body.removeChild(link);
        },
        async loadData() {
            this.isLoaded = false;
            let resp = await fetch(process.env.VUE_APP_API_URL + "/analytics");
            let data = await resp.json();
            if (!resp.ok) {
                console.error(data.error);
                this.error = "Error fetching data from the server. Please try again later.";
                return;
            }

            this.days = data;

            for (let date in this.days) {
                let day = this.days[date];
                day.date = moment(date);

                for (let session of day) {
                    session.start = this.$time(session.start);
                    session.end = this.$time(session.end);

                    session.duration = session.end.diff(session.start, "minutes");
                    session.timeRange = `${session.start.format("h:mm A")} - ${session.end.format("h:mm A")}`;

                    session.activeTab = session.speakers[0]._id;
                }

                //
            }
            this.isLoaded = true;
        },
    },
    created() {},
    async mounted() {
        this.isLoaded = false;

        // check if user has entered password
        let password = this.$cookies.get("didEnterPassword");
        if (password === this.secret) {
            this.didEnterPassword = true;
            await this.loadData();
        }
    },
};
</script>

<style scoped lang="scss">
input::placeholder {
    @apply text-3xl md:text-6xl text-center text-black-500;
    position: relative !important;
    transform: translateX(-10px) translateY(15%) !important;
}
</style>
