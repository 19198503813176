<template>
    <div class="flex">
        <a v-for="i in stars" @mouseover="() => (overIndex = i)" @mouseleave="() => (overIndex = -1)" class="px-1 cursor-pointer" @click="setRating(i)" :key="`star-${i}`">
            <div :class="` text-black-600 rounded-full w-9 h-9 flex items-center justify-center ${rolloverColor(i)}`">
                <i class="fa-solid fa-star"></i>
            </div>
        </a>
    </div>
</template>

<script>
import { setRatingForSpeakerSession, getRatingForSpeakerSession } from "@/auth";

export default {
    props: {
        sessionId: {
            type: String,
            required: true,
        },
        speakerId: {
            type: String,
            required: true,
        },
    },
    name: "Rating",
    components: {},
    mixins: [],
    data() {
        return {
            index: 0,
            overIndex: -1,
            stars: 5,
        };
    },
    computed: {},
    methods: {
        rolloverColor(i) {
            if (this.overIndex >= 0) {
                if (this.overIndex <= this.index && i < this.overIndex) {
                    return "bg-amber-200";
                } else if (i <= this.overIndex) {
                    return "bg-amber-200";
                }
                return "bg-white";
            }

            if (i <= this.index) {
                return "bg-amber-200";
            }
            return "bg-white";
        },
        hover() {
            console.log("hover");
        },
        async setRating(i) {
            let rating = i;

            this.index = rating;

            await setRatingForSpeakerSession(this.sessionId, this.speakerId, rating);
        },
    },
    created() {},
    async mounted() {
        this.index = 0;
        this.overIndex = -1;

        // get rating
        this.index = await getRatingForSpeakerSession(this.sessionId, this.speakerId);
    },
};
</script>

<style scoped lang="scss"></style>
