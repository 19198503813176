<template>
    <!-- user menu input -->
    <div v-if="showUserNameInput" class="fixed top-0 left-0 h-full w-full bg-black-700 popover-2">
        <user-profile-form @close="toggleNameInput" />
    </div>

    <!-- mobile menu -->
    <div class="fixed top-0 left-0 h-full w-full bg-black-700 popover-1" :class="{ hidden: !menuOpen }">
        <div class="flex flex-col items-start mt-[100px] p-9 justify-start h-full">
            <button
                @click="
                    () => {
                        showUserNameInput = true;
                    }
                "
                class="text-white py-9 flex items-center justify-center"
            >
                <i class="fa-duotone fa-user mr-3"></i>
                {{ $store.state.user.username }}
            </button>
            <div class="mb-3">
                <logo />
            </div>

            <a v-for="link in $store.state.menu" @click.stop="openLink(link)" class="text-5xl text-left mb-3 font-thin text-white">{{ link.title }}</a>
        </div>
    </div>

    <!-- search menu -->
    <div class="absolute top-0 left-0 min-h-screen w-full bg-black-700 popover-1" :class="{ hidden: !searchOpen }">
        <div class="cover-full bg-black-900 fixed z-10 top-0 bottom-0 w-full h-full"></div>
        <search :sessions="$store.state.sessions" @close="$store.commit('closeSearch')" :active="searchOpen" class="z-20 relative mt-9 container" />
    </div>

    <!-- main nav -->
    <nav class="bg-dark-300 relative py-4" id="home">
        <div class="flex items-center justify-between container">
            <router-link to="/" class="text-white">
                <logo />
            </router-link>
            <div class="flex">
                <ul class="md:flex gap-4 justify-center items-center hidden">
                    <li v-for="link in $store.state.menu">
                        <router-link :to="link.url" class="link">{{ link.title }}</router-link>
                    </li>

                    <li>
                        <a @click="toggleNameInput" class="text-white flex items-center justify-center hover:text-teal-200 cursor-pointer">
                            <i class="fa-duotone fa-user"></i>
                        </a>
                    </li>
                    <li v-if="hasSearch">
                        <a @click="toggleSearch" class="text-white flex items-center justify-center hover:text-teal-200 cursor-pointer">
                            <i class="fa fa-search"></i>
                        </a>
                    </li>
                    <li>
                        <language-switcher />
                    </li>
                </ul>
            </div>

            <div class="flex gap-3 justify-center items-center md:hidden">
                <hamburger v-if="!searchOpen" @click="toggleMenu" class="md:hidden popover-3" :show="$store.state.menuOpen" />
                <div v-if="hasSearch">
                    <a @click="toggleSearch" class="text-white flex items-center justify-center hover:text-teal-200 cursor-pointer">
                        <i class="fa fa-search"></i>
                    </a>
                </div>
            </div>
            <hamburger @click="toggleNameInput" class="hidden md:block popover-3" v-if="showUserNameInput" :show="showUserNameInput" />
        </div>
    </nav>
</template>

<script>
import Hamburger from "./Hamburger.vue";
import UserProfileForm from "./UserProfileForm.vue";
import LanguageSwitcher from "./LanguageSwitcher.vue";
export default {
    props: {
        hasSearch: {
            type: Boolean,
            default: true,
        },
    },
    name: "main-nav",
    components: {
        Hamburger,
        UserProfileForm,
        LanguageSwitcher,
    },
    mixins: [],
    data() {
        return {
            showUserNameInput: false,
        };
    },
    methods: {
        toggleSearch() {
            this.$store.commit("toggleSearch");
        },
        toggleNameInput() {
            if (this.showUserNameInput) {
                this.showUserNameInput = false;
                this.$store.state.menuOpen = false;
            } else {
                this.showUserNameInput = true;
                this.$store.state.menuOpen = true;
            }
        },
        toggleMenu() {
            this.$store.commit("toggleMenu");
            this.showUserNameInput = false;
            if (this.showUserNameInput && this.$store.state.menuOpen) {
            }
        },
        openLink(link) {
            this.$store.commit("closeMenu");
            this.$router.push(link.url);
        },
        close() {
            this.showUserNameInput = false;
            this.$store.commit("closeMenu");
        },
    },
    computed: {
        menuOpen() {
            return this.$store.state.menuOpen;
        },
        searchOpen() {
            return this.$store.state.searchOpen;
        },
    },
    created() {},
    mounted() {},
};
</script>

<style scoped lang="scss">
.link {
    @apply text-white uppercase inline-block -mb-1;
    text-decoration: none;

    &:after {
        display: block;
        content: "";
        width: inherit;
        border-bottom: solid 2px black;
        @apply border-b-teal-200;
        transform: scaleX(0);
        transition: transform 250ms ease-in-out;
    }
    &:hover:after {
        transform: scaleX(1);
    }
}
</style>
