const animals = [
    "Raccoon",
    "Iguana",
    "Llama",
    "Panda",
    "Giraffe",
    "Antelope",
    "Beaver",
    "Cheetah",
    "Dolphin",
    "Eagle",
    "Falcon",
    "Gecko",
    "Hippo",
    "Jaguar",
    "Koala",
    "Lemur",
    "Mongoose",
    "Narwhal",
    "Ocelot",
    "Parrot",
    "Quail",
    "Rabbit",
    "Salamander",
    "Tiger",
    "Urchin",
    "Vulture",
    "Walrus",
    "Xerus",
    "Yak",
    "Zebra",
    "Alligator",
    "Buffalo",
    "Cobra",
    "Duck",
    "Elephant",
    "Frog",
    "Gorilla",
    "Hare",
    "Impala",
    "Jellyfish",
    "Kangaroo",
    "Lobster",
    "Moose",
    "Newt",
    "Octopus",
    "Penguin",
    "Quokka",
    "Rat",
    "Shark",
    "Turtle",
    "Unicorn",
    "Vaquita",
    "Wolf",
    "Xenops",
    "Yellowjacket",
    "Zebu",
    "Axolotl",
    "Baboon",
    "Chameleon",
    "Dingo",
    "Emu",
    "Flamingo",
    "Gazelle",
    "Hummingbird",
    "Ibex",
    "Jackal",
    "Kiwi",
    "Leopard",
    "Macaw",
    "Numbat",
    "Oryx",
    "Puffin",
    "Quetzal",
    "Rhinoceros",
    "Seahorse",
    "Tapir",
    "Uakari",
    "Viper",
    "Weasel",
    "X-ray Fish",
    "Yak",
    "Zorilla",
    "Armadillo",
    "Bonobo",
    "Cougar",
    "Drake",
    "Echidna",
    "Ferret",
    "Grizzly",
    "Hyena",
    "Indri",
    "Jackrabbit",
    "Kinkajou",
    "Lynx",
    "Manatee",
    "Nightingale",
    "Opossum",
    "Porcupine",
    "Quoll",
    "Rook",
    "Squirrel",
    "Tarsier",
    "Umbrellabird",
    "Vicuña",
    "Wombat",
    "Xiphosuran",
    "Yellowfin Tuna",
    "Zooplankton",
];
const adjectives = [
    "Joyful",
    "Cheerful",
    "Happy",
    "Lively",
    "Bright",
    "Sunny",
    "Energetic",
    "Vibrant",
    "Radiant",
    "Gleaming",
    "Sparkling",
    "Shining",
    "Glowing",
    "Beaming",
    "Luminous",
    "Brilliant",
    "Colorful",
    "Vivid",
    "Dazzling",
    "Bubbly",
    "Jolly",
    "Blissful",
    "Delightful",
    "Pleasant",
    "Pleasing",
    "Charming",
    "Playful",
    "Merry",
    "Sweet",
    "Heartwarming",
    "Uplifting",
    "Optimistic",
    "Hopeful",
    "Content",
    "Grateful",
    "Blessed",
    "Fortunate",
    "Lucky",
    "Wonderful",
    "Fabulous",
    "Marvelous",
    "Splendid",
    "Magical",
    "Enchanting",
    "Dreamy",
    "Serene",
    "Peaceful",
    "Relaxed",
    "Calm",
    "Restful",
    "Soothing",
    "Gentle",
    "Tender",
    "Kind",
    "Friendly",
    "Welcoming",
    "Warm",
    "Loving",
    "Affectionate",
    "Caring",
    "Devoted",
    "Passionate",
    "Zealous",
    "Eager",
    "Keen",
    "Fervent",
    "Animated",
    "Jaunty",
    "Zesty",
    "Vivacious",
    "Exuberant",
    "Buoyant",
    "Sprightly",
    "Effervescent",
    "Frolicsome",
    "Lighthearted",
    "Jubilant",
    "Elated",
    "Overjoyed",
    "Ecstatic",
    "Thrilled",
    "Exhilarated",
    "Delighted",
    "Satisfied",
    "Fulfilled",
];

export const randomUsername = () => {
    const animal = animals[Math.floor(Math.random() * animals.length)];
    const adjective = adjectives[Math.floor(Math.random() * adjectives.length)];
    return `${adjective} ${animal}`;
};
