import { getDatabase, ref, get, set, onValue, push } from "firebase/database";
import { initializeApp } from "firebase/app";
import { getAuth, signInAnonymously } from "firebase/auth";
import { randomUsername } from "./random-username";
import store from "./store";
import VueCookies from "vue-cookies";

const env = process.env.VUE_APP_ENV;
console.log("ENV: ", env);
const firebaseConfig = {
    apiKey: "AIzaSyAZ0rWxBJEixa-a4vrfzrZrYfT-kxFnHRo",
    authDomain: "ideo-apps.firebaseapp.com",
    databaseURL: "https://intercorp2024.firebaseio.com/",
    projectId: "ideo-apps",
    storageBucket: "ideo-apps.appspot.com",
    messagingSenderId: "748248004587",
    appId: "1:748248004587:web:ebdaff01b0f2372dd84bae",
};

const app = initializeApp(firebaseConfig);
const db = getDatabase(app);
const auth = getAuth(app);

export const addSpeakerSessionComment = async (comment) => {
    let { sessionId, speakerId } = comment;

    // Check if sessionID is null or undefined
    if (!sessionId) {
        console.error("Session ID is null or undefined.");
        return; // Exit the function if no sessionID
    }

    if (!speakerId) {
        console.error("Speaker ID is null or undefined.");
        return; // Exit the function if no sessionID
    }

    comment.lang = comment.lang || {};

    const commentsRef = ref(db, `${env}/comments/session-${sessionId}/speaker-${speakerId}`);

    try {
        let res = await push(commentsRef, comment); // Add the comment to the database
        return res;
    } catch (error) {
        console.error("Error adding comment: ", error);
    }
};

export const getTranslationFromKey = async (obj, key) => {
    const lang = store.state.lang;
    const id = obj._id;
    const text = obj[key];
    if (!id) {
        console.error("No ID found in object");
        return obj[key];
    }
    if (!text) {
        console.error("No text found in object");
        return {};
    }

    // do we have the translation in firebase cache
    const translationRef = ref(db, `${env}/translations/${id}`);
    const snapshot = await get(translationRef);
    const data = snapshot.val();

    if (data) {
        console.log("cache", data);
        obj.translations = data;
        return data;
    }

    // get the translation from the api
    obj.isTranslating = true;
    let resp = await fetch(process.env.VUE_APP_API_URL + "/translate", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ text: text, lang: lang }),
    });

    let json = await resp.json();
    let translations = json.translations;

    // save the translation to firebase
    await set(translationRef, translations);

    obj.isTranslating = false;
    obj.translations = translations;

    return translations;
};

export const deleteSessionComment = async (sessionID, speakerId, commentID) => {
    // Check if sessionID or commentID is null or undefined
    if (!sessionID || !commentID || !speakerId) {
        console.error("Session ID or Comment ID is null or undefined.");
        return; // Exit the function if no sessionID or commentID
    }

    const commentRef = ref(db, `${env}/comments/session-${sessionID}/speaker-${speakerId}/${commentID}`);
    console.log("Deleting comment: ", commentRef);

    try {
        await set(commentRef, null); // Set the comment to null to delete it
    } catch (error) {
        console.error("Error deleting comment: ", error);
    }
};

export const registerForSession = async (sessionId, value) => {
    const userRef = ref(db, `${env}/users/${auth.currentUser.uid}/sessions/${sessionId}`);
    return set(userRef, value);
};

export const translateComment = async (comment) => {
    const lang = store.state.lang;
    if (comment.lang[lang]) {
        return comment;
    } else {
        comment.translationState = "loading";
        console.log("Translating comment: ", comment.speakerId);
        let resp = await fetch(process.env.VUE_APP_API_URL + "/translate", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ text: comment.text, lang: store.state.language }),
        });

        let data = await resp.json();

        if (data.translations) {
            comment.lang = comment.lang || {};
            comment.lang = data.translations;
        }

        comment.translationState = "done";

        // update the comment
        const commentRef = ref(db, `${env}/comments/session-${comment.sessionId}/speaker-${comment.speakerId}/${comment.id}`);
        await set(commentRef, comment);

        return comment;
    }
};

export const getCommentsForSpeakerSession = async (sessionId, speakerId) => {
    return new Promise((resolve, reject) => {
        const commentsRef = ref(db, `${env}/comments/session-${sessionId}/speaker-${speakerId}`);
        onValue(commentsRef, async (snapshot) => {
            const commentsData = snapshot.val();
            if (commentsData) {
                // Prepare an array to hold all promises for user data fetching
                const userFetchPromises = [];

                // Loop through all comments to fetch corresponding user data
                for (const commentId in commentsData) {
                    const comment = commentsData[commentId];
                    comment.id = commentId; // Assign the comment ID to the comment object
                    if (comment.userId) {
                        const userFetchPromise = get(ref(db, `${env}/users/${comment.userId}`)).then((userSnapshot) => {
                            const userData = userSnapshot.val();
                            // Assign the username to the comment object
                            comment.username = userData ? userData.username : "Unknown";
                        });
                        userFetchPromises.push(userFetchPromise);
                    }

                    // set language if we have it
                    comment.lang = comment.lang || {};
                }

                // Wait for all user data to be fetched and assigned to the respective comments
                await Promise.all(userFetchPromises);

                // Now all comments should have their respective username, pass them to the results callback
                resolve(commentsData);
            } else {
                // No comments data found, pass null or empty object/array
                resolve({});
            }
        });
    });
};

export const addReactionToComment = async (reaction) => {
    const { sessionId, speakerId, commentId, type } = reaction;
    console.log(reaction);
    // Check if sessionID or commentID is null or undefined
    if (!sessionId || !commentId || !speakerId) {
        console.error("Session ID or Comment ID is null or undefined.");
        return; // Exit the function if no sessionID or commentID
    }

    reaction.userId = auth.currentUser.uid;
    reaction.timestamp = Date.now();

    const commentRef = ref(db, `${env}/comments/session-${sessionId}/speaker-${speakerId}/${commentId}/reactions/${type}/${reaction.userId}`);

    try {
        await set(commentRef, reaction); // Add the reaction to the database
    } catch (error) {
        console.error("Error adding reaction: ", error);
    }
};

export const generateRandomUsername = () => {
    return randomUsername();
};

export const setUsername = (username) => {
    // save to the database
    const userRef = ref(db, `${env}/users/${auth.currentUser.uid}`);
    set(userRef, {
        username,
    });
};

// ------------------------------------------------------------------------------------------------
export const saveSearchTerms = async (searchTerm) => {
    const searchRef = ref(db, `${env}/searches/${auth.currentUser.uid}`);
    push(searchRef, {
        searchTerm,
    });
};

// ratings / sessionID / speakerId / userID / rating
// ------------------------------------------------------------------------------------------------

export const setRatingForSpeakerSession = (sessionId, speakerId, rating) => {
    const userRef = ref(db, `${env}/ratings/${sessionId}/${speakerId}/${auth.currentUser.uid}`);
    set(userRef, {
        rating,
    });
    return rating;
};

// ------------------------------------------------------------------------------------------------
export const getRatingForSpeakerSession = async (sessionId, speakerId) => {
    const userRef = ref(db, `${env}/ratings/${sessionId}/${speakerId}/${auth.currentUser.uid}`);
    const snapshot = await get(userRef);
    const data = snapshot.val();
    return data ? data.rating : null;
};

// sign ing anonymously
// ------------------------------------------------------------------------------------------------
export const signIn = async () => {
    return new Promise((resolve, reject) => {
        signInAnonymously(auth)
            .then(async () => {
                const userRef = ref(db, `${env}/users/${auth.currentUser.uid}`);

                onValue(userRef, async (snapshot) => {
                    const userData = snapshot.val() || {};

                    if (!userData.sessions) {
                        userData.sessions = {};
                    }
                    if (!userData.username) {
                        const username = randomUsername();
                        userData.username = username;
                        await set(ref(db, `${env}/users/${auth.currentUser.uid}`), { username });
                    }

                    // does the user have the password cookie
                    userData.hasAppPassword = await authCheck();

                    let user = { ...userData, ...auth.currentUser };
                    store.state.user = user;

                    // get language cookie
                    let lang = VueCookies.get("language");
                    store.state.language = lang || "en";

                    resolve(user);
                });
            })
            .catch((error) => {
                console.log(error);
                resolve(null);
            });
    });
};

export const checkAppPassword = async (password) => {
    try {
        let resp = await fetch(process.env.VUE_APP_API_URL + "/check-password", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ password: password }),
        });
        let data = await resp.json();

        // put the password in a cookie
        if (data.success) {
            VueCookies.set(process.env.VUE_APP_PASSOWRD_COOKIE_NAME, password, "7d");
        }

        return data;
    } catch (error) {
        console.error(error);
        return { success: false, message: "Error checking app password" };
    }
};

const authCheck = async () => {
    return new Promise(async (resolve, reject) => {
        if (!auth.currentUser) {
            resolve(false);
            return;
        }

        let { success } = await checkAppPassword(VueCookies.get(process.env.VUE_APP_PASSOWRD_COOKIE_NAME));

        if (!success) {
            resolve(false);
            return;
        }

        // console.log("------>", success);

        resolve(true);
    });
};
export default app;
