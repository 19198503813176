/** @type {import('tailwindcss').Config} */
const colors = require("tailwindcss/colors"); // Import default colors
module.exports = {
    mode: "jit",
    content: ["./public/**/*.html", "./src/**/*.vue"],
    safelist: [{ pattern: /(bg|stroke)-./ }],
    theme: {
        extend: {
            fontFamily: {
                "open-sans": ['"Open Sans"', "sans-serif"], // Make sure to include the Open Sans font
            },
            fontWeight: {
                light: 300,
                normal: 400, // This is usually the default, included for clarity
                medium: 500,
                semibold: 600,
                bold: 700,
                extrabold: 800,
            },
            fontSize: {
                "2xs": "0.625rem",
            },
            colors: {
                ...colors,

                dark: {
                    300: "#1E1E1E",
                    200: "#333333",
                },
                intercorp: {
                    300: "#0B5B66",
                    200: "#04CEEA",
                    100: "#9BF2FE",
                },
                black: {
                    900: "#000000",
                    800: "#121212",
                    700: "#242424",
                    600: "#363636",
                    500: "#484848",
                    400: "#696969",
                    300: "#818181",
                    200: "#B2B2B2",
                    100: "#D4D4D4",
                },
            },
        },
    },
    plugins: [],
};
