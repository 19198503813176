import { getColor, colors } from "./colors-table";
import moment from "moment-timezone";
import store from "./store";
moment.tz.setDefault("America/Los_Angeles");

// add a global time filter
export const time = (timestamp) => {
    return moment(timestamp).tz("America/Los_Angeles");
};

export const getCompanyIcon = (event) => {
    const icons = {
        amazon: "fa-amazon",
        google: "fa-google",
        apple: "fa-apple",
        microsoft: "fa-microsoft",
        // "pioneer square labs": "fa-flask",
    };
    return icons[event.company] || "";
};

export const shapesIndex = (id, maxItems) => {
    maxItems = maxItems || 3 + 1;
    let hash = 0;
    for (let i = 0; i < id.length; i++) {
        hash = (hash * 31 + id.charCodeAt(i)) % 1024; // Using 31 as a random small prime number for hashing
    }
    return hash % maxItems;
};
export const valueFromId = (id, n) => {
    n = n || 3 + 1;
    let hash = 0;
    for (let i = 0; i < id.length; i++) {
        hash = (hash * 31 + id.charCodeAt(i)) % 1024;
    }
    return hash % n;
};

export const hashStringToSeed = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
};

export default {
    install: (app) => {
        app.config.globalProperties.$time = time;
        app.config.globalProperties.$getColor = getColor;
        app.config.globalProperties.$openLocation = (address) => {
            let url = `https://www.google.com/maps/dir/?api=1&destination=${address}`;
            let win = window.open(url, "_blank");
        };

        app.config.globalProperties.$text = (obj, prop) => {
            const lang = store.state.language;
            if (obj.translations && obj.translations[lang]) {
                return obj.translations[lang];
            }
            return obj[prop];
        };

        app.mixin({
            computed: {
                $user() {
                    return this.$store.state.user;
                },
                $hasAppPassword() {
                    if (!this.$store.state.user) {
                        return false;
                    }
                    return this.$store.state.user.hasAppPassword;
                },
            },
        });
    },
};
