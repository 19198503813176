<template>
    <main-nav></main-nav>
    <div class="p-8 container">
        <div class="container min-h-screen">
            <div class="w-full">
                <h3 class="placeholder-yellow-950 text-5xl md:text-6xl font-thin">Speakers</h3>

                <div class="relative w-full py-9 max-w-lg">
                    <div class="relative w-full mx-auto search flex justify-center rounded-full bg-black-600 text-center">
                        <input
                            type="text"
                            class="pl-3 py-4 flex h-full w-[75%] md:w-[83%] text-black-100 bg-transparent border-transparent"
                            placeholder="Search for a speaker..."
                            v-model="searchTerm"
                        />
                        <i class="fa fa-search absolute left-5 top-1/2 transform -translate-y-1/2 text-gray-400"></i>
                    </div>
                </div>

                <!-- <div class="columns-1 md:columns-2 lg:columns-3 xl:columns-4 gap-6"> -->
                <div class="md:flex md:flex-wrap md:gap-6 md:justify-between">
                    <speaker-card v-for="speaker in speakers" :speaker="speaker" :key="speaker._id" />
                </div>
            </div>
        </div>
    </div>
    <main-footer></main-footer>
</template>

<script>
import Fuse from "fuse.js";
export default {
    name: "SpeakerView",
    components: {},
    mixins: [],
    data() {
        return {
            searchTerm: "",
        };
    },
    methods: {},
    computed: {
        speakers() {
            if (!this.searchTerm) {
                return this.$store.state.speakers;
            }
            const options = {
                keys: ["name", "jobTitle"],
            };
            const fuse = new Fuse(this.$store.state.speakers, options);
            const result = fuse.search(this.searchTerm);

            return result.map((item) => item.item);
        },
    },
    created() {},
    mounted() {},
};
</script>

<style scoped lang="scss">
.image-link {
    img {
        overflow: hidden;
        transition: transform 0.15s ease;
        @apply grayscale;
    }
    &:hover {
        img {
            //scale 120%
            transform: scale(1.2);
        }
    }
}
</style>
