const colors = {
    microsoft: "cyan",
    "pioneer square labs": "purple",
    amazon: "yellow",
    adobe: "red",
    "khan academy": "green",
    sephora: "black",
    google: "blue",
    "blue river technology": "blue",
    ideo: "orange",
    "palo alto networks": "red",
    hotel: "teal",
    restaurant: "red",
    travel: "teal",

    //
    breakfast: "amber",

    break: "pink",

    none: "sky",
};

const getColor = (company, val) => {
    let str = colors[company] || "sky";
    return str + (val ? "-" + val : "");
};
export { getColor, colors };
export default colors;
