<template>
    <div class="relative inline-block text-left" v-click-outside="closeDropdown">
        <div>
            <a
                @click="toggleDropdown"
                class="cursor-pointer inline-flex justify-center w-full text-sm font-medium uppercase"
                :class="{ 'text-teal-200': open, 'text-white': !open }"
            >
                {{ language }}
            </a>
        </div>
        <div v-show="open" class="origin-top-right absolute right-0 w-20 shadow-lg bg-black-800 z-20" style="display: none">
            <div role="none">
                <a @click="setLanguage('en')" href="#" class="text-white block px-4 py-2 text-sm hover:bg-black-600 uppercase" role="menuitem">en</a>
                <a @click="setLanguage('es')" href="#" class="text-white block px-4 py-2 text-sm hover:bg-black-600 uppercase" role="menuitem">es</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            open: false,
            language: this.$store.state.language,
        };
    },
    methods: {
        toggleDropdown() {
            this.open = !this.open;
        },
        closeDropdown() {
            this.open = false;
        },
        setLanguage(lang) {
            this.language = lang;
            this.open = false;
            // set store

            this.$store.commit("setLanguage", lang);

            // set a cookie
            this.$cookies.set("language", lang);
        },
    },
    directives: {
        clickOutside: {
            bind(el, binding, vnode) {
                el.clickOutsideEvent = function (event) {
                    // Here's the magic: we check if the click is outside the element and its children
                    if (!(el == event.target || el.contains(event.target))) {
                        vnode.context[binding.expression](event);
                    }
                };
                document.body.addEventListener("click", el.clickOutsideEvent);
            },
            unbind(el) {
                document.body.removeEventListener("click", el.clickOutsideEvent);
            },
        },
    },
};
</script>
