<template>
    <div class="w-full h-full">
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.dev/svgjs" width="100%" height="100%">
            <defs>
                <linearGradient :gradientTransform="`rotate(-${angle}, 0.5, 0.5)`" x1="50%" y1="0%" x2="50%" y2="100%" :id="`${id}-grain-gradient2`">
                    <stop :stop-color="colors[0]" stop-opacity="1" offset="-0%"></stop>
                    <stop :stop-color="colors[1]" stop-opacity="0" offset="100%"></stop>
                </linearGradient>
                <linearGradient :gradientTransform="`rotate(${angle}, 0.5, 0.5)`" x1="50%" y1="0%" x2="50%" y2="100%" :id="`${id}-grain-gradient3`">
                    <stop :stop-color="colors[2]" stop-opacity="1"></stop>
                    <stop :stop-color="colors[3]" stop-opacity="0" offset="100%"></stop>
                </linearGradient>
                <filter
                    :id="`${id}-grain-filter`"
                    x="-20%"
                    y="-20%"
                    width="140%"
                    height="140%"
                    filterUnits="objectBoundingBox"
                    primitiveUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feTurbulence
                        type="fractalNoise"
                        baseFrequency="0.55"
                        numOctaves="2"
                        seed="2"
                        stitchTiles="stitch"
                        x="0%"
                        y="0%"
                        width="100%"
                        height="100%"
                        result="turbulence"
                    ></feTurbulence>
                    <feColorMatrix type="saturate" values="0" x="0%" y="0%" width="100%" height="100%" in="turbulence" result="colormatrix"></feColorMatrix>
                    <feComponentTransfer x="0%" y="0%" width="100%" height="100%" in="colormatrix" result="componentTransfer">
                        <feFuncR type="linear" slope="3"></feFuncR>
                        <feFuncG type="linear" slope="3"></feFuncG>
                        <feFuncB type="linear" slope="3"></feFuncB>
                    </feComponentTransfer>
                    <feColorMatrix
                        x="0%"
                        y="0%"
                        width="100%"
                        height="100%"
                        in="componentTransfer"
                        result="colormatrix2"
                        type="matrix"
                        values="1 0 0 0 0
          0 1 0 0 0
          0 0 1 0 0
          0 0 0 19 -11"
                    ></feColorMatrix>
                </filter>
            </defs>
            <g>
                <rect width="100%" height="100%" fill="hsl(0, 100%, 60%)"></rect>
                <rect width="100%" height="100%" :fill="`url(#${id}-grain-gradient3)`"></rect>
                <rect width="100%" height="100%" :fill="`url(#${id}-grain-gradient2)`"></rect>
                <rect width="100%" height="100%" fill="transparent" :filter="`url(#${id}-grain-filter)`" opacity="1" style="mix-blend-mode: soft-light"></rect>
            </g>
        </svg>
    </div>
</template>

<script>
import colors from "@/colors-table";
import _ from "lodash";
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "../../tailwind.config.js";
const fullConfig = resolveConfig(tailwindConfig);
import { valueFromId, itemsFromId, hashStringToSeed } from "@/helper.js";

import Prando from "prando";

export default {
    props: {
        color: {
            type: String,
            default: "blue",
        },
        id: {
            type: String,
        },
        name: {
            type: String,
        },
    },
    name: "YourComponentName",
    components: {},
    mixins: [],
    data() {
        return {
            colors: [],
            angle: 0,
        };
    },
    watch: {
        name() {
            this.generate();
        },
    },
    methods: {
        generate() {
            let random = new Prando(this.name);

            let cvals = Object.values(colors);

            for (var i = 0; i < 4; i++) {
                let c = parseInt(random.next() * cvals.length);
                let name = cvals[c];
                this.colors.push(fullConfig.theme.colors[name]["200"]);
            }

            this.angle = random.next() * 360;
        },
    },
    created() {},
    mounted() {
        this.generate();
        // let c = this.id
        //     .split("")
        //     .map((c) => {
        //         return c.charCodeAt(0);
        //     })
        //     .join("")
        //     .split("")
        //     .map((c) => {
        //         return parseInt(c);
        //     })
        //     .slice(0, 4)
        //     .map((c) => {
        //         return cvals[c];
        //     });

        // console.log(c);
        return;
        c.map((name) => {
            this.colors.push(fullConfig.theme.colors[name]["200"]);
        });
    },
};
</script>

<style scoped lang="scss"></style>
