<template>
    <div>
        <div class="flex items-center justify-center gap-4 max-w-lg mx-auto mb-9 md:mb-20">
            <div class="relative w-full mx-auto search flex justify-center rounded-full bg-black-600 text-center">
                <input
                    ref="searchInput"
                    @input="onInput"
                    @focus="searchFocused = true"
                    @focusout="searchFocused = false"
                    type="text"
                    class="pl-3 py-6 flex h-full w-[75%] md:w-[83%] text-black-100 bg-transparent border-transparent"
                    v-model="searchTerm"
                />
                <i :class="`transition-all fa fa-search absolute left-5 top-1/2 transform -translate-y-1/2 ${searchFocused ? 'text-teal-200' : 'text-black-300'}`"></i>
                <div @click="searchTerm = ''" class="cursor-pointer absolute flex w-3 h-3 right-5 top-1/2 transform -translate-y-1/2 p-3 items-center justify-center">
                    <i :class="`fa fa-close  ${searchFocused ? 'text-teal-200' : 'text-black-300'}`"></i>
                </div>
            </div>
            <a
                v-if="showClose"
                @click="
                    () => {
                        $emit('close');
                    }
                "
                class="bg-black-600 rounded-full min-w-[72px] min-h-[72px] flex items-center justify-center"
            >
                <i class="fa-solid fa-close"></i>
            </a>
        </div>
        <!-- show results -->
        <div v-if="searchTerm" class="md:flex w-full md:gap-6">
            <!--  -->
            <!-- sessions -->
            <div v-if="searchResults.session.length > 0" class="mb-5 p-6 w-full md:w-1/2 bg-black-700">
                <div v-for="result in searchResults.session" :key="result.item._id" class="flex flex-wrap items-center gap-5 mb-12">
                    <div class="flex-1">
                        <h3 class="text-2xl text-teal-200">{{ result.item.title }}</h3>
                        <div class="text-black-100">
                            <div class="date text-black-100">{{ result.item.start.format("dddd MMMM Do ") }}</div>
                            <div>
                                <i class="fa-regular fa-clock"></i>
                                <div>{{ result.item.timeRange }}</div>
                            </div>
                            <div class="pb-3">
                                <a v-if="result.item.address" class="text-xs location hover:underline" @click.prevent="$openLocation(result.item.address)">
                                    <i class="fa-sharp fa-light fa-location-dot mr-1"></i>{{ result.item.address }}
                                </a>
                            </div>
                        </div>
                        <div class="flex gap-2 text-sm text-black-200 flex-wrap my-3">
                            <router-link
                                class="hover:text-black-100 border-b rounded-full px-3 border-b-transparent hover:bg-black-900 text-xs text-nowrap bg-black-500 p-1"
                                :to="`/speakers/${speaker._id}`"
                                v-for="speaker in result.item.speakers"
                                >{{ speaker.name }}</router-link
                            >
                        </div>
                        <p class="text-gray-400">{{ result.item.description }}</p>

                        <div class="flex mt-4">
                            <router-link
                                v-if="result.item.url"
                                :to="`/sessions/${result.item._id}`"
                                :class="`px-3 py-2 text-black-900 text-sm font-medium text-center cursor-pointer transition-all hover:bg-black-800 hover:text-white  bg-white rounded-full  cta`"
                            >
                                Session Details
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
            <!--  -->
            <!-- speakers -->
            <div v-if="searchResults.speakers.length > 0" class="w-full mb-5 md:w-1/2">
                <!-- <div class="flex flex-wrap justify-between mb-3"> -->
                <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
                    <!-- <div class="grid grid-cols-1 lg:grid-cols-3 gap-6 mb-3"> -->
                    <div v-for="result in searchResults.speakers" :key="result.item._id" class="w-full bg-black-700">
                        <router-link :to="`/speakers/${result.item._id}`">
                            <img
                                :src="result.item.photo.url"
                                alt=""
                                class="grayscale w-full md:h-[140px] max-h-[300px] object-cover hover:scale-105 transition-all cursor-pointer"
                            />
                        </router-link>
                        <div class="p-3">
                            <router-link
                                :to="`/speakers/${result.item._id}`"
                                class="font-medium text-teal-200 capitalize cursor-pointer border-b border-b-transparent hover:border-b-teal-200"
                                >{{ result.item.name }}</router-link
                            >
                            <h3 class="text-black-200 text-sm">{{ result.item.jobTitle }}</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="searchResults.length === 0 && showNowResults" class="min-h-screen italic text-black-300 text-center flex justify-center items-center">
            Search for sessions or speakers
        </div>
    </div>
</template>

<script>
import store from "@/store";
import fuse from "fuse.js";
import { saveSearchTerms } from "@/auth";
export default {
    props: {
        sessions: Array,
        showClose: {
            type: Boolean,
            default: true,
        },
        showNowResults: {
            type: Boolean,
            default: true,
        },
        active: {
            type: Boolean,
            default: false,
        },
    },
    name: "Search",
    components: {},
    mixins: [],
    data() {
        return {
            searchTerm: "",
            searchFocused: false,
            searchTimer: null,
        };
    },
    computed: {
        searchResults() {
            if (!this.searchTerm) return [];

            const sessionSrch = new fuse(this.sessions, { keys: ["title", "description", "speakers.name"] });
            const speakersSrch = new fuse(store.state.speakers, { keys: ["name", "jobTitle"] });

            const sessionResults = sessionSrch.search(this.searchTerm);
            const speakerResults = speakersSrch.search(this.searchTerm);

            const results = {
                session: sessionResults,
                speakers: speakerResults,
            };

            return results;
        },
    },
    watch: {
        active(val) {
            if (val) {
                this.$nextTick(() => {
                    this.searchTerm = "";
                    this.$refs.searchInput.focus();
                });
            }
        },
    },
    methods: {
        onInput(e) {
            if (this.searchTimer) {
                clearTimeout(this.searchTimer);
            }

            this.searchTimer = setTimeout(() => {
                this.searchTimer = null;
                saveSearchTerms(this.searchTerm);
            }, 1000);
        },
    },
    created() {},
    mounted() {},
};
</script>

<style scoped lang="scss"></style>
