<template>
    <main-nav></main-nav>

    <div v-if="isLoaded" class="min-h-screen">
        <!-- hero -->
        <div :class="`mb-9 relative bg-cover overflow-hidden  bg-center min-h-[30vh] text-black-600  object-fill bg-${session.color}-200`">
            <div class="z-10 absolute w-full h-full item-end justify-end hidden md:flex">
                <shapes :shapeIndex="session.shapeIndex" />
            </div>

            <div class="py-8 container">
                <div class="h-full flex-col flex justify-between relative py-6 z-10">
                    <div>
                        <h1 v-if="session.company" class="text-5xl mb-3 font-bold capitalize max-w-md">{{ session.company }}</h1>
                        <h1 class="text-5xl capitalize max-w-md">{{ session.title }}</h1>
                        <p class="text-2xl mt-3">{{ day }}</p>

                        <p class="mt-2">
                            <i class="fa-regular fa-clock mr-1"></i>
                            {{ session.timeRange }}
                        </p>
                    </div>
                    <a v-if="session.address" @click.prevent="$openLocation(session.address)">
                        <div class="hover:underline inline-block cursor-pointer"><i class="fa-sharp fa-light fa-location-dot mr-2"></i>{{ session.address }}</div>
                    </a>
                    <!-- <div class="h-full bg-red-100">
                        <div class="container">
                            <i :class="`fa ${session.icon}`"></i>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        <!-- end hero -->

        <!-- Description and Speakers -->
        <div v-if="speakers">
            <div class="flex flex-wrap w-full">
                <!-- pagination -->
                <div class="my-6 md:my-9 w-full flex mx-auto items-center justify-center gap-6" v-if="speakers.length > 1">
                    <div
                        @click="swiper.slideTo(index)"
                        v-for="(speaker, index) in speakers"
                        :key="speaker._id"
                        :class="`w-5 h-5 rounded-full border-4 inset transition-all ease-in-out hover:opacity-50 cursor-pointer ${getDotClass(speaker._id)}`"
                    ></div>
                </div>

                <!-- slides -->
                <swiper @slideChange="onSlideChange" @swiper="initSwiper" :slidesPerView="'auto'" :centeredSlides="true" centeredSlides="auto" :space-between="50" class="">
                    <swiper-slide v-for="speaker in speakers" :speaker="speaker" :key="speaker._id" class="container w-full pb-9 bg-black-500">
                        <div class="w-full flex flex-wrap">
                            <div class="w-full md:max-w-[300px]">
                                <div class="w-full md:w-[300px] h-[300px] overflow-hidden mt-3 md:mt-6 mb-6">
                                    <img :src="speaker.photo.url" :alt="speaker.name" class="object-cover w-full h-[300px] grayscale" />
                                </div>

                                <div class="flex flex-col items-start">
                                    <!-- name -->
                                    <router-link :to="`/speakers/${speaker._id}`" class="border-b-1 font-medium border-b-transparent hover:border-b-black-100 text-2xl">{{
                                        speaker.name
                                    }}</router-link>
                                    <!-- job -->
                                    <div class="text-black-200 mb-6 text-sm max-w-[300px] inline">{{ speaker.jobTitle }}</div>
                                    <!-- ratings -->
                                    <rating class="mb-6" :index="1" :sessionId="session._id" :speakerId="speaker._id" />
                                    <!--  -->
                                </div>
                            </div>

                            <div class="flex-1 md:p-6">
                                <div class="h-full text-center flex items-center justify-center w-full" v-if="speaker.isTranslating">Translating...</div>
                                <markdown
                                    v-if="!speaker.isTranslating"
                                    class="text-white text-xl markdown font-thin"
                                    :source="$text(speaker, 'sessionDescription') || placeHolder"
                                ></markdown>
                            </div>
                        </div>
                    </swiper-slide>
                </swiper>
            </div>
            <div class="p-4 md:p-6 mt-6 mb-36 container mx-auto bg-black-700" v-if="comments">
                <comments
                    :comments="comments"
                    :speakerId="activeSpeakerId"
                    :rating="rating"
                    @translate="translateUserComment"
                    @deleteComment="deleteComment"
                    @commentReacted="commentReacted"
                    @addComment="addComment"
                    @setRating="setRating"
                />
            </div>
        </div>
    </div>
    <main-footer></main-footer>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import _ from "lodash";
import { getColor } from "../colors-table";
import {
    getCommentsForSpeakerSession,
    addSpeakerSessionComment,
    addReactionToComment,
    deleteSessionComment,
    setRatingForSpeakerSession,
    getRatingForSpeakerSession,
    translateComment,
    getTranslationFromKey,
} from "@/auth";
const defaultReactions = {
    heart: {
        type: "heart",
        icon: "fa-solid fa-heart",
        status: false,
    },
    fire: {
        type: "fire",
        icon: "fa-solid fa-fire",
        status: false,
    },
    think: {
        type: "think",
        icon: "fa-solid fa-lightbulb",
        status: false,
    },
};

export default {
    name: "SessionView",
    components: { Swiper, SwiperSlide },
    mixins: [],
    data() {
        return {
            isLoaded: false,
            commentRef: null,
            session: {},
            day: "",
            color: "bg-black-700",
            colorName: "black",
            comments: null,
            swiper: null,
            activeSpeakerId: null,
            placeHolder: "This session has not been filled out yet!!!",
        };
    },
    methods: {
        getDotClass(id) {
            if (id === this.activeSpeakerId) {
                return `border-${this.session.color}-200 bg-transparent`;
            } else {
                return `border-transparent bg-${this.session.color}-200`;
            }
        },
        initSwiper(e) {
            this.swiper = e;
            if (this.$route.query.speaker) {
                this.$nextTick(() => {
                    this.scrollToSpeaker(this.$route.query.speaker);
                });
            }
        },
        async onSlideChange(e) {
            this.activeSpeakerId = this.speakers[e.activeIndex]._id;

            // update the translation

            let speaker = this.speakers[e.activeIndex];
            speaker.translations = await getTranslationFromKey(speaker, "sessionDescription");
        },

        async setRatingForSpeaker(e) {
            setRatingForSpeakerSession(this.session._id, e.speakerID, e.rating);
        },
        async deleteComment(id) {
            if (confirm("Are you sure you want to delete this comment?")) {
                await deleteSessionComment(this.session._id, this.activeSpeakerId, id);
                this.reloadComments();
            }
        },
        commentReacted(reaction) {
            // console.log("reacting to comment", reaction);
            let activeSpeaker = this.speakers.find((speaker) => speaker._id === this.activeSpeakerId);
            if (!activeSpeaker) {
                console.error("No active speaker");
                return;
            }
            reaction.sessionId = this.session._id;
            reaction.speakerId = activeSpeaker._id;
            addReactionToComment(reaction);
        },
        async addComment(text) {
            let activeSpeaker = this.speakers.find((speaker) => speaker._id === this.activeSpeakerId);
            if (!activeSpeaker) {
                console.error("No active speaker");
                return;
            }

            const comment = {
                text,
                userId: this.$store.state.user.uid,
                speakerId: activeSpeaker._id,
                sessionId: this.session._id,
                date: new Date(),
            };

            this.setupDefaultReactions(comment);

            this.comments.push(comment);

            let res = await addSpeakerSessionComment(comment);

            if (res) {
                let key = res.key;
                this.comments[this.comments.length - 1].id = key;
            }
        },
        hasUserId(data, userId) {
            for (const key in data) {
                if (data.hasOwnProperty(key) && data[key].userId === userId) {
                    return true;
                }
            }
            return false;
        },
        setupDefaultReactions(comment) {
            let userId = this.$store.state.user.uid;
            comment.userReactions = { ...defaultReactions };
            for (var type in comment.userReactions) {
                comment.userReactions[type] = {
                    ...comment.userReactions[type],
                    userId: userId,
                    status: false,
                    speakerId: this.activeSpeakerId,
                    sessionId: this.session._id,
                };
            }
        },
        async reloadComments() {
            this.comments = null;
            let comments = await getCommentsForSpeakerSession(this.session._id, this.activeSpeakerId);
            this.comments = [];
            let userId = this.$store.state.user.uid;

            for (let id in comments) {
                let comment = comments[id];
                comment.id = id;

                this.setupDefaultReactions(comment);

                if (comment.reactions) {
                    // console.log("comment has reactions", comment.text);
                    for (var type in comment.userReactions) {
                        // console.log("type", type);
                        let reactionGroup = comment.reactions[type];
                        if (reactionGroup && reactionGroup[userId]) {
                            comment.userReactions[type] = reactionGroup[userId];
                        }
                    }
                }

                // comment.reactions = comment.reactions || defaultReactions;
                this.comments.push(comment);
            }
        },
        scrollToSpeaker(id) {
            let index = this.speakers.findIndex((speaker) => speaker._id === id);
            if (index !== -1) {
                // console.log("scrolling to speaker", this.swiper);
                // this.swiper.slideTo(index);
                this.swiper.slideTo(index);
            }
        },
        async translateUserComment(comment) {
            let c = await translateComment(comment);
            comment = c;
        },
    },
    created() {},
    computed: {
        speakers() {
            return this.session.speakers;
        },
        sessionId() {
            return this.$route.params.id;
        },
    },
    watch: {
        async activeSpeakerId(oldValue, newValue) {
            this.reloadComments();
        },
    },
    async mounted() {
        // we must have sessions!
        if (!this.$store.state.sessions) {
            console.error("No sessions found");
            this.$router.push("/404");
            return;
        }
        if (!this.sessionId) {
            console.error("No session id");
            this.$router.push("/404");
            return;
        }

        this.session = this.$store.state.sessions.find((session) => session._id === this.$route.params.id);

        if (!this.session) {
            this.$router.push("/404");
            return;
        }

        getTranslationFromKey(this.speakers[0], "sessionDescription");

        this.color = getColor(this.session.company);
        this.colorName = this.color.split("bg")[1];
        this.activeSpeakerId = this.speakers.length ? this.speakers[0]._id : null;

        await this.reloadComments();

        this.isLoaded = true;
    },
};
</script>

<style scoped lang="scss">
.slider-container {
    @apply container;
    @apply mx-7;

    // @apply max-w-[90%] md:max-w-[80%] lg:max-w-[1000px];;
}
</style>
