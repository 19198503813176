<template>
    <div class="p-4" v-if="comment">
        <div class="text-white text-2xl mb-9 max-h-[300px] overflow-y-scroll scrollbar">
            <div class="w-full h-full flex items-center justify-center text-xs italic" v-if="comment.translationState === 'loading'">Translating...</div>
            <span v-if="!comment.translationState || comment.translationState === 'done'">{{ text }}</span>
        </div>
        <div class="flex justify-end flex-col items-end">
            <div class="text-right text-sm text-black-200">{{ comment.username }}</div>
            <div class="text-right text-sm text-black-200">{{ $time(comment.date).fromNow() }}</div>
            <div class="flex gap-2 items-end mt-4 justify-between w-full">
                <a v-if="!comment.translationState" class="cursor-pointer border-b border-b-transparent hover:border-b-white" @click="$emit('translate', comment)"
                    >See Translation</a
                >
                <div v-if="comment.translationState"></div>
                <div class="flex gap-2 items-center">
                    <a
                        v-for="(reaction, type) in comment.userReactions"
                        :key="`${speakerId}-${type}-${comment.id}`"
                        @click.stop="addReaction(reaction, type, comment.id)"
                        :class="getReactionClassNames(reaction, comment)"
                    >
                        <i :class="`${reaction.icon}`"></i>
                    </a>
                    <!-- remove comment only if Mine! -->
                    <a
                        v-if="comment.userId === $store.state.user.uid"
                        @click.stop="
                            () => {
                                $emit('delete-comment', comment.id);
                            }
                        "
                        class="bg-red-300 mt-3 hover:bg-red-500 text-black-900 cursor-pointer p-4 rounded-full h-6 items-center justify-center flex"
                    >
                        Delete
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { comment } from "postcss";

export default {
    props: {
        comment: {
            type: Object,
            required: true,
        },
    },
    name: "Comment",
    components: {},
    mixins: [],
    data() {
        return {};
    },

    methods: {
        addReaction(reaction, type, commentId) {
            reaction.status = !reaction.status;
            reaction.commentId = commentId;
            reaction.speakerId = this.speakerId;
            this.$emit("comment-reacted", reaction);
        },
        getReactionClassNames(reaction, comment) {
            return `${
                reaction.status ? "bg-teal-300 text-black-500" : "bg-black-300 text-white"
            }  mt-3 hover:bg-black-200 cursor-pointer p-4 rounded-full w-6 h-6 items-center justify-center flex`;
        },
    },
    computed: {
        text() {
            const lang = this.$store.state.language;
            if (lang && this.comment.lang[lang]) {
                return this.comment.lang[lang];
            }
            return this.comment.text;
        },
    },
    mounted() {},
};
</script>

<style scoped lang="scss"></style>
