<template>
    <div class="flex flex-col 2 mb-9 items-end">
        <textarea class="p-4 mb-6 min-h-36 w-full text-black-900 text-2xl" v-model="text" placeholder="What was your key takeaway?"></textarea>
        <div class="flex justify-end w-full">
            <button
                @click="addComment"
                class="bg-white hover:bg-black-100 text-black-900 cursor-pointer p-2 disabled:pointer-events-none disabled:text-black-100 disabled:cursor-not-allowed md:max-w-sm rounded-full px-6 min-w-36 text-lg"
                :disabled="!text"
            >
                Submit<i class="ml-3 fa-light fa-paper-plane"></i>
            </button>
        </div>
    </div>

    <div className="masonry sm:masonry-sm md:masonry-md">
        <div v-for="comment in comments" :key="comment.id" class="shadow-md bg-black-500 mb-3 break-inside">
            <comment
                :speakerId="speakerId"
                :comment="comment"
                @commentReacted="addReaction"
                @translate="$emit('translate', $event)"
                @deleteComment="
                    (e) => {
                        $emit('delete-comment', e);
                    }
                "
            />
        </div>
    </div>
</template>

<script>
import Comment from "./Comment.vue";
export default {
    props: {
        speakerId: {
            type: String,
            required: true,
        },
        rating: {
            type: Number,
            required: true,
            default: 0,
        },
        comments: {
            type: Array,
            required: true,
            default: () => {
                return [];
            },
        },
    },
    name: "Comments",
    components: { Comment },
    mixins: [],
    data() {
        return {
            text: "",
        };
    },
    computed: {},
    methods: {
        addReaction(reaction) {
            this.$emit("comment-reacted", reaction);
        },
        addComment() {
            this.$emit("add-comment", this.text);
            this.text = "";
        },
    },
    created() {},
    mounted() {},
};
</script>

<style scoped lang="scss"></style>
