<template>
    <main-nav />
    <div class="w-full min-h-screen flex items-center justify-center">
        <shapes color="rgba(255,255,255, 0.5)" ref="shapes" class="absolute overflow-hidden top-0 left-0 w-full h-full flex items-center justify-center" />

        <!-- home button -->
        <div class="flex gap-6 z-30 flex-col items-center">
            <div class="text-teal-500 text-9xl">404</div>
            <div class="flex gap-3">
                <router-link to="/" class="bg-white hover:bg-black-100 text-black-900 cursor-pointer p-4 disabled:text-black-100 md:max-w-sm rounded-full px-12">
                    Home
                </router-link>
                <a @click="reload" class="bg-white hover:bg-black-100 text-black-900 cursor-pointer p-4 disabled:text-black-100 md:max-w-sm rounded-full px-6">
                    <i class="fa-solid fa-arrows-rotate"></i>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Missing404",
    components: {},
    mixins: [],
    data() {
        return {};
    },
    methods: {
        reload() {
            console.log(this.$refs.shapes);
            this.$refs.shapes.reload();
        },
    },
    created() {},
    mounted() {},
};
</script>

<style scoped lang="scss"></style>
