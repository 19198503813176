<template>
    <div class="hamburger-menu" id="hamburger" :class="{ 'is-active': show }">
        <div class="inner">
            <span></span>
            <span></span>
            <span></span>
        </div>
    </div>
</template>

<script>
export default {
    name: "Hamburger",
    props: {
        show: {
            type: Boolean,
            default: false,
        },
    },
    components: {},
    mixins: [],
    data() {
        return {};
    },
    methods: {},
    created() {},
    mounted() {},
};
</script>

<style scoped lang="scss">
@use "sass:math";
$hamburger-thickness: 2px;
$hamburger-size: 20px;
$hamburger-bar-spacing: math.round(math.div($hamburger-size, 3));
$hamburger-animation-speed: 1;
$hamburger-easing: cubic-bezier(0.645, 0.045, 0.355, 1);

.hamburger-menu {
    width: $hamburger-size;
    height: $hamburger-size;
    position: relative;
    transform: rotate(0deg);
    position: relative;
    transition: 0.5s ease-in-out;

    cursor: pointer;
    .inner {
        margin: 0;
        margin-top: math.div(-$hamburger-thickness, 2);
        position: relative;
        width: 100%;
        top: 50%;
        span {
            background-color: white;
            border-radius: 0;
            content: "";
            display: block;
            height: $hamburger-thickness;
            position: absolute;
            width: 100%;

            transition: transform math.div(0.3s, $hamburger-animation-speed) $hamburger-easing, opacity math.div(0.3s, $hamburger-animation-speed) $hamburger-easing;

            &:nth-child(1) {
                top: 0;
                transform: translate3d(0, -$hamburger-bar-spacing, 0);
            }

            &:nth-child(2) {
                opacity: 1;
                transform-origin: 15% 50%;
            }

            &:nth-child(3) {
                top: 0;
                transform: translate3d(0, $hamburger-bar-spacing, 0);
            }
        }
    }

    &.is-active {
        .inner {
            span {
                top: 0;
                &:nth-child(1) {
                    transform: rotate(45deg);
                }

                &:nth-child(2) {
                    opacity: 0;
                    transform: scale(0);
                }

                &:nth-child(3) {
                    transform: rotate(-45deg);
                }
            }
        }
    }
}
</style>
