<template>
    <div class="flex min-h-screen items-center w-full justify-center">
        <div class="container flex justify-center items-center h-full">
            <div class="bg-black-800 max-w-lg flex flex-col justify-center items-center p-9 md:-mt-0">
                <div class="text-6xl p-9 text-black-500">
                    <i :class="`fa-thin fa-${didPass ? 'unlock' : 'lock'}`"></i>
                </div>
                <div class="text-lg w-[300px]">Welcome to Intercorp Learning Trip 2024 Please enter the password to see the event.</div>
                <div class="relative w-full py-9 max-w-lg mx-auto">
                    <input
                        autocomplete="new-password"
                        class="pl-10 pr-3 md:text-5xl text-3xl py-3 w-full rounded-full bg-black-600 text-black-100 border-transparent"
                        type="password"
                        @input="onInput"
                        @keydown.enter="checkPassword"
                        placeholder="********"
                    />
                </div>
                <div :class="`${!didPassCheck && didPassCheck != null ? 'text-red-400' : 'text-transparent'} italic p-6`">Sorry password is incorrect</div>
            </div>
        </div>
    </div>
</template>

<script>
let timer = null;
import { checkAppPassword } from "@/auth";
export default {
    name: "PasswordWall",
    components: {},
    mixins: [],
    data() {
        return {
            didPassCheck: null,
            password: "",
        };
    },

    computed: {
        didPass() {
            return this.$hasAppPassword;
        },
    },
    methods: {
        async onInput(e) {
            this.didPassCheck = null;
            this.password = e.target.value;
            if (timer) {
                clearTimeout(timer);
            }
            timer = setTimeout(() => {
                this.checkPassword();
            }, 1000);
        },
        async checkPassword() {
            console.log("checkPassword");
            let { success } = await checkAppPassword(this.password);

            this.didPassCheck = success;
            this.$store.commit("setUserHasPassword", success);

            if (success) {
                setTimeout(() => {
                    this.$router.push({ path: "/" });
                }, 1000);
            }
        },
    },
    created() {},
    mounted() {},
};
</script>

<style scoped lang="scss">
input::placeholder {
    @apply text-3xl md:text-6xl text-center text-black-500;
    position: relative !important;
    transform: translateX(-10px) translateY(15%) !important;
}
</style>
