import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import components from "./components";
import { getColor, colors } from "./colors-table";
import { signIn } from "./auth";
import VueCookies from "vue-cookies";
import helpers, { shapesIndex, time, getCompanyIcon } from "./helper";

const app = createApp(App);

app.use(components);
app.use(VueCookies);
app.use(helpers);

signIn().then(async (user) => {
    console.log("Signed in as", user);

    const queryParams = new URLSearchParams(window.location.search);
    const refresh = queryParams.get("refresh") || false;
    let resp = await fetch(process.env.VUE_APP_API_URL + "/data?refresh=" + refresh);
    let data = await (await resp).json();

    if (resp.ok) {
        // filter out sessions that are missing start and end
        data.sessions = data.sessions.filter((session) => {
            return session.start && session.end;
        });

        // setup the sessions
        data.sessions.forEach((session) => {
            session.start = time(session.start);
            session.end = time(session.end);
            session.color = getColor(session.company);

            if (session.title.toLowerCase().includes("break")) {
                // session.color = "red";
            }
            session.duration = session.end.diff(session.start, "minutes");
            session.icon = getCompanyIcon(session);
            session.shapeIndex = shapesIndex(session._id);
            session.timeRange = `${session.start.format("h:mm A")} - ${session.end.format("h:mm A")}`;
        });

        let colorKeys = Object.keys(colors);
        data.participants.forEach((participant) => {
            participant.colorId = colors[colorKeys[shapesIndex(participant._id, colorKeys.length)]];
            participant.shapeIndex = parseInt(Math.random() * 4);
        });

        store.state.sessions = data.sessions;
        store.state.speakers = data.speakers;
        store.state.participants = data.participants;
    }

    app.use(store).use(router).mount("#app");
});
