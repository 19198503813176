<template>
    <div
        @click.prevent="
            () => {
                selectedSession = null;
            }
        "
        :class="`cover ${selectedSession ? 'is-active' : ''}`"
    ></div>

    <!--  -->
    <!-- day button -->
    <!--  -->
    <div class="overflow-hidden relative flex w-full md:justify-center bg-black-700">
        <div class="relative overflow-x-scroll flex snap-x snap-mandatory px-6 py-3 gap-3 scrollbar-hide ml-4 md:ml-0">
            <a :href="`#now`" class="h-[40px] px-5 flex items-center justify-center rounded-full relative text-black-600 whitespace-nowrap snap-start bg-teal-200">
                <div>Now</div>
            </a>
            <a
                :href="`#${group.day}`"
                :key="group.day"
                v-for="group in sessionsGrouped"
                class="h-[40px] px-5 flex items-center justify-center bg-black-600 rounded-full whitespace-nowrap snap-start cursor-pointer hover:bg-teal-200 hover:text-black-600 transition-all"
            >
                <div>{{ group.day.format("MMM Do") }}</div>
            </a>
        </div>
    </div>

    <!-- Search -->
    <div class="container my-20">
        <search :sessions="$store.state.sessions" :showNowResults="false" :showClose="false" />
    </div>

    <!-- next and now sessions -->
    <div class="flex w-full container">
        <div class="w-full flex justify-between">
            <!-- render countdown -->
            <div v-if="countDown" class="w-full text-sm">
                <div class="flex flex-wrap max-w-96 bg-black-600 p-3">
                    <div class="mr-2">Time to the first session:</div>
                    <div><i class="fa-light fa-clock font-medium text-sm"></i> {{ countDown.days }}d {{ countDown.hours }}h {{ countDown.minutes }}m {{ countDown.seconds }}s</div>
                </div>
            </div>

            <!-- render now session -->
            <router-link class="bg-black-600 p-3 w-full md:max-w-sm cursor-pointer" v-if="nowSession" :to="`/sessions/${nowSession._id}`">
                <div class="mb-2"><i class="fa-light fa-calendar font-medium text-sm"></i> Now: {{ nowSession.timeRange }}</div>
                <div v-if="nowSession.company" class="text-black-100 capitalize">
                    <i v-if="nowSession.icon" :class="`mr-1 fa-brands ${nowSession.icon}`"></i> {{ nowSession.company }}
                </div>
                <div class="text-black-200 text-sm">{{ nowSession.title }}</div>
            </router-link>
            <!-- <div class="bg-black-600 p-3 max-w-60" v-if="nextSession">
                <div><i class="fa-light fa-calendar"></i> Next:</div>
                <span class="text-black-200">{{ nextSession.title }}</span>
            </div> -->
        </div>
    </div>

    <!--  -->
    <!-- End Day Buttons -->
    <!--  -->
    <!-- Main Schedule -->
    <!--  -->
    <div class="min-h-screen">
        <div class="container">
            <div v-for="group in sessionsGrouped" :href="`#${group.day}`" :key="group.day" class="flex flex-col h-full flex-wrap mb-20 lg:mb-36">
                <div class="text-teal-200 w-full py-5 text-5xl flex lg:text-7xl lg:max-w-[250px] font-light" :id="group.day">
                    {{ group.dayFormated }}
                </div>
                <day-Schedule :sessions="group.sessions" :day="group.dayString" @sessionSelected="sessionSelected" :selectedSession="selectedSession" :now="now" />
            </div>
        </div>
    </div>
    <!--  -->
    <!-- End Main Schedule -->
    <!--  -->

    <!-- scroll to top a with a arrow up font awesome -->
    <a
        @click="jumpToTop"
        ref="topButton"
        class="fixed bottom-6 cursor-pointer right-6 bg-pink-300 hover:bg-pink-600 w-[50px] h-[50px] flex items-center justify-center rounded-full text-black-900 z-50"
    >
        <i class="fa-solid fa-arrow-up"></i>
    </a>
</template>

<script>
import store from "@/store";
import moment from "moment-timezone";
import fuse from "fuse.js";
let updateTimeInterval = null;
export default {
    name: "Home",
    components: {},
    mixins: [],
    data() {
        return {
            searchFocused: false,
            selectedSession: null,
            sessionsGrouped: {},
            searchTerm: "",
            now: moment(),
            nowSession: null,
            nextSession: null,
            countDown: null,
        };
    },
    methods: {
        calculateCountDown() {
            //
            // get the first session
            let firstSession = this.sessions[0];
            let starTime = firstSession.start;

            // if the first session is in the past return 0
            if (this.now.isAfter(starTime)) {
                return null;
            }

            // calculate time to the first session as a count down
            let now = this.now;
            let duration = moment.duration(starTime.diff(now));
            let days = duration.days();
            let hours = duration.hours();
            let minutes = duration.minutes();
            let seconds = duration.seconds();

            return { days, hours, minutes, seconds };

            console.log("firstSession", firstSession.title);
        },
        jumpToTop() {
            this.selectedSession = null;

            window.scrollTo({ top: 0, behavior: "smooth" });
        },
        sessionSelected(session) {
            this.selectedSession = session;
            // scroll to the session
            let width = window.innerWidth;

            if (session && session._id) {
                this.$nextTick(() => {
                    let el = document.getElementById(`session-${session._id}`);
                    if (el) {
                        var headerOffset = width <= 500 ? 20 : 300;
                        var elementPosition = el.getBoundingClientRect().top;
                        var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

                        window.scrollTo({
                            top: offsetPosition,
                            behavior: "smooth",
                        });
                    }
                });
            }
            // this.$router.push({ path: `/session/${session._id}` });
        },
        getCurrentSession() {
            if (!this.now) {
                return null;
            }
            let currentSession = null;

            // Make sure 'this.now' is up-to-date
            this.sessions.forEach((session) => {
                // Check if the session is today
                if (this.now.format("YYYY-MM-DD") === session.start.format("YYYY-MM-DD")) {
                    // Check if 'this.now' is between the session's start and end times
                    if (this.now.isSameOrAfter(session.start) && this.now.isSameOrBefore(session.end)) {
                        currentSession = session;
                        return; // Exit the loop once the current session is found (this doesn't actually break in native JavaScript's forEach)
                    }
                }
            });

            return currentSession;
        },
        getNextSessionFromNow() {
            if (!this.now) {
                return null;
            }
            let next = null;

            let nowHour = this.now.hour();
            let nowMinute = this.now.minute();

            let smallestDiff = Infinity; // Keep track of the smallest time difference

            // Ensure 'this.now' is up-to-date
            this.sessions.forEach((session) => {
                // Check if the session is today and in the future
                if (this.now.format("YYYY-MM-DD") === session.start.format("YYYY-MM-DD")) {
                    // Session must start after current time or be ongoing
                    if (this.now.isBefore(session.end)) {
                        // Calculate total minutes difference for a more precise comparison
                        let diff = session.start.hour() * 60 + session.start.minute() - (nowHour * 60 + nowMinute);

                        // If the session has already started, we consider the current time as the reference (diff = 0)
                        if (this.now.isAfter(session.start) && this.now.isBefore(session.end)) {
                            next = session;
                            return false; // This will exit the forEach loop early in lodash, but not in native JavaScript
                        }
                        // If this session is in the future and closer than others
                        else if (diff > 0 && diff < smallestDiff) {
                            smallestDiff = diff;
                            next = session;
                        }
                    }
                }
            });

            return next;
        },
    },
    computed: {
        sessions() {
            return store.state.sessions;
        },
        searchResults() {
            if (!this.searchTerm) return [];

            const sessionSrch = new fuse(this.sessions, { keys: ["title", "description", "speakers.name"] });
            const speakersSrch = new fuse(store.state.speakers, { keys: ["name", "jobTitle"] });

            const sessionResults = sessionSrch.search(this.searchTerm);
            const speakerResults = speakersSrch.search(this.searchTerm);

            const results = {
                session: sessionResults,
                speakers: speakerResults,
            };
            return results;
        },
    },
    created() {
        let days = {};
        this.sessions.forEach((session) => {
            let day = moment(session.start).startOf("day");
            let dayKey = day.format("YYYY-MM-DD");

            if (!days[dayKey]) {
                days[dayKey] = {
                    day: day,
                    dayString: day.format("YYYY-MM-DD"),
                    dayFormated: day.format("ddd MMMM Do"),
                    sessions: [],
                };
            }

            const start = moment(session.start);
            const end = moment(session.end);

            const duration = moment.duration(end.diff(start));
            const hours = duration.hours();
            const minutes = duration.minutes();

            // Format the duration string based on the hours and minutes
            let formattedDuration = "";
            if (hours > 0) {
                // There are hours, include them in the output
                formattedDuration += hours + "h ";
            }
            if (minutes > 0 || hours === 0) {
                // There are minutes, or no hours and no minutes, include them in the output
                formattedDuration += minutes + "min";
            }

            days[dayKey].sessions.push({
                durationInMinutes: duration.asMinutes(),
                duration: formattedDuration,
                ...session,
                start: start,
                end: end,
            });
        });

        // convert to array
        days = Object.values(days).sort((a, b) => {
            return a.day - b.day;
        });

        this.sessionsGrouped = days;
    },
    mounted() {
        window.onscroll = () => {
            if (!this.$refs.topButton) return;
            if (window.scrollY > 100) {
                this.$refs.topButton.style.display = "flex";
            } else {
                this.$refs.topButton.style.display = "none";
            }
        };

        let testTime = this.$route.query.testTime;

        if (testTime) {
            this.now = moment("3/11/2024 9:30", "MM/DD/YYYY HH:mm");
        }

        this.countDown = this.calculateCountDown();
        this.nowSession = this.getCurrentSession();
        this.nextSession = this.getNextSessionFromNow();

        updateTimeInterval = setInterval(() => {
            if (testTime) {
                this.now = moment(this.now).add(1, "minutes");
            }
            this.countDown = this.calculateCountDown();
            this.nowSession = this.getCurrentSession();
            this.nextSession = this.getNextSessionFromNow();
        }, 1000);
    },
    unmounted() {
        window.onscroll = null;
        if (updateTimeInterval) {
            clearInterval(updateTimeInterval);
        }
    },
};
</script>

<style scoped lang="scss">
.cover {
    @apply fixed inset-0 z-50 transition-all;
    background-color: black;
    opacity: 0;
    pointer-events: none;
    z-index: 100;
    &.is-active {
        pointer-events: all;
        opacity: 0.9;
    }
}
// .search {
//     input {
//         @apply transition-all ease-in-out duration-100;
//     }
//     .fa {
//         @apply text-black-300;
//     }
//     &:focus {
//         @apply bg-black-500;
//         .fa {
//             @apply text-teal-200;
//         }
//     }
// }
</style>
