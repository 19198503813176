import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

import AboutView from "../views/AboutView.vue";
import SessionView from "../views/SessionView.vue";
import SpeakerSingleView from "../views/SpeakerSingleView.vue";
import SpeakersView from "../views/SpeakersView.vue";
import ParticipantsView from "../views/ParticipantsView.vue";
import AnalyticsView from "../views/AnalyticsView.vue";
import MissingView from "../views/MissingView.vue";

import store from "../store";
const routes = [
    {
        path: "/",
        name: "home",
        component: HomeView,
    },
    {
        path: "/participants",
        name: "participants",
        component: ParticipantsView,
    },
    {
        path: "/speakers",
        name: "speakers",
        component: SpeakersView,
    },
    {
        path: "/analytics",
        name: "analytics",
        component: AnalyticsView,
    },

    {
        path: "/speakers/:id",
        name: "speaker-single",
        component: SpeakerSingleView,
    },
    {
        path: "/sessions/:id",
        name: "session",
        component: SessionView,
    },
    {
        path: "/about",
        name: "about",
        component: AboutView,
    },
    // 404
    {
        path: "/:pathMatch(.*)*",
        name: "missing",
        component: MissingView,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { left: 0, top: 0 };
        }
    },
});

router.beforeEach((to, from, next) => {
    store.commit("closeSearch");
    store.commit("closeMenu");
    next();
});

export default router;
