<template>
    <main-nav :hasSearch="false" />
    <schedule class="min-h-screen my-9" />
    <div class="fixed top-0 w-full min-h-screen flex items-center z-50" v-if="showUserForm">
        <div class="bg-black-900 fixed z-10 top-0 bottom-0 w-full h-full bg-opacity-80"></div>
        <div class="bg-black-300 flex justify-center max-w-sm mx-auto">
            <user-profile-form @close="showUserForm = false" class="z-20" />
        </div>
    </div>
    <main-footer />
</template>

<script>
import store from "@/store";
import UserProfileForm from "@/components/UserProfileForm.vue";

export default {
    name: "Home",
    components: {
        UserProfileForm,
    },
    mixins: [],
    data() {
        return {
            showUserForm: true,
        };
    },
    methods: {},
    created() {},
    mounted() {
        let k = this.$cookies.get("didShowUserForm1") === "true";
        if (!k) {
            this.$cookies.set("didShowUserForm1", "true");
            this.showUserForm = true;
        } else {
            this.showUserForm = false;
        }
    },
};
</script>

<style lang="scss"></style>
