<template>
    <div class="flex gap-2">
        <div v-for="i in stars" class="" :key="`star-${i}`">
            <div :class="` text-black-600 rounded-full w-9 h-9 flex items-center justify-center ${ratingColor(i)}`">
                <i class="fa-solid fa-star"></i>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        rating: {
            type: Number,
            default: 0,
        },
    },
    name: "Stars",
    components: {},
    mixins: [],
    data() {
        return {
            stars: 5,
        };
    },
    methods: {
        ratingColor(i) {
            if (i <= this.rating) {
                return "bg-amber-200";
            }
            return "bg-white";
        },
    },
    created() {},
    mounted() {},
};
</script>

<style scoped lang="scss"></style>
