import MainNav from "./MainNav";
import MainFooter from "./MainFooter";
import Schedule from "./Schedule";
import DaySchedule from "./DaySchedule";
import Logo from "./Logo";
import SpeakerImage from "./SpeakerImage";
import SpeakerCard from "./SpeakerCard";
import Comments from "./Comments.vue";
import Rating from "./Rating.vue";
import Shapes from "./Shapes.vue";
import VueMarkdown from "vue-markdown-render";
import Search from "./Search.vue";
const components = {
    MainNav,
    Search,
    Logo,
    SpeakerImage,
    MainFooter,
    Schedule,
    DaySchedule,
    SpeakerCard,
    Comments,
    Rating,
    Shapes,
    markdown: VueMarkdown,
};

export default {
    install: (app) => {
        for (const component in components) {
            app.component(component, components[component]);
        }
    },
};
