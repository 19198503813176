<template>
    <main-nav></main-nav>
    <div class="min-h-screen" v-if="speaker && Object.keys(speaker).length > 0">
        <div class="container">
            <div class="flex flex-wrap bg-black-700 mt-9 pb-6 min-h-[50vh]">
                <!-- show speaker details -->
                <div class="w-full lg:w-1/2">
                    <div class="mb-6 md:p-6">
                        <img :src="speaker.photo.url" alt="" class="w-full max-h-[400px] h-auto object-cover grayscale" />
                    </div>
                    <div class="px-6 flex flex-col justify-between">
                        <div class="flex-grow mb-6">
                            <div class="text-3xl font-thin">
                                {{ speaker.name }}
                            </div>
                            <div class="py-1 text-xs leading-5 text-black-200">
                                {{ speaker.jobTitle }}
                            </div>
                        </div>
                        <a v-if="speaker.website" :href="speaker.website" class="flex items-center">
                            <i class="hover:opacity-80 cursor-pointer fa-brands fa-linkedin"></i>
                        </a>
                    </div>
                </div>

                <!-- bio -->
                <div class="text-white font-thin leading-9 markdown text-xl w-full lg:w-1/2 p-6 lg:pl-0">
                    <markdown v-if="speaker.bio" :source="speaker.bio"></markdown>
                </div>
            </div>
        </div>

        <!-- list sessios -->
        <div class="container mt-9 mb-36">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div v-for="session in sessions" class="bg-black-700 w-full">
                    <div class="w-full p-6">
                        <div>
                            <h3 class="text-2xl text-teal-200">{{ session.title }}</h3>
                            <div class="text-black-100">
                                <div class="mb-2 date text-black-100">{{ session.start.format("dddd MMMM Do ") }}</div>
                                <div class="mb-2">
                                    <i class="fa-regular fa-clock"></i>
                                    {{ session.timeRange }}
                                </div>
                                <a v-if="session.address" @click.prevent="$openLocation(session.address)">
                                    <div class="text-xs hover:underline inline-block cursor-pointer">
                                        <i class="fa-sharp fa-light fa-location-dot mr-2"></i>{{ session.address }}
                                    </div>
                                </a>
                            </div>
                            <div class="flex gap-2 text-sm text-black-200 my-3 flex-wrap">
                                <router-link
                                    class="hover:text-black-100 border-b border-b-transparent hover:bg-black-600 text-xs text-nowrap bg-black-500 p-1"
                                    :to="`/speakers/${speaker._id}`"
                                    v-for="speaker in session.speakers"
                                    >{{ speaker.name }}</router-link
                                >
                            </div>
                            <markdown class="text-gray-400 markdown" :source="session.description" v-if="session.description"></markdown>
                        </div>
                        <div class="flex mt-9 gap-3">
                            <router-link
                                v-if="session.url"
                                :to="`/sessions/${session._id}?speaker=${speaker._id}`"
                                :class="`px-3 py-2 text-black-900 text-sm font-medium text-center cursor-pointer transition-all hover:bg-black-800 hover:text-white  bg-white rounded-full  cta`"
                            >
                                Session Details
                            </router-link>
                            <a @click="registerUserForSession(session)" :class="getDidUserRegister(session)"> I'm Going! </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <main-footer></main-footer>
</template>

<script>
import store from "@/store";
import { registerForSession } from "@/auth";
export default {
    name: "SpeakerView",
    data() {
        return {
            speaker: {},
        };
    },
    methods: {
        async updateSpeaker() {
            this.speaker = store.state.speakers.find((speaker) => speaker._id === this.$route.params.id);
            if (!this.speaker) {
                console.log("Speaker not found", this.$route.params.id);
                this.$router.push("/speakers");
            }
        },
        async registerUserForSession(session) {
            let isAttending = this.$store.state.user && this.$store.state.user.sessions[session._id] === true;

            registerForSession(session._id, !isAttending);
        },
        getDidUserRegister(session) {
            let isAttending = this.$store.state.user && this.$store.state.user.sessions[session._id] === true;
            return `px-3 py-2 text-black-900 text-sm font-medium text-center cursor-pointer transition-all hover:bg-black-800 hover:text-white  rounded-full cta ${
                isAttending ? "bg-amber-200" : "bg-white"
            }`;
        },
    },
    computed: {
        photo() {
            return this.speaker.photo ? this.speaker.photo.url : "";
        },
        sessions() {
            if (this.speaker.sessions) {
                return this.speaker.sessions.map((session) => this.$store.state.sessions.find((s) => s._id === session));
            }
            return [];
        },
    },
    watch: {
        "$route.params.id": {
            immediate: true,
            async handler() {
                await this.updateSpeaker();
            },
        },
    },
    async mounted() {
        await this.updateSpeaker();
    },
};
</script>

<style scoped lang="scss"></style>
