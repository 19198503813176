<template>
    <main-nav></main-nav>

    <!-- <svg width="0" height="0">
        <filter id="grainy" x="0" y="0" width="100%" height="100%">
            <feTurbulence type="fractalNoise" baseFrequency=".537"></feTurbulence>
            <feColorMatrix type="saturate" values="0"></feColorMatrix>
            <feBlend mode="multiply" in="SourceGraphic"></feBlend>
        </filter>
    </svg>
    <div></div> -->

    <div class="p-8 container">
        <div class="container min-h-screen">
            <div class="w-full">
                <h3 class="placeholder-yellow-950 text-5xl md:text-6xl font-thin">Participants</h3>

                <div class="relative w-full py-9 max-w-lg">
                    <div class="relative w-full mx-auto search flex justify-center rounded-full bg-black-600 text-center">
                        <input
                            type="text"
                            class="pl-3 py-4 flex h-full w-[75%] md:w-[83%] text-black-100 bg-transparent border-transparent"
                            placeholder="Search for a participant..."
                            v-model="searchTerm"
                        />
                        <i class="fa fa-search absolute left-5 top-1/2 transform -translate-y-1/2 text-gray-400"></i>
                    </div>
                </div>

                <!-- <div class="columns-1 md:columns-2 lg:columns-3 xl:columns-4 gap-6"> -->
                <div class="md:flex md:flex-wrap md:gap-6 md:justify-between">
                    <div v-for="person in participants" :key="person._id" class="bg-black-700 mb-9 w-full md:w-[200px] flex flex-col justify-between person">
                        <div>
                            <!-- :class="`tile grain relative min-w-full flex h-36 hover:scale-105 transition-all overflow-hidden bg-gradient-to-b from-${person.colorId}-400  to-${person.colorId}-500`" -->
                            <a
                                :href="person.linkedin"
                                :class="`tile relative min-w-full flex h-36 hover:bg-${person.colorId}-500 bg-${person.colorId}-200 hover:scale-105 transition-all overflow-hidden `"
                            >
                                <!-- <blob :color="person.colorId" /> -->
                                <!-- <gradient :id="person._id" :name="person.name" :color="person.colorId" /> -->
                            </a>

                            <div class="text-left p-6">
                                <a :href="person.linkedin" target="_blank" class="text-2xl font-medium">
                                    {{ person.name }}
                                </a>
                                <div class="pt-3">
                                    {{ person.company }}
                                </div>
                                <div class="pt-1 text-xs text-black-200">
                                    {{ person.title }}
                                </div>
                            </div>
                        </div>
                        <div class="py-3 px-6">
                            <a v-if="person.linkedin" :href="person.website" class="">
                                <i class="py-3 hover:opacity-80 cursor-pointer fa-brands fa-linkedin"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <main-footer></main-footer>
</template>

<script>
import Blob from "@/components/Blob.vue";
import Fuse from "fuse.js";
import Gradient from "@/components/Gradient.vue";

export default {
    name: "SpeakerView",
    components: { Gradient },
    mixins: [],
    data() {
        return {
            searchTerm: "",
        };
    },
    methods: {},
    computed: {
        participants() {
            if (!this.searchTerm) {
                return this.$store.state.participants;
            }
            const options = {
                keys: ["name", "title"],
            };
            const fuse = new Fuse(this.$store.state.participants, options);
            const result = fuse.search(this.searchTerm);

            return result.map((item) => item.item);
        },
    },
    created() {},
    mounted() {
        // // make a random string
        // setInterval(() => {
        //     this.$store.state.participants.map((p) => {
        //         let text = "";
        //         let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        //         for (let i = 0; i < 15; i++) {
        //             text += possible.charAt(Math.floor(Math.random() * possible.length));
        //         }
        //         p.name = text;
        //     });
        // }, 1000);
    },
};
</script>

<style scoped lang="scss">
.image-link {
    img {
        overflow: hidden;
        transition: transform 0.15s ease;
        @apply grayscale;
    }
    &:hover {
        img {
            //scale 120%
            transform: scale(1.2);
        }
    }
}
.person {
}
.grain {
    position: relative;
}
.grain::after {
    content: "";
    background-image: url("https://grainy-gradients.vercel.app/noise.svg");
    opacity: 1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: 1;
    pointer-events: none;
    filter: contrast(170%) brightness(100%);
}
</style>
