<template>
    <div class="w-full h-auto flex" ref="sessionsMainContainer" v-if="now">
        <div class="flex h-full w-full relative schedule" ref="sessionsContainer">
            <!-- Time slots -->
            <div class="w-full h-full absolute" ref="sessionsTimes">
                <div v-for="(time, index) in times" :key="time" :class="`border-t border-black-800  w-full bg-black-600 text-white`" :style="timeSlotPosition(time, index)">
                    <div
                        :id="isTimeSlotNow(time) ? 'now' : ''"
                        :class="`flex items-center w-7 md:w-[60px] uppercase md:text-sm h-full text-xs text-white ${isTimeSlotNow(time) ? 'bg-blue-800' : ''}`"
                    >
                        <div class="w-full text-center text-nowrap -rotate-90 whitespace-nowrap">{{ time }}</div>
                    </div>
                </div>
            </div>

            <!-- time bar -->
            <div class="time-bar" id="now-bar" :style="liveTimeStyle" v-if="showTimebar">
                <div class="line"></div>
                <div class="now">
                    <span>{{ now.format("h:mm A") }}</span>
                </div>
            </div>

            <!-- Events -->
            <div class="w-full h-full ml-7 md:ml-[60px] relative bg-opacity-50 flex" ref="sessions">
                <div
                    v-for="event in sessionsComputed"
                    :id="`session-${event._id}`"
                    :ref="`session-${event._id}`"
                    :key="event.id"
                    :style="getStyle(event)"
                    :class="getClass(event)"
                    @click="toggleSession(event)"
                    class="absolute text-black-900 border border-black-500 cursor-pointer h-full transition-all session"
                >
                    <!-- <div class="absolute w-full h-full overflow-hidden opacity-50 z-0 shapes pointer-events-none">
                        <shapes class="absolute bottom-[-10%] right-[-20%]" :shapeIndex="event.shapeIndex" color="rgba(0, 0, 0, 0.6)" />
                    </div> -->

                    <div class="details">
                        <div class="details-wrap grow">
                            <div>
                                <!-- Company Icon -->
                                <!--  -->
                                <div v-if="event.company" class="company-name">
                                    <i v-if="event.icon" :class="`mr-1 fa-brands ${event.icon}`"></i>
                                    <div>
                                        <span>{{ event.company }}</span>
                                    </div>
                                </div>

                                <!-- title -->
                                <!--  -->
                                <router-link :to="`/sessions/${event._id}`" class="title">
                                    <span> <i v-if="event.type.indexOf('travel') !== -1" class="fa-sharp fa-light fa-bus inline mr-1"></i>{{ event.title }} </span>
                                </router-link>
                                <!--  -->
                                <!-- close open session button  -->
                                <!--  -->
                                <a class="close-button absolute top-0 right-0 px-3 z-50 py-2 items-center justify-center bg-white" href="#close" @click.stop="closeSession">
                                    <i class="fa-solid fa-xmark"></i>
                                </a>
                                <!--  -->
                                <!-- time from start - end -->
                                <!--  -->
                                <div class="flex w-full justify-between items-center">
                                    <div class="session-time">
                                        <div class="date">{{ event.start.format("ddd DD MMMM") }}</div>
                                        <i class="fa-regular fa-clock"></i>
                                        {{ event.timeRange }}
                                    </div>
                                </div>

                                <!--  -->
                                <!-- description (only if we have one and not a sessions with spearker) -->
                                <!--  -->
                                <div class="description">{{ event.description }}</div>
                            </div>

                            <!-- sessions button -->
                            <!--  -->
                            <div class="flex grow items-center justify-center mb-6">
                                <router-link
                                    v-if="event.url"
                                    :to="`/sessions/${event._id}`"
                                    :class="`py-3 text-black-900 font-medium text-center cursor-pointer transition-all hover:bg-black-800 hover:text-white  bg-white rounded-full w-40 cta`"
                                >
                                    Session Details
                                </router-link>
                            </div>
                        </div>

                        <!--  -->
                        <!-- sessions footer -->
                        <!--  -->
                        <div class="details-footer">
                            <!-- Location of session -->
                            <a v-if="event.address" class="location hover:underline" @click.prevent="$openLocation(event.address)">
                                <i class="fa-sharp fa-light fa-location-dot mr-1"></i>{{ event.address }}
                            </a>
                        </div>
                        <!--  -->
                        <!--  -->
                        <!--  -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment, { duration } from "moment-timezone";
import { getColor } from "../colors-table";
import Shapes from "./Shapes.vue";

const remap = (value, low1, high1, low2, high2) => {
    return low2 + ((value - low1) * (high2 - low2)) / (high1 - low1);
};
export default {
    name: "DaySchedule",
    props: {
        now: {
            type: Object,
            required: true,
        },
        selectedSession: {
            default: () => null,
        },
        day: {
            type: String,
            required: true,
        },
        sessions: {
            type: Array,
            required: true,
            default: () => [],
        },
    },
    data() {
        return {
            full: {},
        };
    },
    computed: {
        showTimebar() {
            if (!this.now) {
                return false;
            }
            if (this.isSessionToday) {
                // is now > start of day and < end of day
                let start = moment(this.day).hour(this.startHour).minute(0);
                let end = moment(this.day).hour(this.endHour).minute(0).add(1, "minute");

                return this.now.isBetween(start, end);
            }
            return false;
        },
        liveTimeStyle() {
            // let test = moment("3/11/2024 7:00 AM", "M/D/YYYY h:mm A");
            // let now = moment(test, "h:mm A");

            let now = this.now;

            // see if now is this.day format YYYY-MM-DD
            if (now.format("YYYY-MM-DD") === this.day) {
                let start = moment(this.day).hour(this.startHour).minute(0);
                let end = moment(this.day).hour(this.endHour).minute(0).add(1, "minute");
                let totalMins = this.totalMinutes;

                // get the mins from the start of the day to now
                let mins = now.diff(start, "minutes");

                // remamp now from start , end to 0, 100
                let top = remap(mins, 0, totalMins, 0, 100);

                return {
                    top: top + "%",
                };
            }
        },
        isSessionToday() {
            return this.now.format("YYYY-MM-DD") === this.day;
        },
        startHour() {
            let start = this.sessions.reduce((acc, event) => {
                if (event.start.hour() < acc) {
                    return event.start.hour();
                }
                return acc;
            }, 24);
            return start;
        },
        endHour() {
            let end = this.sessions.reduce((acc, event) => {
                if (event.end.hour() > acc) {
                    return event.end.hour();
                }
                return acc;
            }, 0);
            return end + 1;
        },
        totalMinutes() {
            return (this.endHour - this.startHour) * 60;
        },
        times() {
            let times = [];
            let start = moment(this.day).hour(this.startHour).minute(0);
            let end = moment(this.day).hour(this.endHour).minute(0);

            while (start < end) {
                times.push(start.format("h:mm A"));
                start.add(1, "hours");
            }

            return times;
        },
        sessionsComputed() {
            let s = this.sessions.map((event) => ({
                ...event,
                start: event.start,
                end: event.end,
            }));

            let hourBins = {};
            for (let i = this.startHour; i <= this.endHour; i++) {
                hourBins[i] = 0; // Initialize hour bins
            }

            s.forEach((event) => {
                let startHour = event.start.hour();
                hourBins[startHour] += 100; // Increment bin count
                event.binCount = hourBins[startHour];
                event.binIndex = hourBins[startHour] / 100 - 1;
            });

            // sort based on start time
            s.sort((a, b) => {
                return a.start - b.start;
            });

            s = s.map((event) => {
                let startHour = event.start.hour();
                let binIndex = event.binCount / 100 - 1;
                let eventStart = event.start; // Already in the correct timezone
                let dayStart = moment(this.day).hour(this.startHour).minute(0); // Assuming this is already set to the correct time
                let topPercentage = (eventStart.diff(dayStart, "minutes") / this.totalMinutes) * 100;

                let duration = event.duration;

                //figire out if there is a gap based on if this is the first event in the bin
                let gap = hourBins[startHour] == 100 ? " " : "";

                let size = null;
                if (duration <= 15) {
                    size = "is-small";
                } else if (duration <= 30) {
                    size = "is-med";
                }

                let durationType = duration >= 60 ? "duration-long" : "duration-short";

                // // get the top percentage
                // let start = moment(this.day).hour(this.startHour).minute(0);
                // let end = moment(this.day).hour(this.endHour).minute(0).add(1, "minute");

                // // get the mins from the start of the day to now
                // let mins = event.start.diff(start, "minutes");

                // // remamp now from start , end to 0, 100
                // let top = remap(mins, 0, this.totalMinutes, 0.0, 100.0);

                let style = {
                    top: `${topPercentage}%`,
                    width: "100px",
                    height: `${(duration / this.totalMinutes) * 100}%`,
                    width: `calc(${100 / (hourBins[startHour] / 100)}% ${gap})`,
                    left: `calc(${binIndex * (100 / (hourBins[startHour] / 100))}% )`,
                };

                return {
                    style: style,
                    ...event,
                    size: size,
                    durationType: durationType,
                };
            });

            return s;
        },
    },
    methods: {
        toggleSession(event) {
            console.log("sessionSelected");
            if (this.selectedSession) {
                this.$emit("sessionSelected", null);
                return;
            }
            this.$emit("sessionSelected", event);
        },
        closeSession() {
            this.$emit("sessionSelected", null);
        },
        getClass(event) {
            let c = `${this.selectedSession && event._id === this.selectedSession._id ? "is-selected" : ""} ${event.size}`;
            if (event.url) {
                c += " has-event-link before:bg-pink-300";
            }

            if (event.is_break) {
                c += " bg-pink-400 hover:bg-pink-300";
            } else {
                c += ` bg-${event.color}-300 hover:bg-${event.color}-200 `;
            }
            c += ` ${event.durationType}`;

            return c;
        },
        getStyle(event) {
            if (this.selectedSession && this.selectedSession._id === event._id) {
                let screenWidth = window.innerWidth;
                let screenHeight = window.innerHeight;
                let sessionsContainerEl = this.$refs.sessions;
                let offsetX = 0;

                if (sessionsContainerEl) {
                    let o = sessionsContainerEl.getBoundingClientRect();
                    offsetX = o.x;
                }

                let maxWidth = screenWidth > 600 ? 800 : 360;
                let top = 0;
                let left = 0;
                // left = (screenWidth - (offsetX + 360)) * 2;
                left = -offsetX + (screenWidth - maxWidth) / 2;

                // try to get the top
                let sessionEl = this.$refs[`session-${event._id}`];
                if (sessionEl && sessionEl.length > 0) {
                    sessionEl = sessionEl[0];
                    let r = sessionEl.getBoundingClientRect();
                    top = r.top - sessionsContainerEl.offsetTop;
                }

                return {
                    top: `${event.style.top}px`,
                    left: `${left}px`,
                    minHeight: "50vh",
                    height: "auto",
                    width: "100vw",
                    maxWidth: maxWidth + "px",
                    "transition-duration": "0.25s",
                };
            }
            return event.style;
        },
        isTimeSlotNow(time) {
            return this.isSessionToday && moment(time, "h:mm A").hour() === this.now.hour();
        },

        timeSlotPosition(time, index) {
            return {
                top: `${(moment(time, "h:mm A").diff(moment().hour(this.startHour).minute(0), "minutes") / this.totalMinutes) * 100}%`,
                height: `${(60 / this.totalMinutes) * 100}%`,
            };
        },
        adjustContainer() {
            if (this.$refs.sessionsMainContainer) {
                this.$nextTick(() => {
                    let rect = this.$refs.sessionsMainContainer.getBoundingClientRect();
                    let timeEl = this.$refs.sessionsTimes.children[0];
                    let h = rect.height + timeEl.offsetHeight;
                    this.$refs.sessionsContainer.style.height = `${h}px`;
                });
            }
        },
    },
    mounted() {
        this.adjustContainer();
    },
    unmounted() {},
};
</script>

<style scoped land="scss">
.schedule {
    height: calc(190vh + 200px);
    min-height: 4000px;
}
.time {
    /* Abs positioning makes it not take up vert space */
    position: absolute;
    top: 0;
    left: 20px;

    /* Border is the new background */
    background: none;

    /* Rotate from top left corner (not default) */
    transform-origin: 0 0;
    transform: rotate(90deg);
}
.marquee-text {
    animation: scroll 5s linear infinite;
    animation-delay: 0s;
}
@keyframes scroll {
    0% {
        left: -110%;
    }
    100% {
        left: 110%;
    }
}
.arrow-right {
    content: "";
    position: absolute;
    border-top: 10px solid transparent; /* Adjust size */
    border-bottom: 10px solid transparent; /* Adjust size */
    border-right: 10px solid black; /* Adjust color and size */
    top: 0;
    left: 0;
    transform: translateX(200%);
}

/* Now Time  */
.time-bar {
    width: calc(100% + 15px);
    height: 1px;
    position: absolute;
    @apply bg-blue-800 z-40 md:-ml-4;
    user-select: none;
    pointer-events: none;
    .now {
        &::after {
        }
        @apply md:p-3 p-[7px] text-2xs md:text-xs -translate-y-1/2 -translate-x-3/4 -rotate-90 bg-blue-800 md:text-white;
        span {
            @apply text-nowrap relative top-[6px] md:top-0;
        }
        position: absolute;
    }
}

/* sessions */
.session {
    .details {
        display: flex;
        flex-wrap: wrap;

        @apply p-3 md:p-3 md:pt-1 flex-col justify-between;

        .description {
            @apply py-6 text-lg;
            display: none;
        }

        .close-button {
            @apply hidden;
        }

        .details-wrap {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: auto;
            @apply flex-grow;
        }
        .details-footer {
            display: flex;
            align-items: flex-end;
            justify-content: flex-start;
            width: 100%;
        }

        &:hover {
            filter: saturate(2.95);
        }
        .location {
        }

        /* company logo and namp (if session has one) */
        .company-name {
            white-space: nowrap;
            @apply font-medium;
            @apply md:text-2xl capitalize min-h-6 flex  items-center;
            position: relative;
            div {
                width: 100%;
                overflow: hidden;
                position: relative;
            }
            div span {
                position: relative;
                height: 100%;
                display: flex;
                /* @apply marquee-text;  TODO: will this work? */
            }
        }

        /* title of the session */
        .title {
            @apply font-medium pointer-events-none;
            @apply text-sm md:text-lg overflow-hidden flex break-words;
            span {
                @apply border-b-2 border-b-transparent hover:border-b-black-800;
            }
        }

        /* time of session start - end */
        .session-time {
            @apply text-sm md:block hidden;
        }
        .duration {
            @apply text-sm hidden md:block py-2;
        }
        .company {
            @apply absolute w-full bottom-0 left-0;
        }
        .location,
        .cta {
            display: none;
        }
    }

    &.is-med {
        .title {
            @apply text-sm;
        }
        .duration {
            @apply text-sm;
        }
        .session-time {
            @apply hidden;
        }
    }
    &.is-small {
        .session-time {
            @apply hidden;
        }
        .duration {
            @apply hidden;
        }
        .title {
            @apply text-xs;
            margin-bottom: 0 !important;
        }
        .company {
            @apply hidden;
        }
    }
    .date {
        display: none;
    }
    .shapes {
        @apply opacity-0 transition-all;
    }

    &.duration-long {
        .session-time {
            @apply block;
        }
    }
    /*  */
    /* this session has speakers and more info */
    /*  */
    &.has-event-link {
        @apply before:w-[4px];
        &::before {
            height: 100%;
            content: "";
            position: absolute;
        }
        .details {
        }
    }
    /*  */
    /* we have click the session and it is full screem */
    /*  */
    z-index: 20;
    &.is-selected {
        z-index: 300;

        .details {
            min-height: 50vh;
        }
        .close-button {
            @apply flex;
        }
        .company-name {
            @apply text-2xl mr-8;
        }
        .title {
            @apply text-xl mr-8 pointer-events-auto;
        }
        .description {
            display: flex;
        }
        .date {
            @apply text-lg mt-2;
            display: flex;
        }
        .location {
            @apply text-sm;
        }
        .location,
        .cta {
            display: inline;
        }
        /* shapes art */
        .shapes {
            @apply opacity-20 z-0;
            svg {
                @apply -bottom-[60%] rotate-90;
            }
        }
        /* sessions mark as small */
        &.is-small {
            .session-time {
                @apply block;
            }
            .duration {
                @apply block;
            }

            .company {
                @apply block;
            }
        }

        /* .shapes {
            @apply block;
        }
        height: 50vh !important;
        z-index: 1000;

      

        .details {
            @apply py-4 justify-between;
        }
       
        .title {
            @apply text-3xl max-w-[50%] pb-4;
        }
        .duration {
            @apply block;
        }
        .session-time {
            @apply block;
        }
        .details {
            padding: 30px;
        } */
    }
}
</style>
