<template>
    <!-- <img :src="url" alt="" class="object-fit" />
     -->
    <div class="text-[3px]">
        {{ this.photo }}
    </div>
</template>

<script>
export default {
    props: {
        photo: {
            type: Array,
            default: () => [],
        },
    },
    name: "SpeakerImage",
    components: {},
    mixins: [],
    data() {
        return {};
    },
    methods: {},
    computed: {
        url() {
            return this.photo && this.photo.length > 0 ? this.photo[0].url : "https://via.placeholder.com/600";
        },
    },
    mounted() {},
};
</script>

<style scoped lang="scss"></style>
