import { createStore } from "vuex";

export default createStore({
    state: {
        user: null,
        menuOpen: false,
        searchOpen: false,
        sessions: [],
        speakers: [],
        participants: [],
        language: "en",
        menu: {
            home: {
                title: "Schedule",
                url: "/",
            },
            speakers: {
                title: "Speakers",
                url: "/speakers",
            },
            participants: {
                title: "Participants",
                url: "/participants",
            },
        },
    },
    getters: {},
    mutations: {
        toggleMenu(state) {
            state.menuOpen = !state.menuOpen;
        },
        toggleSearch(state) {
            state.searchOpen = !state.searchOpen;
        },
        closeMenu(state) {
            state.menuOpen = false;
        },
        closeSearch(state) {
            state.searchOpen = false;
        },
        setUser(state, user) {
            state.user = user;
        },
        setUserHasPassword(state, value) {
            if (state.user) {
                state.user.hasAppPassword = value;
            }
        },
        setLanguage(state, language) {
            state.language = language;
        },
    },
    actions: {},
    modules: {},
});
