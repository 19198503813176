<template>
    <footer class="bg-dark-300 w-full flex justify-center mt-6 py-9 z-20 relative">
        <div class="h-full w-full">
            <div class="container flex flex-col md:items-start gap-3 justify-center w-full h-full">
                <logo />

                <ul class="flex flex-row gap-3 text-white text-xs">
                    <!-- <li><a href="#link">About</a></li> -->
                    <!-- <li><a href="#link">FAQ</a></li> -->
                    <!-- <li><a href="#link">Help</a></li> -->
                    <li>
                        <a href="#link">Made with <i class="mx-1 fa-solid fa-heart"></i> @IDEO</a>
                    </li>
                </ul>

                <div class="text-black-400 text-xs uppercase">V {{ version }}</div>

                <!-- <div class="w-full h-full absolute bottom-0 right-0 flex items-center overflow-hidden">
                    <div class="absolute right-0 -bottom-6 overflow-hidden">
                        <svg width="327" height="173" viewBox="0 0 327 173" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="209" y="130" width="18" height="43" rx="9" transform="rotate(90 209 130)" fill="#04CEEA" />
                            <rect x="245" y="101" width="18" height="43" rx="9" transform="rotate(90 245 101)" fill="#04CEEA" />
                            <rect x="159" y="82" width="18" height="43" rx="9" transform="rotate(90 159 82)" fill="#04CEEA" />
                            <rect x="202" y="34" width="18" height="43" rx="9" transform="rotate(90 202 34)" fill="#04CEEA" />
                            <rect x="43" y="64" width="18" height="43" rx="9" transform="rotate(90 43 64)" fill="#04CEEA" />
                            <rect x="327" y="101" width="18" height="43" rx="9" transform="rotate(90 327 101)" fill="#04CEEA" />
                            <rect x="263" y="43" width="18" height="43" rx="9" transform="rotate(-180 263 43)" fill="#04CEEA" />
                            <rect x="319" y="27" width="18" height="43" rx="9" transform="rotate(90 319 27)" fill="#04CEEA" />
                            <rect x="275" y="173" width="18" height="43" rx="9" transform="rotate(-180 275 173)" fill="#04CEEA" />
                        </svg>
                    </div>
                </div> -->
            </div>
        </div>
    </footer>
</template>

<script>
import { version } from "../../package.json"; // Adjust the path as necessary
export default {
    name: "main-footer",
    components: {},
    mixins: [],
    data() {
        return {
            version: version,
        };
    },
    methods: {},
    created() {},
    mounted() {},
};
</script>

<style scoped lang="scss">
li a {
    @apply border-b border-b-transparent hover:border-b-black-100;
}
</style>
