<template>
    <div class="bg-black-700 mb-9 w-full md:w-[200px] flex flex-col justify-between">
        <div>
            <router-link :to="`/speakers/${speaker._id}`" class="image-link">
                <img :src="speaker.photo.url" :alt="speaker.name" class="hover:scale-105 transition-all w-full h-[300px] md:h-[200px] object-cover overflow-hidden grayscale" />
            </router-link>
            <div class="text-left p-6">
                <router-link :to="`/speakers/${speaker._id}`" class="text-2xl font-medium">
                    {{ speaker.name }}
                </router-link>
                <div class="py-1 text-xs leading-5 text-black-200">
                    {{ speaker.jobTitle }}
                </div>
            </div>
        </div>
        <div class="py-3 px-6">
            <a v-if="speaker.website" :href="speaker.website" class="">
                <i class="py-3 hover:opacity-80 cursor-pointer fa-brands fa-linkedin"></i>
            </a>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        speaker: {
            type: Object,
            required: true,
        },
    },
    name: "SpeakerCard",
    components: {},
    mixins: [],
    data() {
        return {};
    },
    methods: {},
    created() {},
    mounted() {},
};
</script>

<style scoped lang="scss"></style>
