<template>
    <div ref="svgContainer">
        <div v-if="index == 0">
            <svg width="870" height="1230" viewBox="0 0 870 1230" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_14_703)">
                    <path d="M1.62398 1049.97L4.28983 1.64645L867.98 305.544L867.089 656.003L1.62398 1049.97Z" stroke="#231F20" stroke-miterlimit="10" />
                    <path d="M8.09526 1053.45L10.7335 15.9929L865.489 313.948L864.59 667.226L8.09526 1053.45Z" stroke="#231F20" stroke-miterlimit="10" />
                    <path d="M14.5667 1056.94L17.1773 30.3292L862.987 322.351L862.082 678.46L14.5667 1056.94Z" stroke="#231F20" stroke-miterlimit="10" />
                    <path d="M21.0374 1060.41L23.6204 44.6659L860.496 330.745L859.583 689.684L21.0374 1060.41Z" stroke="#231F20" stroke-miterlimit="10" />
                    <path d="M27.499 1063.9L30.0544 59.0119L857.995 339.148L857.075 700.907L27.499 1063.9Z" stroke="#231F20" stroke-miterlimit="10" />
                    <path d="M33.9702 1067.38L36.4979 73.3487L855.503 347.552L854.576 712.141L33.9702 1067.38Z" stroke="#231F20" stroke-miterlimit="10" />
                    <path d="M40.4411 1070.86L42.9413 87.6849L853.002 355.956L852.067 723.365L40.4411 1070.86Z" stroke="#231F20" stroke-miterlimit="10" />
                    <path d="M46.9124 1074.35L49.385 102.031L850.51 364.349L849.569 734.588L46.9124 1074.35Z" stroke="#231F20" stroke-miterlimit="10" />
                    <path d="M53.3834 1077.82L55.8283 116.368L848.009 372.753L847.06 745.822L53.3834 1077.82Z" stroke="#231F20" stroke-miterlimit="10" />
                    <path d="M59.8546 1081.31L62.2719 130.704L845.518 381.157L844.562 757.046L59.8546 1081.31Z" stroke="#231F20" stroke-miterlimit="10" />
                    <path d="M66.3161 1084.79L68.7058 145.051L843.017 389.561L842.053 768.279L66.3161 1084.79Z" stroke="#231F20" stroke-miterlimit="10" />
                    <path d="M72.7868 1088.27L75.1489 159.387L840.525 397.954L839.554 779.503L72.7868 1088.27Z" stroke="#231F20" stroke-miterlimit="10" />
                    <path d="M79.2583 1091.75L81.5928 173.723L838.024 406.358L837.046 790.726L79.2583 1091.75Z" stroke="#231F20" stroke-miterlimit="10" />
                    <path d="M85.7295 1095.24L88.0364 188.06L835.532 414.762L834.548 801.96L85.7295 1095.24Z" stroke="#231F20" stroke-miterlimit="10" />
                    <path d="M92.2005 1098.72L94.4798 202.407L833.031 423.165L832.039 813.184L92.2005 1098.72Z" stroke="#231F20" stroke-miterlimit="10" />
                    <path d="M98.6717 1102.2L100.923 216.743L830.54 431.559L829.541 824.408L98.6717 1102.2Z" stroke="#231F20" stroke-miterlimit="10" />
                    <path d="M105.133 1105.69L107.357 231.079L828.038 439.962L827.032 835.641L105.133 1105.69Z" stroke="#231F20" stroke-miterlimit="10" />
                    <path d="M111.604 1109.16L113.8 245.426L825.547 448.366L824.533 846.865L111.604 1109.16Z" stroke="#231F20" stroke-miterlimit="10" />
                    <path d="M118.075 1112.65L120.244 259.762L823.046 456.77L822.025 858.089L118.075 1112.65Z" stroke="#231F20" stroke-miterlimit="10" />
                    <path d="M124.546 1116.13L126.687 274.099L820.554 465.164L819.526 869.322L124.546 1116.13Z" stroke="#231F20" stroke-miterlimit="10" />
                    <path d="M131.018 1119.61L133.131 288.445L818.053 473.567L817.018 880.546L131.018 1119.61Z" stroke="#231F20" stroke-miterlimit="10" />
                    <path d="M137.489 1123.1L139.575 302.781L815.561 481.971L814.519 891.78L137.489 1123.1Z" stroke="#231F20" stroke-miterlimit="10" />
                    <path d="M143.95 1126.58L146.008 317.118L813.06 490.375L812.011 903.004L143.95 1126.58Z" stroke="#231F20" stroke-miterlimit="10" />
                    <path d="M150.421 1130.06L152.452 331.465L810.569 498.769L809.512 914.227L150.421 1130.06Z" stroke="#231F20" stroke-miterlimit="10" />
                    <path d="M156.892 1133.54L158.895 345.801L808.067 507.172L807.003 925.461L156.892 1133.54Z" stroke="#231F20" stroke-miterlimit="10" />
                    <path d="M163.363 1137.02L165.339 360.137L805.576 515.576L804.505 936.684L163.363 1137.02Z" stroke="#231F20" stroke-miterlimit="10" />
                    <path d="M169.834 1140.5L171.782 374.484L803.074 523.979L801.996 947.908L169.834 1140.5Z" stroke="#231F20" stroke-miterlimit="10" />
                    <path d="M176.306 1143.99L178.226 388.82L800.583 532.373L799.498 959.141L176.306 1143.99Z" stroke="#231F20" stroke-miterlimit="10" />
                    <path d="M182.767 1147.47L184.66 403.156L798.082 540.777L796.989 970.365L182.767 1147.47Z" stroke="#231F20" stroke-miterlimit="10" />
                    <path d="M189.238 1150.95L191.103 417.503L795.59 549.181L794.491 981.599L189.238 1150.95Z" stroke="#231F20" stroke-miterlimit="10" />
                    <path d="M195.709 1154.44L197.546 431.839L793.099 557.584L791.992 992.823L195.709 1154.44Z" stroke="#231F20" stroke-miterlimit="10" />
                    <path d="M202.18 1157.91L203.99 446.175L790.598 565.978L789.484 1004.05L202.18 1157.91Z" stroke="#231F20" stroke-miterlimit="10" />
                    <path d="M208.652 1161.4L210.434 460.512L788.106 574.382L786.985 1015.28L208.652 1161.4Z" stroke="#231F20" stroke-miterlimit="10" />
                    <path d="M215.123 1164.88L216.877 474.859L785.605 582.785L784.476 1026.5L215.123 1164.88Z" stroke="#231F20" stroke-miterlimit="10" />
                    <path d="M221.584 1168.36L223.311 489.195L783.113 591.189L781.978 1037.73L221.584 1168.36Z" stroke="#231F20" stroke-miterlimit="10" />
                    <path d="M228.055 1171.85L229.754 503.531L780.612 599.582L779.469 1048.96L228.055 1171.85Z" stroke="#231F20" stroke-miterlimit="10" />
                    <path d="M234.526 1175.33L236.198 517.878L778.121 607.986L776.971 1060.18L234.526 1175.33Z" stroke="#231F20" stroke-miterlimit="10" />
                    <path d="M240.998 1178.81L242.642 532.214L775.619 616.39L774.462 1071.42L240.998 1178.81Z" stroke="#231F20" stroke-miterlimit="10" />
                    <path d="M247.468 1182.29L249.085 546.551L773.128 624.794L771.963 1082.64L247.468 1182.29Z" stroke="#231F20" stroke-miterlimit="10" />
                    <path d="M253.94 1185.78L255.529 560.897L770.627 633.187L769.455 1093.87L253.94 1185.78Z" stroke="#231F20" stroke-miterlimit="10" />
                    <path d="M260.401 1189.25L261.962 575.233L768.135 641.591L766.957 1105.1L260.401 1189.25Z" stroke="#231F20" stroke-miterlimit="10" />
                    <path d="M266.872 1192.74L268.406 589.57L765.634 649.995L764.448 1116.32L266.872 1192.74Z" stroke="#231F20" stroke-miterlimit="10" />
                    <path d="M273.343 1196.22L274.849 603.917L763.142 658.399L761.949 1127.55L273.343 1196.22Z" stroke="#231F20" stroke-miterlimit="10" />
                    <path d="M279.814 1199.7L281.293 618.253L760.641 666.792L759.441 1138.78L279.814 1199.7Z" stroke="#231F20" stroke-miterlimit="10" />
                    <path d="M286.285 1203.19L287.736 632.589L758.15 675.196L756.942 1150L286.285 1203.19Z" stroke="#231F20" stroke-miterlimit="10" />
                    <path d="M292.757 1206.66L294.18 646.936L755.649 683.599L754.434 1161.24L292.757 1206.66Z" stroke="#231F20" stroke-miterlimit="10" />
                    <path d="M299.218 1210.15L300.613 661.272L753.157 692.003L751.935 1172.46L299.218 1210.15Z" stroke="#231F20" stroke-miterlimit="10" />
                    <path d="M305.689 1213.63L307.057 675.609L750.656 700.397L749.427 1183.69L305.689 1213.63Z" stroke="#231F20" stroke-miterlimit="10" />
                    <path d="M312.16 1217.11L313.501 689.945L748.164 708.8L746.928 1194.92L312.16 1217.11Z" stroke="#231F20" stroke-miterlimit="10" />
                    <path d="M318.631 1220.6L319.944 704.291L745.663 717.204L744.419 1206.14L318.631 1220.6Z" stroke="#231F20" stroke-miterlimit="10" />
                    <path d="M325.103 1224.08L326.388 718.628L743.171 725.608L741.921 1217.37L325.103 1224.08Z" stroke="#231F20" stroke-miterlimit="10" />
                    <path d="M739.412 1228.59L740.67 734.001L332.821 732.964L331.564 1227.55L739.412 1228.59Z" stroke="#231F20" stroke-miterlimit="10" />
                </g>
                <defs>
                    <clipPath id="clip0_14_703">
                        <rect width="1226.28" height="865.46" fill="white" transform="translate(869.249 3.146) rotate(90.1457)" />
                    </clipPath>
                </defs>
            </svg>
        </div>
        <div v-if="index == 1">
            <svg width="886" height="765" viewBox="0 0 886 765" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_14_574)">
                    <path
                        d="M1.13136 572.969C1.97975 568.864 2.72041 564.719 2.82814 560.519C5.70996 450.943 8.309 341.353 10.6387 231.75C11.6756 183.04 12.6587 134.315 13.5879 85.6042C14.8268 20.9165 84.3946 -19.8128 141.129 11.36C172.923 28.8308 210.32 44.6191 253.359 55.3197C361.75 82.2527 452.151 63.5436 496.159 51.0395C500.347 49.855 504.616 48.9936 508.925 48.4552L788.126 13.7424C852.092 5.78768 901.904 68.093 880.088 128.716C856.428 194.48 827.179 262.681 790.94 331.999C782.632 347.895 774.188 363.401 765.637 378.516C758.271 391.545 754.352 406.216 754.352 421.17V613.389C754.352 640.834 741.384 666.757 719.191 682.922C667.587 720.542 575.665 760.787 539.4 754.421C533.731 753.425 527.94 753.25 522.176 753.438L212.313 764.246C194.699 764.865 177.327 760.087 162.5 750.544C102.157 711.645 -7.31212 613.618 1.13136 572.969Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M27.3102 93.8552C28.7511 31.2 96.0162 -8.41217 150.932 20.7013C181.851 36.9338 218.171 51.6857 259.89 61.7133C364.565 87.058 452.393 70.0314 495.54 58.3887C499.539 57.3119 503.795 56.5717 507.915 56.0602L778.295 25.7084C840.349 18.817 888.613 79.1572 868.332 138.03C846.422 202.112 818.6 268.738 783.466 336.306C775.548 351.435 767.307 366.725 759.159 381.127C752.143 393.537 748.238 407.939 748.238 422.193L747.484 607.144C747.484 633.283 734.61 658.493 713.683 674.16C662.901 712.171 572.81 752.644 535.387 745.887C529.731 744.878 523.981 744.636 518.258 744.73L218.561 753.29C201.782 753.869 184.477 749.01 170.351 739.952C111.274 702.089 5.48103 607.575 13.13 567.37C13.8976 563.373 14.5709 559.348 14.6517 555.27C16.0926 479.195 18.3146 402.919 20.4019 326.777C21.2233 297.017 22.5835 265.508 23.2164 235.735C24.1994 189.339 26.2733 140.251 27.3371 93.8552H27.3102Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M41.0321 102.092C42.675 41.4695 107.516 3.20334 160.749 30.0287C190.726 45.144 226.021 58.7249 266.434 68.1064C367.406 91.8629 452.608 76.5322 494.92 65.7375C498.731 64.7684 502.973 64.1358 506.891 63.6512L768.437 37.6469C828.565 31.8188 875.173 90.1401 856.549 147.331C836.228 209.676 810.157 274.849 775.965 340.586C768.478 354.975 760.398 370.023 752.655 383.711C745.989 395.489 742.097 409.648 742.097 423.175L740.575 600.858C740.575 625.691 727.823 650.201 708.121 665.344C658.134 703.771 569.928 744.38 531.32 737.313C525.704 736.277 519.967 735.981 514.285 735.981L224.755 742.293C208.811 742.845 191.587 737.878 178.134 729.305C120.35 692.506 18.2469 601.504 25.0878 561.744C25.7612 557.854 26.3941 553.964 26.4345 550.007C27.2559 476.18 29.7876 401.976 32.1847 328.015C33.1004 299.736 35.1338 267.998 35.7398 239.705C36.6825 195.624 39.8471 146.16 41.0456 102.092H41.0321Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M54.7547 110.33C56.5996 51.7392 119.084 14.6979 170.553 39.3696C199.627 53.3005 233.899 65.697 272.965 74.4997C370.274 96.4256 452.797 83.0332 494.287 73.0864C497.91 72.2115 502.151 71.7135 505.855 71.2424L758.567 49.5991C816.782 44.8343 861.787 101.177 844.739 156.631C826.101 217.268 801.606 280.919 768.438 344.866C761.368 358.514 753.449 373.334 746.124 386.295C739.808 397.454 735.93 411.344 735.93 424.171L733.654 594.572C733.654 618.1 721.036 641.951 702.56 656.541C653.353 695.399 567.019 736.182 527.239 728.739C521.651 727.689 515.955 727.326 510.285 727.232L230.923 731.297C215.827 731.822 198.671 726.747 185.905 718.671C129.426 682.936 31.0133 595.46 37.0462 556.131C37.6253 552.362 38.2178 548.58 38.2313 544.744C38.4333 473.178 41.2882 401.047 43.9815 329.253C44.9915 302.468 47.7117 270.474 48.2908 243.689C49.1795 201.937 53.4484 152.082 54.7681 110.343L54.7547 110.33Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M68.4769 118.567C70.5238 62.0093 130.665 26.2063 180.37 48.6976C208.542 61.444 241.764 72.7099 279.51 80.88C373.129 101.15 452.972 89.5346 493.668 80.4223C497.101 79.6551 501.33 79.2648 504.831 78.8341L748.709 61.5517C804.999 57.8503 848.415 112.214 832.955 165.932C815.988 224.873 793.081 287.003 760.95 349.147C754.284 362.041 746.555 376.632 739.633 388.88C733.667 399.419 729.789 413.04 729.789 425.154L726.745 588.287C726.745 610.509 714.262 633.727 697.011 647.725C648.559 687.041 564.138 727.959 523.186 720.152C517.624 719.089 511.955 718.658 506.326 718.456L237.131 720.273C222.87 720.771 205.781 715.603 193.702 708.011C138.516 673.339 43.8198 589.404 49.0179 550.492C49.5027 546.831 50.0548 543.17 50.0279 539.455C49.6104 470.137 52.775 400.079 55.7781 330.465C56.8688 305.174 60.2893 272.938 60.828 247.633C61.6629 208.196 67.0495 157.964 68.4769 118.554V118.567Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M82.1993 126.818C84.4616 72.2788 142.246 37.7141 190.173 58.0518C217.443 69.6272 249.615 79.7624 286.028 87.2999C375.957 105.915 453.093 96.0757 493.035 87.8114C496.28 87.1384 500.495 86.8826 503.794 86.4654L738.852 73.544C793.229 70.9059 835.043 123.291 821.159 175.273C805.874 232.517 784.543 293.113 753.436 353.48C747.174 365.621 739.633 379.983 733.115 391.504C727.513 401.424 723.635 414.79 723.635 426.177L719.837 582.028C719.837 602.944 707.529 625.556 691.45 638.949C643.724 678.736 561.242 719.788 519.106 711.618C513.557 710.542 507.928 710.057 502.34 709.747L243.299 709.317C229.873 709.788 212.865 704.525 201.472 697.405C147.592 663.782 56.6129 583.4 60.9761 544.905C61.3801 541.366 61.8783 537.826 61.811 534.218C60.7741 467.148 64.2484 399.19 67.5612 331.729C68.7328 307.933 72.8535 275.454 73.3518 251.63C74.1463 214.508 80.6506 163.899 82.1858 126.818H82.1993Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M95.9216 135.055C98.3859 82.5353 153.841 49.2089 199.99 67.3796C226.371 77.7705 257.465 86.7751 292.573 93.68C378.798 110.639 453.201 102.59 492.415 95.1471C495.472 94.5683 499.687 94.4337 502.771 94.0569L728.994 85.4964C781.446 83.9216 821.684 134.329 809.376 184.574C795.761 240.122 776.019 299.184 745.935 357.761C740.091 369.161 732.711 383.294 726.611 394.089C721.359 403.39 717.494 416.486 717.494 427.173L712.929 575.755C712.929 595.366 700.795 617.4 685.901 630.146C638.876 670.432 558.347 711.592 515.052 703.045C509.531 701.954 503.942 701.402 498.381 700.999L249.507 698.32C236.929 698.764 219.975 693.394 209.269 686.772C156.682 654.212 69.433 577.398 72.9477 539.293C73.2709 535.861 73.7019 532.442 73.6211 528.956C71.9781 464.147 75.7488 398.261 79.3712 332.968C80.6102 310.665 85.4446 277.931 85.916 255.601C86.6566 220.808 94.2921 169.808 95.9216 135.042V135.055Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M109.631 143.293C112.297 92.7916 165.422 60.7034 209.781 76.7071C235.273 85.9136 265.303 93.801 299.09 100.073C381.613 115.391 453.255 109.118 491.769 102.496C494.624 101.998 498.839 101.998 501.721 101.648L719.11 97.4486C769.65 96.9372 808.298 145.379 797.552 193.874C785.621 247.741 767.441 305.254 738.407 362.041C732.967 372.688 725.776 386.592 720.079 396.673C715.178 405.355 711.326 418.182 711.326 428.155L706.007 569.456C706.007 587.748 694.076 609.243 680.34 621.33C633.988 662.127 555.452 703.394 510.972 694.457C505.478 693.353 499.916 692.734 494.395 692.236L255.688 687.31C243.945 687.714 227.058 682.276 217.053 676.125C165.759 644.629 82.2666 571.367 84.9195 533.667C85.1485 530.355 85.539 527.044 85.4178 523.679C83.1554 461.118 87.2358 397.346 91.168 334.193C92.4608 313.384 98.0089 280.407 98.4533 259.572C99.1401 227.093 107.92 175.717 109.631 143.279V143.293Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M123.353 151.53C126.221 103.035 177.03 72.1848 219.598 86.0484C244.201 94.0569 273.167 100.841 305.635 106.467C384.454 120.142 453.295 115.66 491.163 109.845C493.829 109.441 498.031 109.576 500.711 109.24L709.266 109.401C757.893 109.953 794.953 156.43 785.782 203.175C775.548 255.345 758.93 311.338 730.92 366.321C725.884 376.214 718.881 389.889 713.589 399.257C709.037 407.32 705.199 419.891 705.199 429.152L699.112 563.184C699.112 580.17 687.396 601.141 674.792 612.541C629.086 653.876 552.556 695.225 506.919 685.91C501.451 684.793 495.916 684.107 490.422 683.501L261.883 676.327C250.975 676.704 234.155 671.172 224.836 665.492C174.835 635.046 95.1002 565.378 96.8912 528.054C97.0394 524.864 97.376 521.661 97.2144 518.417C94.3326 458.117 98.7227 396.444 102.965 335.431C104.311 316.116 110.587 282.898 111.004 263.542C111.65 233.379 121.575 181.626 123.366 151.517L123.353 151.53Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M137.075 159.768C140.159 113.278 188.652 83.6661 229.401 95.3762C253.129 102.2 281.005 107.853 312.166 112.847C387.269 124.88 456.231 132.525 490.53 117.194C492.806 116.185 497.209 117.141 499.673 116.844L699.395 121.367C746.097 122.969 781.594 167.494 773.986 212.503C765.448 263.004 750.392 317.436 723.406 370.642C718.773 379.781 711.946 393.241 707.071 401.882C702.869 409.325 699.031 421.628 699.031 430.175L692.19 556.939C692.19 572.62 680.717 593.092 669.23 603.765C624.144 645.666 549.661 687.068 502.852 677.364C497.411 676.233 491.917 675.493 486.449 674.779L268.077 665.357C258.004 665.707 241.252 660.095 232.633 654.886C183.925 625.49 107.987 559.416 108.863 522.468C108.93 519.4 109.213 516.317 109.011 513.181C105.51 455.129 110.209 395.583 114.761 336.697C116.135 318.889 123.164 285.401 123.541 267.54C124.134 239.705 135.23 187.562 137.075 159.781V159.768Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M150.798 168.018C154.084 123.52 200.287 95.147 239.219 104.73C262.085 110.357 288.87 114.919 318.698 119.267C390.097 129.671 456.514 140.278 489.897 124.57C491.957 123.601 496.374 124.745 498.637 124.462L689.538 133.346C734.327 136.011 768.249 178.571 762.189 221.83C755.348 270.649 741.868 323.533 715.892 374.949C711.663 383.334 705.024 396.565 700.553 404.493C696.702 411.317 692.877 423.364 692.877 431.197L685.269 550.68C685.269 565.055 674.065 585.056 663.669 594.989C619.189 637.468 546.753 678.911 498.772 668.816C493.358 667.672 487.891 666.865 482.464 666.057L274.258 654.387C265.034 654.71 248.335 649.017 240.404 644.279C192.975 615.946 120.862 553.466 120.821 516.882C120.821 513.921 121.037 510.96 120.808 507.945C116.687 452.141 121.697 394.735 126.558 337.961C127.959 321.648 135.742 287.918 136.092 271.551C136.631 246.031 148.926 193.511 150.798 168.059V168.018Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M164.52 176.256C168.008 133.75 211.922 106.601 249.022 114.058C271.027 118.486 296.707 121.945 325.229 125.647C392.912 134.436 453.134 128.662 489.278 131.906C491.271 131.34 495.553 132.296 497.613 132.04L679.68 145.271C722.544 149 754.917 189.608 750.406 231.104C745.275 278.254 733.344 329.576 708.404 379.202C704.566 386.834 698.129 399.836 694.049 407.037C690.548 413.242 686.737 425.019 686.737 432.139L678.374 544.354C678.374 557.423 667.453 576.98 658.12 586.133C614.206 629.231 543.857 670.687 494.718 660.202C489.332 659.058 483.891 658.17 478.505 657.268L280.467 643.35C272.077 643.646 255.432 637.872 248.214 633.605C202.078 606.322 133.79 547.436 132.807 511.229C132.726 508.389 132.874 505.536 132.618 502.642C127.878 449.099 133.197 393.819 138.368 339.159C139.769 324.34 148.333 290.368 148.643 275.481C149.141 252.29 162.635 199.379 164.534 176.256H164.52Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M178.242 184.493C181.946 143.966 223.584 118.029 258.839 123.399C279.982 126.616 304.571 128.998 331.774 132.054C395.739 139.228 453.228 134.598 488.658 139.268C490.449 138.757 494.745 139.887 496.59 139.659L669.823 157.25C710.774 162.042 741.586 200.699 738.609 240.432C735.202 285.913 724.806 335.673 700.89 383.509C697.456 390.387 691.194 403.174 687.531 409.648C684.38 415.234 680.582 426.742 680.582 433.162L671.452 538.095C671.452 549.859 660.841 568.999 652.559 577.357C609.197 621.074 540.962 662.544 490.638 651.655C485.278 650.498 479.878 649.542 474.518 648.532L286.634 632.367C279.079 632.636 262.489 626.795 255.971 622.972C211.114 596.739 146.704 541.473 144.751 505.616C144.603 502.898 144.684 500.152 144.388 497.379C139.041 446.084 144.643 392.972 150.138 340.398C151.525 327.072 160.884 292.844 161.167 279.452C161.611 258.576 176.33 205.275 178.229 184.48L178.242 184.493Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M191.965 192.731C195.87 154.169 235.246 129.456 268.643 132.727C288.937 134.719 312.409 136.038 338.305 138.434C398.541 144.02 453.322 140.493 488.025 146.618C489.614 146.16 493.91 147.452 495.553 147.25L659.952 169.203C698.978 175.058 728.267 211.763 726.813 249.733C725.143 293.545 716.269 341.744 693.376 387.803C690.332 393.914 684.272 406.486 681.013 412.246C678.212 417.213 674.428 428.465 674.428 434.159L664.544 531.823C664.544 542.281 654.269 561.031 647.011 568.555C604.174 612.932 538.067 654.374 486.584 643.082C481.252 641.911 475.879 640.888 470.546 639.784L292.829 621.358C286.122 621.586 269.572 615.691 263.755 612.326C220.177 587.143 159.686 535.484 156.71 499.991C156.494 497.393 156.508 494.768 156.171 492.117C150.205 443.069 156.117 392.137 161.921 341.636C163.281 329.805 173.435 295.335 173.691 283.423C174.081 264.862 190.053 211.184 191.924 192.717L191.965 192.731Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M205.687 200.968C209.808 164.358 246.935 140.843 278.46 142.068C297.919 142.822 320.26 143.091 344.849 144.827C401.368 148.825 453.416 146.416 487.405 153.967C488.806 153.576 493.088 155.03 494.529 154.841L650.107 181.155C687.221 188.074 714.975 222.84 715.043 259.034C715.11 301.19 707.757 347.814 685.888 392.083C683.235 397.44 677.377 409.783 674.522 414.831C672.071 419.178 668.287 430.161 668.287 435.155L657.635 525.551C657.635 534.703 648.842 554.368 641.448 559.765C608.227 611.895 535.158 646.231 482.517 634.534C477.211 633.35 471.865 632.273 466.573 631.062L299.023 610.388C293.152 610.59 276.655 604.627 271.551 601.72C229.24 577.573 172.707 529.535 168.694 494.405C168.412 491.928 168.358 489.411 167.994 486.881C161.409 440.095 167.631 391.343 173.744 342.901C175.051 332.564 186.039 297.852 186.268 287.434C186.618 271.201 203.869 217.133 205.687 200.995V200.968Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M219.409 209.205C223.745 174.533 258.731 154.235 288.263 151.395C306.807 149.619 328.097 150.144 351.367 151.22C404.156 153.657 453.497 152.338 486.773 161.315C487.971 160.979 492.267 162.594 493.492 162.446L640.237 193.121C675.425 201.102 701.671 233.917 703.233 268.361C705.064 308.861 699.206 353.911 678.374 396.39C676.111 400.994 670.455 413.121 668.005 417.441C665.904 421.156 662.133 431.884 662.133 436.164L650.727 519.278C650.727 527.125 642.243 546.346 635.9 550.962C603.998 604.129 532.263 638.074 478.45 625.96C473.172 624.776 467.866 623.618 462.6 622.313L305.218 599.391C300.181 599.566 283.725 593.549 279.335 591.073C238.289 567.976 185.757 523.531 180.653 488.778C180.303 486.423 180.168 484.013 179.777 481.604C172.573 437.066 179.091 390.535 185.528 344.126C186.753 335.283 198.604 300.314 198.792 291.391C199.088 277.473 217.659 223.028 219.382 209.219L219.409 209.205Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M233.118 217.456C237.656 184.709 270.286 163.523 298.067 160.737C315.843 158.96 335.935 157.224 357.899 157.614C406.957 158.502 453.578 158.26 486.14 168.665C487.136 168.382 491.432 170.172 492.456 170.037L630.366 205.073C663.642 214.118 688.393 244.995 691.436 277.662C695.059 316.493 690.669 359.982 670.86 400.671C668.988 404.507 663.52 416.419 661.487 420.026C659.736 423.122 655.979 433.593 655.979 437.147L643.805 512.993C643.805 519.534 635.618 538.31 630.339 542.16C599.743 596.362 529.354 629.918 474.384 617.387C469.132 616.189 463.853 614.964 458.628 613.551L311.412 588.381C307.224 588.529 290.795 582.459 287.132 580.426C247.352 558.366 198.873 517.529 192.638 483.152C192.234 480.918 192.019 478.616 191.588 476.328C183.764 434.038 190.578 389.741 197.338 345.351C198.455 337.989 211.195 302.791 211.356 295.348C211.599 283.746 231.529 228.937 233.118 217.443V217.456Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M246.841 225.694C251.608 194.844 281.961 174.802 307.871 170.078C324.798 166.982 343.759 164.304 364.43 164.021C409.758 163.388 453.672 164.196 485.507 176.027C486.302 175.798 490.597 177.75 491.419 177.642L620.495 217.039C651.845 227.147 675.115 256.072 679.626 286.976C685.053 324.138 682.118 366.066 663.332 404.978C661.851 408.06 656.585 419.743 654.956 422.624C653.555 425.1 649.812 435.303 649.812 438.156L636.884 506.72C636.884 511.943 628.992 530.275 624.764 533.357C595.488 588.596 526.418 621.774 470.29 608.826C465.065 607.615 459.813 606.323 454.628 604.815L317.58 577.398C314.227 577.519 297.838 571.408 294.889 569.806C256.375 548.782 211.989 511.525 204.583 477.54C204.125 475.413 203.829 473.246 203.371 471.065C194.927 431.036 202.038 389.001 209.121 346.589C210.104 340.721 223.759 305.281 223.88 299.319C224.082 290.045 245.413 234.846 246.827 225.68L246.841 225.694Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M260.563 233.931C265.545 204.952 293.61 186.041 317.688 179.405C333.767 174.977 351.61 171.397 370.974 170.401C412.572 168.274 453.766 170.105 484.901 183.376C485.493 183.201 489.803 185.328 490.422 185.247L610.664 229.005C640.102 240.176 661.918 267.136 667.87 296.29C675.115 331.784 673.634 372.136 655.858 409.271C654.754 411.587 649.704 423.068 648.478 425.221C647.428 427.079 643.698 437.025 643.698 439.152L630.002 500.448C630.002 504.365 622.407 522.253 619.242 524.568C591.273 580.843 523.55 613.645 466.263 600.266C461.052 599.054 455.854 597.695 450.683 596.08L323.801 566.414C321.283 566.509 304.935 560.371 302.713 559.173C265.451 539.185 225.24 505.482 216.581 471.927C216.056 469.921 215.692 467.862 215.194 465.803C206.145 428.021 213.524 388.274 220.944 347.828C221.752 343.44 236.35 307.758 236.458 303.303C236.606 296.344 259.391 240.782 260.576 233.931H260.563Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M274.285 242.168C279.497 215.047 305.218 197.24 327.491 188.747C342.682 182.945 359.461 178.504 377.506 176.794C415.387 173.214 453.847 176.014 484.268 190.725C484.672 190.618 488.968 192.892 489.385 192.838L600.807 240.944C628.332 253.179 648.694 278.173 656.073 305.578C665.177 339.375 665.096 378.193 648.357 413.538C647.63 415.086 642.782 426.352 641.974 427.792C641.274 429.031 637.557 438.708 637.557 440.135L623.107 494.162C623.107 496.774 615.809 514.218 613.708 515.752C587.058 573.064 520.641 605.569 462.223 591.679C457.039 590.467 451.868 589.027 446.737 587.318L330.023 555.404C328.34 555.458 312.005 549.307 310.524 548.527C274.514 529.575 238.545 499.398 228.58 466.301C228.014 464.403 227.557 462.465 227.018 460.526C217.349 425.006 218.386 385.623 232.768 349.053C232.835 346.078 248.955 310.235 249.022 307.26C249.116 302.616 273.437 246.704 274.312 242.142L274.285 242.168Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M288.007 250.406C298.188 226.596 316.785 208.411 337.308 198.074C351.583 190.887 367.311 185.637 384.037 183.188C418.215 178.181 453.941 181.936 483.635 198.074C483.837 198.02 488.133 200.457 488.348 200.443L590.936 252.909C616.536 266.208 635.483 289.21 644.277 314.892C655.252 346.98 656.558 384.263 640.843 417.832C640.479 418.599 635.86 429.663 635.456 430.39C635.106 431.009 631.389 440.418 631.389 441.131L616.185 487.877C616.185 489.182 609.196 506.182 608.132 506.949C582.789 565.297 517.719 597.399 458.129 583.118C452.972 581.893 447.828 580.386 442.737 578.569L336.177 544.408C335.342 544.435 319.021 538.27 318.28 537.893C283.51 519.965 251.863 493.274 240.511 460.688C239.892 458.898 239.34 457.094 238.774 455.264C228.486 421.991 229.469 384.667 244.524 350.291C244.565 348.797 261.492 312.725 261.519 311.231C261.573 308.916 287.94 252.708 287.994 250.379L288.007 250.406Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M256.335 351.556L301.73 258.643C334.75 191.075 418.686 166.43 483.016 205.423L581.079 264.862C634.729 297.381 656.855 363.993 633.342 422.126L602.585 498.16C576.527 562.565 503.795 594.397 438.765 569.833L326.064 527.274C255.58 500.65 223.261 419.218 256.335 351.556Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_14_574">
                        <rect width="886" height="765" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </div>
        <div v-if="index == 2">
            <svg width="930" height="1704" viewBox="0 0 930 1704" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_14_650)">
                    <path
                        d="M929.707 1503.76C700.101 1455.27 693.768 1319.9 728.668 1256.33C748.213 1220.73 811.855 1206.69 809.828 1166.1C789.173 754.843 924.407 953.78 928.928 833.567C934.111 695.059 731.981 469.38 693.904 335.982C660.271 218.13 803.709 194.431 796.363 71.2534"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M923.375 1507.66C697.12 1459.52 681.278 1325.81 717.698 1256.84C736.658 1220.95 802.56 1202.67 800.904 1162.31C784.379 757.301 919.263 948.065 918.718 828.574C918.094 692.211 723.115 465.479 685.058 332.061C651.425 214.209 788.121 192.987 780.755 69.8101"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M917.041 1511.58C694.158 1463.77 668.826 1331.76 706.727 1257.38C725.141 1221.26 793.246 1198.69 791.998 1158.57C779.547 759.817 914.158 942.662 908.526 823.62C902.193 689.597 714.287 461.617 676.211 328.199C642.578 210.347 772.512 191.622 765.146 68.4448"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M910.709 1515.48C691.196 1468.02 656.374 1337.75 695.775 1257.89C713.703 1221.55 783.951 1194.69 783.093 1154.78C774.675 762.333 909.091 937.532 898.315 818.626C886.409 687.101 705.421 457.716 667.364 324.298C633.731 206.446 756.923 190.218 749.557 67.0405"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M904.376 1519.38C688.234 1472.26 643.942 1343.75 684.805 1258.42C702.284 1221.9 774.636 1190.69 774.188 1151.02C769.726 764.888 904.103 932.675 888.124 813.652C870.82 684.818 696.594 453.834 658.518 320.417C624.885 202.564 741.315 188.832 733.949 65.6553"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M898.062 1523.3C685.292 1476.51 631.568 1349.8 673.853 1258.94C690.923 1222.25 765.341 1186.73 765.283 1147.25C764.698 767.483 899.231 928.091 877.933 808.678C855.465 682.692 687.766 449.933 649.69 316.535C616.057 198.683 725.726 187.448 718.379 64.2705"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M891.729 1527.2C682.33 1480.76 619.195 1355.87 662.883 1259.47C679.583 1222.64 756.027 1182.75 756.378 1143.49C759.632 770.096 894.457 923.741 867.742 803.704C841.026 683.668 678.92 446.052 640.863 312.654C607.23 194.801 710.156 186.063 702.791 62.8857"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M885.396 1531.12C679.368 1485.01 606.84 1361.97 651.912 1260C668.241 1223.03 746.712 1178.8 747.472 1139.73C754.488 772.749 889.839 919.626 857.55 798.731C825.515 678.772 670.093 442.17 632.017 308.753C598.383 190.9 694.548 184.639 687.202 61.4619"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M879.063 1535.02C676.386 1489.26 594.505 1368.08 640.941 1260.5C656.939 1223.42 737.398 1174.8 738.548 1135.94C749.246 775.422 885.357 915.666 847.339 793.737C810.919 676.919 661.226 438.269 623.17 304.852C589.536 186.999 678.959 183.235 671.593 60.0576"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M872.73 1538.94C673.425 1493.52 582.21 1374.22 629.971 1261.05C645.657 1223.85 728.064 1170.88 729.643 1132.2C743.965 778.132 902.252 902.09 837.148 788.783C817.506 669.741 652.399 434.407 614.323 300.989C580.69 183.137 663.351 181.869 655.985 58.6919"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M866.397 1542.85C670.463 1497.77 569.953 1380.35 619 1261.56C634.394 1224.26 718.749 1166.9 720.718 1128.41C738.567 780.844 899.777 896.336 826.937 783.77C804.528 666.893 643.533 430.486 605.476 297.069C571.843 179.216 647.761 180.445 640.395 57.2681"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M860.064 1546.77C667.501 1502.02 557.715 1386.53 608.029 1262.1C623.15 1224.73 709.416 1162.98 711.813 1124.67C733.13 783.633 897.321 890.621 816.746 778.816C791.57 664.084 634.705 426.605 596.629 293.207C562.996 175.355 632.153 179.08 624.787 55.9028"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M853.731 1550.67C664.539 1506.27 545.498 1392.69 597.058 1262.61C611.907 1225.14 700.082 1159 702.888 1120.88C727.577 786.384 894.847 884.867 806.535 773.822C778.612 661.256 625.84 422.704 587.783 289.306C554.15 171.453 616.545 177.676 609.198 54.4985"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M847.398 1554.57C661.577 1510.51 533.318 1398.88 586.107 1263.14C600.702 1225.59 690.767 1155.08 694.002 1117.12C721.984 789.192 892.411 879.112 796.363 768.848C765.672 658.447 617.031 418.822 578.955 285.404C545.322 167.552 600.975 176.251 593.609 53.0742"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M841.065 1558.49C658.615 1514.78 521.179 1405.1 575.136 1263.66C589.498 1226.04 681.414 1151.14 685.097 1113.35C716.295 792.02 889.936 873.378 786.172 763.874C752.734 655.638 608.185 414.94 570.128 281.523C536.495 163.671 585.386 174.867 578.02 51.6895"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M834.732 1562.39C655.653 1519.01 509.058 1411.32 564.165 1264.19C578.273 1226.49 672.08 1147.22 676.172 1109.59C710.487 794.868 887.461 867.643 775.961 758.9C739.756 652.829 599.338 411.059 561.262 277.641C527.629 159.789 569.777 173.482 562.412 50.3047"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M828.399 1566.31C652.672 1523.26 496.977 1417.56 553.195 1264.72C567.088 1226.95 662.727 1143.28 667.267 1105.83C704.603 797.736 885.006 861.909 765.77 753.926C726.798 650.021 590.491 407.158 552.435 273.76C518.801 155.908 554.188 172.097 546.823 48.9199"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M822.066 1570.21C649.71 1527.51 484.934 1423.78 542.224 1265.24C555.903 1227.4 653.354 1139.35 658.342 1102.06C698.62 800.622 882.532 856.154 755.559 748.952C713.839 647.192 581.645 403.276 543.569 269.878C509.935 152.026 538.58 170.712 531.214 47.5347"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M815.752 1574.13C646.767 1531.77 472.931 1430.05 531.272 1265.77C544.737 1227.87 644.02 1135.45 649.456 1098.3C692.579 803.509 880.095 850.42 745.387 743.978C700.9 644.383 572.817 399.394 534.76 265.977C501.127 148.125 523.01 169.308 515.644 46.1304"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M809.419 1578.03C643.806 1536.02 460.947 1436.31 520.302 1266.3C533.553 1228.34 634.647 1131.53 640.532 1094.53C686.403 806.435 877.621 844.685 735.177 739.005C687.922 641.575 563.971 395.513 525.914 262.095C492.281 144.243 507.402 167.903 500.056 44.7261"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M803.086 1581.95C640.844 1540.27 448.982 1442.59 509.331 1266.82C522.387 1228.81 625.274 1127.63 631.627 1090.77C680.147 809.342 875.166 838.931 724.966 734.011C674.964 638.747 555.124 391.612 517.048 258.194C483.414 140.342 491.794 166.499 484.428 43.3218"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M796.753 1585.86C637.882 1544.52 437.057 1448.87 498.361 1267.35C511.221 1229.28 615.882 1123.73 622.722 1087C673.795 812.268 872.691 833.197 714.775 729.038C662.006 635.938 546.277 387.73 508.221 254.313C474.587 136.461 476.205 165.114 468.839 41.937"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M790.42 1589.76C634.92 1548.76 425.17 1455.17 487.39 1267.86C500.056 1229.72 606.49 1119.81 613.797 1083.22C667.326 815.193 870.216 827.442 704.564 724.044C649.048 633.109 537.431 383.809 499.354 250.411C465.721 132.559 460.596 163.709 453.23 40.5322"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M784.087 1593.68C631.938 1553.03 413.303 1461.47 476.438 1268.4C488.929 1230.21 597.116 1115.93 604.911 1079.47C660.778 818.138 867.78 821.728 694.392 719.09C636.108 630.32 528.603 379.948 490.546 246.549C456.913 128.697 445.026 162.344 437.661 39.167"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M777.754 1597.58C628.977 1557.26 401.475 1467.77 465.468 1268.91C477.763 1230.66 587.705 1112.01 595.986 1075.69C654.094 821.064 865.306 815.954 684.181 714.096C623.131 627.472 519.756 376.046 481.68 242.629C448.047 124.777 429.399 160.92 422.052 37.7236"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M771.421 1601.5C626.015 1561.53 389.686 1474.11 454.497 1269.44C466.617 1231.13 578.274 1108.11 587.081 1071.92C647.333 824.01 862.85 810.219 673.99 709.122C610.192 624.664 510.91 372.165 472.853 238.747C439.22 120.895 413.829 159.516 406.463 36.3389"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M765.088 1605.4C623.053 1565.76 377.936 1480.43 443.526 1269.96C455.491 1231.6 568.862 1104.23 578.157 1068.16C640.454 826.935 860.376 804.484 663.779 704.148C597.214 621.854 502.063 368.283 463.987 234.865C430.354 117.013 398.201 158.131 390.855 34.9536"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M758.755 1609.32C620.091 1570.02 366.205 1486.79 432.556 1270.49C444.345 1232.08 559.411 1100.32 569.252 1064.4C633.478 829.842 857.921 798.75 653.588 699.174C584.256 619.046 493.216 364.402 455.16 230.984C421.526 113.132 382.632 156.746 375.266 33.5688"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M752.422 1613.22C617.129 1574.27 354.513 1493.11 421.584 1271.02C433.198 1232.55 549.96 1096.42 560.346 1060.63C626.404 832.748 855.465 792.996 643.396 694.201C571.317 616.237 484.389 360.501 446.312 227.103C412.679 109.25 367.023 155.361 359.657 32.1841"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M746.089 1617.14C614.167 1578.52 342.841 1499.49 410.614 1271.54C422.072 1233.02 540.49 1092.52 551.421 1056.87C619.214 835.655 852.971 787.261 633.186 689.227C558.339 613.428 475.522 356.619 437.466 223.202C403.833 105.349 351.434 153.957 344.068 30.7798"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M739.775 1621.04C611.205 1582.77 331.247 1505.82 399.663 1272.07C410.945 1233.49 531.039 1088.64 542.536 1053.1C611.946 838.522 850.535 781.527 623.014 684.233C545.4 610.581 466.715 352.718 428.639 219.3C395.005 101.448 335.845 152.533 328.479 29.356"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M733.443 1624.94C608.243 1587.01 319.652 1512.18 388.692 1272.58C399.819 1233.96 521.549 1084.72 533.611 1049.32C604.541 841.35 828.516 813.945 612.803 679.24C521.588 622.284 457.849 348.817 419.792 215.399C386.159 97.5468 320.237 151.128 312.891 27.9512"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M727.109 1628.86C605.281 1591.28 308.077 1518.56 377.74 1273.12C388.711 1234.44 512.059 1080.82 524.725 1045.57C597.097 844.198 827.307 807.527 602.631 674.285C509.545 619.085 449.041 344.955 410.964 211.537C377.331 93.6848 304.667 149.763 297.301 26.5859"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M720.776 1632.77C602.319 1595.51 296.561 1524.94 366.77 1273.63C377.585 1234.89 502.53 1076.9 515.801 1041.79C589.497 846.968 826.06 800.876 592.42 669.292C497.444 615.808 440.194 341.034 402.118 207.636C368.485 89.7838 289.059 148.359 281.712 25.1816"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M714.443 1636.69C599.358 1599.78 285.064 1531.34 355.799 1274.18C366.478 1235.38 492.982 1073 506.895 1038.04C581.82 849.738 824.833 794.108 582.229 664.338C485.324 612.512 431.347 337.172 393.291 203.774C359.657 85.9219 273.489 146.994 266.124 23.8164"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M708.11 1640.59C596.396 1604.01 273.587 1537.72 344.829 1274.68C355.351 1235.85 483.415 1069.06 497.99 1034.26C574.045 852.429 823.605 787.125 572.038 659.344C473.165 609.117 422.52 333.271 384.444 199.853C350.811 82.0009 257.881 145.569 250.515 22.3921"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M701.777 1644.51C593.434 1608.29 262.168 1544.13 333.857 1275.23C344.224 1236.34 473.846 1065.14 489.065 1030.51C566.172 855.101 822.319 780.025 561.827 654.39C460.947 605.743 413.654 329.409 375.597 195.991C341.964 78.1389 242.292 144.185 234.926 21.0073"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M695.444 1648.41C590.472 1612.52 250.768 1550.51 322.887 1275.74C333.117 1236.78 464.24 1061.18 480.16 1026.73C558.222 857.696 821.052 772.73 551.636 649.396C448.709 602.271 404.826 325.508 366.75 192.09C333.117 74.2379 226.683 142.78 219.317 19.603"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M689.111 1652.33C587.49 1616.77 239.408 1556.93 311.916 1276.28C321.99 1237.27 454.614 1057.24 471.235 1022.99C550.174 860.271 819.727 765.337 541.405 644.442C436.394 598.819 395.941 321.646 357.884 188.228C324.251 70.376 211.055 141.415 203.709 18.2378"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M682.778 1656.23C584.529 1621.02 228.067 1563.35 300.945 1276.79C310.883 1237.72 444.968 1053.24 462.33 1019.2C542.068 862.748 818.383 757.808 531.214 639.429C424.079 595.269 387.113 317.706 349.037 184.308C315.404 66.4554 195.466 139.991 188.101 16.814"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M676.445 1660.13C581.567 1625.26 216.765 1569.74 289.975 1277.32C299.776 1238.21 435.303 1049.24 453.406 1015.44C533.884 865.166 816.999 750.162 521.003 634.455C411.705 591.738 378.247 313.824 340.191 180.426C306.557 62.5735 179.858 138.606 172.492 15.4287"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M670.112 1664.05C578.605 1629.53 205.502 1576.18 279.004 1277.84C288.65 1238.68 425.599 1045.22 444.501 1011.67C525.641 867.526 815.577 742.399 510.812 629.481C399.312 588.169 369.42 309.942 331.344 176.525C297.711 58.6725 164.269 137.202 156.903 14.0244"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M663.798 1667.95C575.662 1633.76 194.278 1582.6 268.072 1278.35C277.581 1239.13 415.914 1041.16 435.634 1007.87C517.379 869.75 814.154 734.518 500.66 624.468C386.919 584.541 360.612 306.022 322.556 172.604C288.922 54.7519 148.719 135.758 141.353 12.5811"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M657.465 1671.87C572.7 1638.03 183.093 1589.03 257.101 1278.9C266.474 1239.61 406.171 1037.13 426.709 1004.12C509.019 871.935 812.634 726.619 490.449 619.514C374.408 580.952 351.765 302.16 313.689 168.742C280.056 50.89 133.11 134.393 125.745 11.2158"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M651.133 1675.78C569.739 1642.26 171.908 1595.47 246.131 1279.4C255.367 1240.06 396.389 1033.01 417.804 1000.34C500.621 874.002 811.076 718.582 480.258 614.52C361.898 577.284 342.919 298.239 304.862 164.841C271.209 47.008 117.502 133.008 110.136 9.83057"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M644.8 1679.7C566.757 1646.54 160.781 1601.91 235.16 1279.95C244.26 1240.55 386.568 1028.9 408.88 996.594C492.183 876.011 809.458 710.507 470.047 609.566C349.349 573.657 334.072 294.377 295.996 160.979C262.382 43.107 101.913 131.603 94.5474 8.42627"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M638.466 1683.6C563.795 1650.77 149.674 1608.35 224.189 1280.46C233.153 1241 376.727 1024.7 399.974 992.81C483.746 877.845 807.821 702.354 459.855 604.573C336.761 569.97 325.225 290.476 287.168 157.058C253.535 39.2256 86.3043 130.219 78.958 7.0415"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M632.133 1687.52C560.833 1655.04 138.605 1614.8 213.218 1281C222.045 1241.49 366.867 1020.51 391.05 989.065C475.289 879.62 806.087 694.181 449.645 599.619C324.114 566.323 316.378 286.614 278.322 153.196C244.689 35.3441 70.7154 128.814 63.3496 5.63721"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M625.8 1691.42C557.871 1659.27 127.557 1621.26 202.248 1281.51C210.958 1241.93 356.968 1016.22 382.145 985.281C466.851 881.22 804.353 685.911 439.454 594.625C311.468 562.597 307.551 282.713 269.475 149.295C235.842 31.4431 55.1265 127.43 47.7607 4.25244"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M619.467 1695.32C554.91 1663.51 116.547 1627.69 191.277 1282.04C199.851 1242.4 347.03 1011.91 373.239 981.516C458.433 882.702 802.56 677.621 429.262 589.631C298.783 558.871 298.705 278.811 260.648 145.394C227.015 27.5611 39.5182 126.025 32.1719 2.84766"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M613.135 1699.24C551.948 1667.78 105.577 1634.17 180.306 1282.56C188.744 1242.85 337.073 1007.54 364.315 977.752C450.035 884.047 800.69 669.292 419.052 584.658C286.039 555.165 289.858 274.91 251.782 141.512C218.168 23.6602 23.9293 124.64 16.5635 1.46289"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M606.801 1703.14C548.985 1672.01 94.625 1640.61 169.335 1283.09C177.636 1243.32 327.096 1003.11 355.409 973.988C441.714 885.238 798.799 660.924 408.86 579.684C273.294 551.44 281.011 271.029 242.954 137.631C209.321 19.7787 8.33992 123.236 0.974121 0.0585938"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_14_650">
                        <rect width="930" height="1704" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </div>
        <div v-if="index == 3">
            <svg width="797" height="1251" viewBox="0 0 797 1251" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_16_909)">
                    <path
                        d="M780.605 1250.95L795.589 1048.67C798.36 1011.26 795.567 973.545 787.351 937.341L739.347 725.857C727.789 674.924 727.023 621.235 737.126 569.839L747.378 517.709"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M767.294 1246.5L768.549 1222.8C768.252 1221.77 773.222 1167.51 772.39 1167.08C771.669 1166.71 774.336 1109.58 775.755 1106.54C776.059 1105.88 779.491 1059.67 779.774 1058.91C779.982 1058.36 781.66 1038.22 781.883 1038.08C785.776 1000.68 784.372 968.197 777.538 934.015C778.095 934.613 769.381 893.778 769.871 892.246C772.508 884.06 731.161 717.428 729.572 717.7C728.138 713.421 726.964 709.143 726.154 704.701C725.783 702.653 725.478 700.223 725.152 698.166C722.67 682.448 721.772 666.522 721.526 650.777C721.467 647.069 721.348 643.298 721.274 639.274C721.17 633.545 721.415 627.744 721.809 621.915C723.265 600.324 726.437 579.131 731.51 558.736C731.845 558.69 735.812 540.199 736.153 540.235L744.34 507.784"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M753.973 1242.04L754.798 1217.42C754.196 1215.35 760.176 1160.4 758.52 1159.54C757.079 1158.8 757.963 1104.5 760.808 1098.42C761.424 1097.1 764.886 1050.42 765.458 1048.91C765.874 1047.81 767.739 1027.75 768.177 1027.48C773.199 990.088 773.169 962.84 767.724 930.67C768.838 931.866 760.845 891.774 761.833 888.719C767.115 872.34 722.974 708.998 719.795 709.523C717.93 705.408 716.511 701.302 715.739 696.915C715.39 694.902 715.16 692.138 714.862 690.107C712.485 673.782 712.337 657.257 712.559 641.123C712.611 637.397 712.5 633.527 712.411 629.157C712.3 623.311 712.768 617.31 713.406 611.246C715.739 589.138 719.802 567.736 725.886 547.595C726.547 547.504 730.782 529.376 731.458 529.448L741.286 497.831"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M740.662 1237.57L741.056 1212.03C740.157 1208.93 747.132 1153.28 744.651 1152.01C742.489 1150.89 741.598 1099.43 745.862 1090.31C746.783 1088.34 750.282 1041.19 751.144 1038.93C751.768 1037.28 753.818 1017.3 754.472 1016.9C760.615 979.518 761.974 957.501 757.911 927.352C759.59 929.138 752.317 889.807 753.796 885.22C761.714 860.656 714.788 700.586 710.027 701.374C707.731 697.422 706.067 693.497 705.332 689.146C704.998 687.17 704.849 684.07 704.582 682.076C702.301 665.144 702.903 648.039 703.608 631.496C703.772 627.753 703.66 623.782 703.571 619.078C703.46 613.104 704.143 606.913 705.035 600.613C708.236 577.988 713.199 556.379 720.3 536.482C721.296 536.337 725.797 518.58 726.808 518.68L738.277 487.896"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M727.35 1233.11L727.321 1206.65C726.125 1202.51 734.103 1146.18 730.797 1144.47C727.915 1142.98 725.248 1094.36 730.931 1082.19C732.157 1079.56 735.7 1031.95 736.844 1028.92C737.676 1026.73 739.912 1006.83 740.789 1006.29C748.061 968.913 750.795 952.126 748.121 923.999C750.357 926.382 743.805 887.795 745.773 881.685C756.329 848.926 706.61 692.138 700.266 693.189C697.54 689.391 695.631 685.657 694.94 681.351C694.628 679.411 694.554 675.967 694.316 674.018C692.139 656.46 693.469 638.802 694.665 621.852C694.932 618.09 694.836 614.029 694.732 608.971C694.613 602.871 695.512 596.489 696.656 589.963C700.712 566.821 706.595 545.003 714.7 525.36C716.022 525.17 720.791 507.766 722.143 507.911L735.262 477.962"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M714.031 1228.65L713.57 1201.26C712.07 1196.1 721.058 1139.06 716.921 1136.93C713.318 1135.07 708.875 1089.29 715.977 1074.08C717.515 1070.79 721.088 1022.7 722.514 1018.93C723.554 1016.18 725.976 996.369 727.068 995.698C735.462 958.334 739.585 946.777 738.292 920.662C741.086 923.645 735.262 885.809 737.721 878.168C750.914 837.224 698.409 683.707 690.475 685.031C687.325 681.396 685.171 677.843 684.51 673.573C684.22 671.67 684.228 667.89 684.005 665.977C681.925 647.794 683.99 629.583 685.676 612.216C686.048 608.436 685.966 604.275 685.847 598.873C685.728 592.655 686.828 586.083 688.232 579.312C693.134 555.662 699.939 533.627 709.054 514.229C710.71 513.993 715.74 496.952 717.426 497.124L732.186 468.009"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M700.719 1224.18L699.828 1195.87C698.03 1189.67 708.021 1131.95 703.052 1129.38C698.728 1127.14 692.503 1084.2 701.031 1065.95C702.873 1062.01 706.491 1013.45 708.2 1008.91C709.44 1005.62 712.048 985.891 713.362 985.084C722.886 947.729 728.383 941.411 728.48 917.309C731.83 920.889 726.726 883.798 729.683 874.633C745.521 825.495 690.223 675.269 680.707 676.846C677.126 673.374 674.727 670.002 674.103 665.778C673.828 663.92 673.924 659.796 673.724 657.919C671.74 639.102 674.526 620.356 676.725 602.572C677.193 598.774 677.126 594.513 677 588.775C676.874 582.43 678.188 575.668 679.845 568.661C685.58 544.495 693.32 522.251 703.445 503.107C705.436 502.826 710.725 486.147 712.753 486.356L729.156 458.065"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M687.399 1219.72L686.077 1190.49C683.982 1183.26 694.969 1124.84 689.182 1121.85C684.138 1119.24 676.137 1079.13 686.084 1057.84C688.231 1053.24 691.886 1004.22 693.884 998.935C695.333 995.091 698.126 975.439 699.664 974.497C710.309 937.151 717.195 936.063 718.673 913.982C722.581 918.161 718.205 881.812 721.645 871.125C740.119 813.801 682.036 666.848 670.93 668.688C666.919 665.379 664.274 662.189 663.687 658.001C663.435 656.179 663.613 651.71 663.435 649.879C661.548 630.418 665.047 611.156 667.758 592.936C668.323 589.12 668.278 584.76 668.137 578.678C668.003 572.215 669.519 565.262 671.45 558.02C678.009 533.346 686.693 510.893 697.836 491.985C700.154 491.649 705.71 475.342 708.08 475.578L726.124 448.122"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M674.087 1215.26L672.334 1185.11C669.934 1176.84 681.932 1117.73 675.313 1114.32C669.541 1111.33 659.772 1074.06 671.138 1049.73C673.597 1044.47 677.289 994.983 679.569 988.937C681.226 984.549 684.205 964.979 685.958 963.9C697.732 926.564 705.993 930.706 708.86 910.647C713.332 915.414 709.67 879.827 713.607 867.608C734.719 802.09 673.849 658.418 661.154 660.53C656.719 657.385 653.822 654.384 653.272 650.233C653.035 648.447 653.302 643.643 653.146 641.848C651.356 621.743 655.568 601.964 658.799 583.31C659.46 579.475 659.431 575.016 659.289 568.598C659.156 562.008 660.872 554.865 663.063 547.387C670.432 522.197 680.074 499.535 692.228 480.881C694.88 480.5 700.704 464.547 703.408 464.828L723.093 438.205"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M660.775 1210.79L658.591 1179.72C655.895 1170.42 668.895 1110.62 661.444 1106.77C654.951 1103.41 643.407 1068.98 656.192 1041.6C658.955 1035.68 662.684 985.728 665.255 978.929C667.119 973.989 670.284 954.501 672.252 953.295C685.148 915.967 694.791 925.349 699.047 907.302C704.076 912.668 701.135 877.824 705.569 864.082C729.318 790.379 665.663 649.979 651.386 652.354C646.52 649.362 643.385 646.552 642.865 642.437C642.65 640.697 643.006 635.539 642.865 633.79C641.164 613.032 646.082 592.746 649.841 573.665C650.598 569.812 650.591 565.262 650.435 558.491C650.294 551.774 652.21 544.45 654.669 536.727C662.848 511.029 673.448 488.15 686.612 469.74C689.598 469.314 695.682 453.723 698.72 454.032L720.055 428.243"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M647.456 1206.33L644.848 1174.33C641.855 1164 655.858 1103.5 647.582 1099.23C640.369 1095.51 627.042 1063.9 641.253 1033.5C644.321 1026.93 648.087 976.5 650.947 968.94C653.02 963.456 656.363 944.049 658.554 942.698C672.579 905.38 683.603 919.992 689.241 903.966C694.828 909.93 692.614 875.839 697.532 860.565C723.925 778.676 657.477 641.558 641.61 644.186C636.313 641.358 632.933 638.739 632.45 634.66C632.25 632.956 632.703 627.463 632.577 625.749C630.972 604.339 636.581 583.545 640.882 564.029C641.736 560.168 641.743 555.509 641.58 548.402C641.439 541.567 643.556 534.044 646.275 526.094C655.256 499.88 666.822 476.792 681.003 458.636C684.316 458.165 690.668 442.937 694.048 443.272L717.024 418.317"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M634.144 1201.87L631.106 1168.96C627.808 1157.59 642.821 1096.4 633.714 1091.7C625.78 1087.6 610.678 1058.83 626.307 1025.38C629.687 1018.15 633.491 967.263 636.633 958.951C638.914 952.914 642.442 933.589 644.857 932.111C660.003 894.802 672.409 914.644 679.436 900.639C685.58 907.202 684.087 873.853 689.502 857.048C718.533 766.965 649.299 633.128 631.842 636.028C626.122 633.363 622.489 630.925 622.044 626.882C621.858 625.214 622.408 619.377 622.289 617.709C620.781 595.637 627.073 574.344 631.916 554.393C632.859 550.514 632.897 545.755 632.718 538.304C632.57 531.342 634.88 523.629 637.881 515.443C647.657 488.712 660.197 465.416 675.388 447.505C679.035 446.979 685.647 432.113 689.368 432.494L713.987 408.374"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M620.825 1197.4L617.356 1163.56C613.761 1151.16 629.769 1089.28 619.845 1084.15C611.19 1079.68 594.313 1053.75 611.361 1017.25C615.046 1009.37 618.886 958.008 622.318 948.935C624.807 942.354 628.521 923.11 631.151 921.496C647.427 884.196 661.214 909.278 669.623 897.276C676.324 904.437 675.559 871.832 681.464 853.504C713.132 755.236 641.113 624.671 622.073 627.834C615.922 625.332 612.045 623.084 611.644 619.069C611.48 617.446 612.119 611.264 612.015 609.633C610.604 586.89 617.579 565.108 622.965 544.731C623.997 540.833 624.057 535.974 623.871 528.179C623.715 521.091 626.226 513.186 629.494 504.765C640.065 477.508 653.578 454.013 669.779 436.356C673.754 435.785 680.64 421.281 684.688 421.689L710.948 398.394"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M607.513 1192.94L603.613 1158.18C599.72 1144.74 616.732 1082.17 605.975 1076.62C596.6 1071.78 577.947 1048.68 596.415 1009.15C600.404 1000.6 604.289 948.772 607.996 938.955C610.692 931.821 614.592 912.659 617.438 910.909C634.835 873.618 650.004 903.93 659.803 893.95C667.068 901.7 667.016 869.856 673.419 849.996C707.724 743.534 632.919 616.25 612.29 619.676C605.708 617.338 601.593 615.28 601.221 611.301C601.073 609.714 601.808 603.188 601.719 601.602C600.397 578.197 608.048 555.917 613.998 535.105C615.12 531.189 615.209 526.23 615.009 518.1C614.853 510.893 617.557 502.79 621.093 494.133C632.451 466.35 646.944 442.665 664.163 425.243C668.472 424.627 675.618 410.486 680.008 410.93L707.917 388.468"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M594.201 1188.48L589.871 1152.8C585.673 1138.33 603.695 1075.07 592.107 1069.08C582.011 1063.87 561.583 1043.61 581.469 1001.04C585.77 991.837 589.685 939.535 593.681 928.956C596.586 921.279 600.664 902.198 603.732 900.313C622.259 863.03 638.81 898.572 649.99 890.614C657.812 898.962 658.488 867.862 665.374 846.479C702.324 731.831 624.725 607.829 602.507 611.518C595.494 609.342 591.133 607.475 590.799 603.523C590.665 601.973 591.497 595.102 591.423 593.552C590.197 569.477 598.51 546.707 605.025 525.469C606.228 521.535 606.347 516.486 606.147 508.001C605.983 500.668 608.88 492.374 612.691 483.491C624.829 455.183 640.31 431.298 658.54 414.129C663.183 413.468 670.589 399.681 675.321 400.161L704.872 378.533"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M580.882 1184.01L576.12 1147.41C571.626 1131.9 590.65 1067.95 578.237 1061.54C567.421 1055.96 545.21 1038.53 566.522 992.916C571.128 983.054 575.08 930.289 579.366 918.958C582.479 910.728 586.743 891.738 590.034 889.716C609.682 852.443 627.615 893.215 640.184 887.278C648.563 896.225 649.96 865.877 657.344 842.962C696.93 720.12 616.539 599.399 592.745 603.351C585.309 601.33 580.704 599.662 580.399 595.746C580.28 594.241 581.216 587.026 581.149 585.512C580.013 560.757 588.994 537.497 596.081 515.833C597.373 511.881 597.522 506.732 597.306 497.913C597.143 490.462 600.226 481.968 604.319 472.849C617.245 444.015 633.706 419.931 652.946 403.007C657.916 402.291 665.589 388.876 670.663 389.393L701.856 368.599"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M567.57 1179.55L562.378 1142.03C557.586 1125.48 577.606 1060.84 564.368 1054C552.832 1048.05 528.845 1033.45 551.577 984.803C556.487 974.288 560.483 921.053 565.052 908.96C568.372 900.186 572.822 881.268 576.328 879.12C597.099 841.856 616.42 887.867 630.371 883.942C639.308 893.487 641.425 863.883 649.306 839.445C691.53 708.418 608.576 596.054 582.97 595.184C574.962 594.912 570.252 591.857 569.984 587.96C569.888 586.491 570.921 578.931 570.854 577.463C569.814 552.037 579.448 528.279 587.107 506.189C588.482 502.219 588.66 496.97 588.437 487.806C588.266 480.228 591.55 471.544 595.91 462.19C609.608 432.821 627.065 408.546 647.323 391.876C652.627 391.115 660.561 378.062 665.969 378.606L698.803 358.646"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M554.25 1175.09L548.634 1136.64C543.538 1119.07 564.568 1053.73 550.506 1046.46C538.249 1040.13 512.487 1028.38 536.637 976.681C541.859 965.505 545.886 911.807 550.744 898.962C554.265 889.635 558.908 870.808 562.63 868.515C584.529 831.26 605.225 882.501 620.565 880.598C630.058 890.741 632.904 861.888 641.268 835.919C686.129 696.697 600.411 587.524 573.193 587.008C564.784 586.854 559.799 584.034 559.569 580.173C559.487 578.75 560.617 570.837 560.565 569.413C559.621 543.308 569.902 519.06 578.148 496.553C579.604 492.565 579.819 487.217 579.582 477.717C579.403 470.012 582.88 461.138 587.515 451.557C601.994 421.653 620.439 397.188 641.707 380.763C647.345 379.956 655.546 367.257 661.288 367.846L695.764 348.72"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M540.939 1170.63L534.892 1131.26C529.499 1112.65 551.532 1046.61 536.638 1038.93C523.66 1032.22 496.123 1023.31 521.692 968.569C527.218 956.739 531.289 902.57 536.43 888.964C540.159 879.093 544.988 860.339 548.925 857.918C571.946 820.672 594.031 877.144 610.752 877.262C620.811 887.994 624.369 859.894 633.231 832.402C680.737 684.995 592.248 578.986 563.418 578.85C554.6 578.804 549.356 576.239 549.155 572.396C549.088 571.009 550.321 562.76 550.277 561.373C549.422 534.579 560.357 509.842 569.182 486.918C570.727 482.911 570.973 477.463 570.72 467.619C570.542 459.797 574.204 450.714 579.114 440.906C594.365 410.468 613.813 385.812 636.084 369.641C642.049 368.789 650.517 356.452 656.601 357.069L692.726 338.768"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M527.619 1166.17L521.141 1125.87C515.451 1106.23 538.487 1039.51 522.76 1031.39C509.062 1024.31 479.742 1018.23 506.737 960.456C512.576 947.965 516.676 893.333 522.107 878.975C526.044 868.551 531.051 849.887 535.218 847.331C559.368 810.094 582.828 871.805 600.938 873.926C611.554 885.257 615.84 857.9 625.193 828.885C675.335 673.293 584.068 570.438 553.648 570.683C544.43 570.755 538.91 568.435 538.754 564.618C538.695 563.277 540.039 554.675 540.002 553.333C539.244 525.859 550.818 500.623 560.23 477.282C561.857 473.257 562.132 467.719 561.879 457.521C561.693 449.572 565.549 440.299 570.741 430.255C586.765 399.282 607.208 374.436 630.497 358.51C636.796 357.604 645.525 345.638 651.943 346.291L689.709 328.824"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M514.307 1161.71L507.398 1120.49C501.403 1099.81 525.45 1032.4 508.899 1023.85C494.48 1016.4 463.384 1013.16 491.798 952.343C497.942 939.2 502.087 884.097 507.799 868.977C511.944 858.009 517.137 839.418 521.52 836.726C546.792 799.498 571.633 866.439 591.133 870.582C602.305 882.51 607.312 855.906 617.155 825.359C669.935 661.572 575.897 561.881 543.872 562.507C534.238 562.697 527.886 560.576 528.339 556.823C528.102 555.554 529.929 546.544 529.714 545.275C533.108 517.411 541.258 491.387 551.271 467.638C552.98 463.595 553.292 457.957 553.024 447.424C552.839 439.356 556.872 429.875 562.347 419.614C579.143 388.097 600.582 363.079 624.881 347.397C631.507 346.445 640.503 334.843 647.263 335.523L686.671 318.889"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M500.996 1157.24L493.656 1115.11C487.364 1093.4 512.406 1025.29 495.031 1016.31C479.891 1008.49 447.02 1008.08 476.853 944.221C483.301 930.416 487.483 874.842 493.478 858.979C497.831 847.458 503.209 828.957 507.808 826.129C534.209 788.91 560.431 861.082 581.313 867.246C593.043 879.773 598.778 853.912 609.111 821.833C664.535 649.861 567.711 553.315 534.09 554.339C524.046 554.647 517.405 552.762 517.918 549.046C517.688 547.813 519.627 538.467 519.419 537.235C523.11 508.727 531.69 482.159 542.298 458.002C544.081 453.941 544.43 448.203 544.156 437.326C543.962 429.131 548.189 419.46 553.939 408.963C571.507 376.902 592.136 346.146 619.251 336.266C625.966 333.818 635.475 324.02 642.569 324.745L683.619 308.945"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M487.675 1152.78L479.905 1109.72C473.316 1086.98 499.361 1018.18 481.153 1008.77C465.286 1000.58 430.647 1003 461.899 936.099C468.658 921.632 472.87 865.596 479.155 848.972C483.716 836.907 489.28 818.479 494.094 815.524C521.617 778.314 549.221 855.725 571.492 863.901C583.779 877.026 590.234 851.918 601.065 818.306C659.126 638.14 559.524 544.749 524.313 546.163C513.854 546.58 506.923 544.93 507.502 541.25C507.28 540.063 509.33 530.364 509.122 529.176C513.118 500.034 522.114 472.904 533.331 448.357C535.196 444.269 535.582 438.441 535.293 427.228C535.099 418.907 539.512 409.035 545.537 398.312C563.878 365.707 585.643 334.933 613.634 325.144C620.691 322.678 630.452 313.215 637.888 313.967L680.58 299.002"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M474.364 1148.32L466.163 1104.34C459.269 1080.56 486.324 1011.06 467.292 1001.24C450.704 992.671 414.282 997.937 446.96 927.996C454.024 912.876 458.281 856.368 464.848 838.992C469.617 826.374 475.359 808.036 480.403 804.936C509.055 767.736 538.041 850.377 561.694 860.574C574.545 874.298 581.714 849.932 593.042 814.798C653.741 626.447 551.353 536.201 514.552 538.005C503.677 538.54 496.456 537.126 497.103 533.482C496.887 532.34 499.049 522.296 498.848 521.145C503.142 491.368 512.561 463.685 524.38 438.731C526.327 434.624 526.742 428.696 526.445 417.139C526.245 408.7 530.843 398.62 537.157 387.679C556.263 354.531 579.158 323.748 608.033 314.04C615.432 311.547 625.445 302.428 633.216 303.217L677.557 289.085"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M461.044 1143.85L452.42 1098.94C445.229 1074.13 473.286 1003.95 453.422 993.686C436.114 984.749 397.916 992.843 432.013 919.865C439.383 904.084 443.676 847.113 450.533 828.984C455.51 815.823 461.943 798.573 466.697 794.331C501.084 763.629 528.882 840.043 551.88 857.229C567.072 868.587 573.185 847.929 584.997 811.272C648.332 614.727 543.159 527.626 504.769 529.838C493.477 530.491 485.974 529.303 486.68 525.695C486.472 524.59 488.738 514.202 488.552 513.096C493.143 482.684 502.978 454.439 515.414 429.086C517.442 424.953 517.895 418.943 517.59 407.041C517.382 398.475 522.174 388.196 528.763 377.029C548.634 343.336 572.651 312.544 602.417 302.908C610.157 300.407 620.423 291.605 628.535 292.43L674.518 279.123"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M447.732 1139.39L438.677 1093.57C431.189 1067.73 460.242 996.841 439.561 986.163C421.532 976.853 381.559 987.785 417.075 911.761C424.756 895.327 429.087 837.886 436.226 818.995C441.411 805.281 448.022 788.104 452.999 783.744C488.263 752.879 517.717 834.958 542.075 853.894C557.727 866.058 564.658 845.944 576.967 807.755C642.947 603.025 534.981 519.051 495.008 521.671C483.293 522.441 475.508 521.49 476.28 517.918C476.08 516.858 478.457 506.116 478.279 505.047C483.167 473.992 493.418 445.185 506.463 419.451C508.565 415.299 509.055 409.19 508.743 396.943C508.535 388.251 513.512 377.772 520.376 366.378C541.02 332.132 566.143 301.34 596.816 291.786C604.898 289.266 615.417 280.8 623.871 281.661L671.495 269.189"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M434.421 1134.93L424.934 1088.18C417.142 1061.31 447.205 989.734 425.692 978.621C406.942 968.94 365.194 982.709 402.129 903.648C410.114 886.553 414.482 828.649 421.911 809.006C427.304 794.748 434.079 777.634 439.301 773.147C475.448 742.101 506.522 829.854 532.269 850.558C548.382 863.52 556.137 843.95 568.937 804.238C637.554 591.313 526.802 510.485 485.239 513.504C473.109 514.383 465.041 513.667 465.873 510.132C465.68 509.107 468.169 498.03 467.998 496.997C473.19 465.308 483.85 435.93 497.504 409.806C499.688 405.627 500.215 399.427 499.888 386.836C499.673 378.026 504.836 367.339 511.982 355.727C533.391 320.928 559.621 290.136 591.2 280.655C599.624 278.126 610.403 269.986 619.191 270.874L668.457 259.235"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M421.101 1130.46L411.184 1082.79C403.095 1054.88 434.161 982.61 411.816 971.07C392.346 961.018 348.814 977.624 387.175 895.518C395.466 877.77 399.878 819.394 407.589 798.99C413.19 784.179 420.136 767.128 425.588 762.533C462.627 731.305 495.298 824.724 522.442 847.204C539.022 860.937 547.587 841.947 560.877 800.703C632.131 579.593 518.586 501.892 475.441 505.319C462.887 506.316 454.537 505.835 455.444 502.336C455.258 501.357 457.858 489.927 457.687 488.939C463.177 456.615 474.253 426.666 488.523 400.152C490.789 395.946 491.346 389.656 491.011 376.73C490.796 367.792 496.145 356.906 503.573 345.067C525.747 309.716 553.069 278.933 585.569 269.524C594.335 266.986 605.366 259.163 614.489 260.097L665.404 249.292"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M407.789 1126L397.441 1077.41C389.054 1048.46 421.123 975.503 397.954 963.538C377.763 953.113 332.456 972.557 372.236 887.405C380.839 868.995 385.281 810.157 393.281 789.001C399.09 773.645 406.207 756.649 411.89 751.945C449.827 720.527 484.08 819.602 512.636 843.877C529.692 858.371 539.066 839.961 552.846 797.195C626.738 567.9 510.4 493.326 465.68 497.169C452.702 498.284 444.07 498.03 445.043 494.568C444.865 493.625 447.577 481.85 447.413 480.908C453.2 447.949 464.684 417.42 479.571 390.535C481.911 386.311 482.513 379.92 482.164 366.65C481.941 357.594 487.475 346.499 495.186 334.434C518.125 298.53 546.547 267.756 579.968 258.411C589.075 255.863 600.359 248.367 609.823 249.328L662.38 239.357"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M394.47 1121.54L383.691 1072.03C375 1042.05 408.079 968.396 384.077 956.005C363.166 945.209 316.084 967.481 357.283 879.301C366.19 860.239 370.669 800.93 378.952 779.012C384.961 763.103 392.256 746.171 398.169 741.358C437.013 709.75 472.826 814.472 502.808 840.55C520.339 855.797 530.516 837.985 544.794 793.687C621.33 556.197 502.199 484.76 455.889 489.011C442.488 490.244 433.581 490.226 434.614 486.8C434.443 485.902 437.266 473.783 437.11 472.876C443.194 439.274 455.079 408.174 470.598 380.908C473.019 376.657 473.651 370.185 473.294 356.57C473.064 347.388 478.791 336.093 486.777 323.802C510.482 287.344 539.988 256.58 574.337 247.307C583.786 244.75 595.323 237.58 605.129 238.578L659.327 229.44"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M381.158 1117.07L369.956 1066.64C360.967 1035.63 395.042 961.28 370.216 948.463C348.584 937.287 299.726 962.404 342.344 871.179C351.555 851.455 356.079 791.675 364.644 769.013C370.862 752.561 378.32 735.674 384.479 730.752C424.236 698.936 461.594 809.323 493.009 837.206C511.016 853.186 522.003 835.982 536.764 790.161C615.937 544.486 494.012 476.176 446.121 480.835C432.289 482.177 423.107 482.394 424.206 479.004C424.043 478.143 426.977 465.679 426.829 464.818C433.217 430.582 445.504 398.892 461.639 371.263C464.135 366.985 464.811 360.422 464.447 346.463C464.217 337.154 470.122 325.66 478.39 313.151C502.86 276.141 533.443 245.385 568.729 236.175C578.519 233.619 590.316 226.757 600.448 227.791L656.289 219.479"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M367.846 1112.61L356.213 1061.25C346.928 1029.21 382.005 954.174 356.355 940.922C334.002 929.374 283.362 957.328 327.406 863.058C336.929 842.681 341.483 782.429 350.337 759.006C356.763 742.001 364.392 725.178 370.781 720.147C411.459 688.122 450.34 804.157 483.204 833.861C501.694 850.576 513.476 833.979 528.734 786.635C610.544 532.766 485.819 467.592 436.36 472.668C422.105 474.128 412.648 474.581 413.807 471.227C413.651 470.411 416.697 457.603 416.555 456.778C423.241 421.907 435.929 389.637 452.695 361.628C455.273 357.322 455.986 350.669 455.607 336.374C455.37 326.947 461.461 315.245 470.019 302.509C495.253 264.937 526.914 234.199 563.143 225.062C573.275 222.506 585.324 215.962 595.799 217.022L653.281 209.544"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M354.527 1108.15L342.463 1055.88C332.872 1022.8 368.96 947.068 342.477 933.389C319.404 921.47 266.989 952.262 312.451 854.954C322.279 833.916 326.878 773.202 336.015 749.027C342.648 731.478 350.441 714.699 357.067 709.569C398.674 677.336 439.071 799.017 473.383 830.535C492.363 847.966 504.932 832.003 520.688 783.118C605.136 521.064 477.617 459.017 426.576 464.501C411.897 466.078 402.158 466.758 403.384 463.441C403.236 462.661 406.385 449.509 406.259 448.729C413.242 413.223 426.338 380.355 443.721 351.984C446.373 347.651 447.123 340.907 446.737 326.268C446.499 316.714 452.777 304.803 461.609 291.85C487.609 253.725 520.346 223.005 557.511 213.922C567.986 211.366 580.287 205.139 591.103 206.236L650.234 199.591"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M341.215 1103.68L328.72 1050.48C318.833 1016.37 355.923 939.952 328.616 925.847C304.822 913.556 250.631 947.176 297.513 846.832C307.645 825.141 312.281 763.955 321.708 739.019C328.549 720.917 336.505 704.193 343.369 698.954C385.912 666.503 427.809 793.841 463.578 827.18C483.04 845.327 496.412 829.99 512.658 779.583C599.75 509.334 469.432 450.415 416.808 456.316C401.698 458.011 391.692 458.917 392.977 455.636C392.836 454.902 396.097 441.395 395.971 440.661C403.251 404.512 416.748 371.064 434.755 342.33C437.489 337.97 438.276 331.135 437.875 316.151C437.63 306.47 444.093 294.36 453.208 281.19C479.973 242.502 513.772 211.81 551.888 202.791C562.704 200.235 575.258 194.325 586.416 195.449L647.189 189.638"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M327.895 1099.22L314.969 1045.1C304.785 1009.96 342.871 932.836 314.739 918.306C290.225 905.643 234.258 942.1 282.559 838.711C293.003 816.358 297.676 754.71 307.385 729.021C314.435 710.376 322.561 693.697 329.663 688.358C373.142 655.68 416.532 788.675 453.764 823.845C473.717 842.699 487.876 828.005 504.612 776.066C594.342 497.632 461.222 441.84 407.024 448.158C391.491 449.971 381.202 451.113 382.554 447.868C382.421 447.17 385.793 433.328 385.674 432.63C393.259 395.847 407.15 361.8 425.788 332.703C428.596 328.316 429.421 321.4 429.012 306.072C428.76 296.273 435.416 283.955 444.805 270.557C472.336 231.308 507.198 200.634 546.272 191.678C557.422 189.122 570.244 183.52 581.728 184.689L644.143 179.713"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M314.583 1094.76L301.227 1039.72C290.738 1003.54 329.834 925.729 300.87 910.764C275.636 897.729 217.886 937.024 267.613 830.598C278.362 807.592 283.072 745.473 293.07 719.023C300.328 699.824 308.618 683.191 315.958 677.761C360.38 644.857 405.256 783.499 443.951 820.509C464.402 840.07 479.348 826.011 496.575 772.549C588.942 485.929 453.029 433.256 397.256 439.99C381.292 441.921 370.728 443.29 372.155 440.081C372.028 439.428 375.512 425.233 375.401 424.581C383.283 387.163 397.568 352.518 416.837 323.059C419.727 318.644 420.589 311.637 420.173 295.965C419.92 286.039 426.754 273.521 436.434 259.897C464.729 220.086 500.654 189.439 540.678 180.547C552.17 177.99 565.245 172.706 577.071 173.902L641.127 169.76"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M301.264 1090.3L287.477 1034.33C276.69 997.122 316.79 918.614 287.001 903.232C261.046 889.825 201.521 931.957 252.667 822.485C263.72 798.818 268.467 736.236 278.748 709.034C286.214 689.291 294.668 672.703 302.245 667.165C347.626 634.034 393.972 778.323 434.131 817.173C455.072 837.432 470.806 824.017 488.53 769.032C583.534 474.218 444.821 424.672 387.472 431.824C371.078 433.863 360.247 435.476 361.732 432.304C361.614 431.688 365.209 417.148 365.105 416.532C373.284 378.47 387.97 343.227 407.871 313.423C410.835 308.981 411.734 301.884 411.311 285.876C411.051 275.833 418.071 263.097 428.032 249.265C457.093 208.9 494.072 178.271 535.055 169.434C546.889 166.886 560.216 161.91 572.384 163.143L638.082 159.825"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M287.952 1085.84L273.741 1028.95C262.658 990.704 303.76 911.508 273.14 895.69C246.456 881.912 185.163 926.881 237.728 814.363C249.093 790.043 253.877 726.991 264.441 699.027C272.114 678.731 280.732 662.189 288.546 656.56C334.878 623.193 382.688 773.138 424.325 813.829C445.764 834.777 462.285 822.023 480.492 765.506C578.14 462.507 436.619 416.088 377.696 423.657C360.871 425.814 349.765 427.654 351.318 424.518C351.206 423.947 354.913 409.063 354.816 408.483C363.292 369.786 378.372 333.945 398.905 303.779C401.943 299.31 402.886 292.122 402.448 275.769C402.188 265.599 409.394 252.664 419.63 238.605C449.449 197.679 487.49 167.077 529.439 158.303C541.607 155.755 555.194 151.087 567.696 152.356L635.043 149.873"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M274.64 1081.38L259.999 1023.56C248.611 984.277 290.716 904.392 259.271 888.158C231.867 874.008 168.798 921.805 222.782 806.251C234.452 781.269 239.273 717.745 250.126 689.038C258.008 668.199 266.788 651.692 274.848 645.972C322.146 612.37 371.412 767.962 414.52 810.502C436.456 832.148 453.757 820.038 472.462 761.998C572.748 450.805 428.433 407.513 367.935 415.499C350.679 417.774 339.306 419.85 340.918 416.749C340.821 416.215 344.632 400.986 344.535 400.452C353.309 361.121 368.79 324.672 389.954 294.161C393.074 289.665 394.047 282.386 393.601 265.699C393.334 255.401 400.725 242.258 411.244 227.981C441.827 186.484 480.916 155.919 523.831 147.199C536.34 144.661 550.18 140.301 563.024 141.606L632.012 139.956"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M261.32 1076.91L246.248 1018.17C234.563 977.85 277.671 897.276 245.401 880.606C217.277 866.085 152.426 916.719 207.835 798.129C219.81 772.494 224.675 708.499 235.811 679.03C243.901 657.638 252.845 641.177 261.142 635.358C309.405 601.511 360.12 762.759 404.706 807.139C427.14 829.465 445.221 818.025 464.424 758.453C567.346 439.075 420.232 398.901 358.159 407.304C340.479 409.697 328.824 411.999 330.502 408.936C330.413 408.446 334.336 392.873 334.246 392.375C343.324 352.409 359.191 315.345 380.987 284.489C384.181 279.966 385.199 272.606 384.738 255.564C384.463 245.149 392.04 231.797 402.849 217.303C434.197 175.244 474.334 144.706 518.214 136.049C531.066 133.511 545.165 129.468 558.343 130.801L628.974 129.985"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M248.009 1072.45L232.505 1012.79C220.523 971.442 264.634 890.17 231.532 873.074C202.687 858.181 136.061 911.652 192.889 790.016C205.176 763.72 210.071 699.262 221.496 669.041C229.787 647.096 238.901 630.68 247.437 624.77C296.666 590.679 348.829 757.583 394.893 803.812C417.825 826.827 436.694 816.04 456.379 754.945C561.946 427.373 412.023 390.326 348.376 399.146C330.265 401.657 318.342 404.195 320.08 401.167C319.999 400.714 324.025 384.797 323.951 384.344C333.326 343.735 349.587 306.072 372.021 274.863C375.297 270.312 376.344 262.861 375.884 245.476C375.609 234.934 383.364 221.373 394.455 206.661C426.569 164.04 467.752 133.529 512.606 124.927C525.792 122.398 540.151 118.654 553.671 120.032L625.943 120.05"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M234.69 1067.99L218.755 1007.41C206.469 965.024 251.59 883.063 217.663 865.541C188.098 850.277 119.696 906.585 177.943 781.903C190.534 754.955 195.467 690.026 207.182 659.043C215.68 636.554 224.966 620.175 233.739 614.174C283.948 579.838 337.538 752.389 385.088 800.477C408.517 824.171 428.166 814.055 448.349 751.429C556.553 415.671 403.83 381.751 338.615 390.988C320.073 393.617 307.876 396.381 309.681 393.39C309.607 392.982 313.744 376.711 313.677 376.304C323.349 335.06 340.004 296.781 363.07 265.236C366.42 260.65 367.512 253.117 367.037 235.396C366.754 224.736 374.703 210.967 386.069 196.029C418.947 152.846 461.171 122.362 506.998 113.823C520.525 111.303 535.137 107.868 548.991 109.273L622.913 110.125"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M221.378 1063.52L205.013 1002.02C192.429 958.598 238.553 875.947 203.795 857.991C173.509 842.345 103.331 901.5 162.997 773.782C175.893 746.171 180.87 680.78 192.867 649.045C201.574 626.003 211.023 609.66 220.034 603.578C271.224 568.987 326.239 747.204 375.275 797.141C399.21 821.515 419.638 812.061 440.312 747.902C551.153 403.95 395.629 373.158 328.839 382.812C309.859 385.558 297.394 388.559 299.266 385.604C299.2 385.232 303.449 368.626 303.389 368.254C313.358 326.376 330.407 287.48 354.104 255.592C357.536 250.978 358.658 243.355 358.175 225.289C357.892 214.502 366.019 200.525 377.667 185.378C411.311 141.624 454.575 111.176 501.375 102.701C515.244 100.19 530.108 97.0627 544.304 98.5039L619.867 100.181"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M208.065 1059.06L191.27 996.641C178.389 952.189 225.508 868.841 189.925 850.467C158.918 834.45 86.9655 896.433 148.05 765.678C161.258 737.415 166.265 671.552 178.552 639.056C187.466 615.47 197.071 599.163 206.327 592.981C258.498 558.146 314.94 742.011 365.461 793.805C389.901 818.859 411.102 810.067 432.274 744.386C545.752 392.248 387.428 364.574 319.063 374.645C299.652 377.509 286.92 380.736 288.858 377.817C288.799 377.491 293.16 360.531 293.108 360.205C303.381 317.693 320.816 278.18 345.152 245.947C348.658 241.306 349.824 233.592 349.327 215.191C349.037 204.278 357.35 190.101 369.287 174.727C403.689 130.411 447.993 99.9997 495.78 91.5697C509.984 89.0679 525.116 86.2398 539.646 87.7173L616.851 90.2282"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M194.746 1054.6L177.527 991.257C164.341 945.771 212.471 861.734 176.064 842.925C144.336 826.537 70.6006 891.357 133.112 757.556C146.624 728.631 151.676 662.306 164.237 629.058C173.36 604.919 183.128 588.648 192.622 582.385C245.78 547.287 303.641 736.816 355.648 790.469C380.593 816.203 402.574 808.081 424.229 740.868C540.352 380.545 379.212 355.99 309.287 366.486C289.445 369.469 276.438 372.931 278.444 370.049C278.392 369.759 282.864 352.455 282.812 352.174C293.382 309.018 311.211 268.889 336.178 236.329C339.766 231.652 340.97 223.866 340.457 205.111C340.16 194.08 348.666 179.685 360.878 164.094C396.045 119.207 441.389 88.8319 490.15 80.4654C504.695 77.9726 520.079 75.4527 534.944 76.9665L613.79 80.3113"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M181.435 1050.13L163.785 985.863C150.302 939.344 199.434 854.61 162.195 835.375C129.747 818.614 54.2359 886.272 118.166 749.434C131.983 719.848 137.072 653.06 149.923 619.05C159.253 594.368 169.185 578.133 178.924 571.779C233.071 536.428 292.35 731.613 345.843 787.124C371.286 813.529 394.047 806.078 416.199 737.342C534.959 368.825 371.019 347.397 299.519 358.31C279.246 361.401 265.972 365.1 268.037 362.253C267.992 362.009 272.576 344.36 272.531 344.115C283.399 300.325 301.621 259.58 327.227 226.676C330.89 221.971 332.13 214.094 331.61 195.004C331.313 183.846 339.997 169.243 352.492 153.435C388.424 107.985 434.8 77.6374 484.542 69.3252C499.421 66.8416 515.065 64.6298 530.272 66.1708L610.76 70.3495"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M168.115 1045.67L150.034 980.479C136.254 932.927 186.389 847.503 148.325 827.842C115.157 810.71 37.8703 881.205 103.219 741.322C117.348 711.082 122.474 643.815 135.608 609.061C145.146 583.826 155.241 567.628 165.218 561.183C220.36 525.568 281.044 726.41 336.03 783.78C361.985 810.855 385.518 804.084 408.161 733.816C529.558 357.114 362.817 338.804 289.742 350.134C269.039 353.343 255.489 357.277 257.621 354.467C257.584 354.259 262.279 336.266 262.242 336.066C273.407 291.641 292.023 250.271 318.261 217.04C321.997 212.309 323.282 204.341 322.755 184.907C322.45 173.621 331.32 158.819 344.097 142.793C380.794 96.7726 428.195 66.47 478.932 58.2123C494.153 55.7377 510.051 53.8341 525.598 55.4114L607.736 60.424"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M154.803 1041.21L136.291 975.104C122.207 926.518 173.345 840.396 134.457 820.309C100.568 802.806 21.5058 876.129 88.2735 733.209C102.707 702.308 107.87 634.578 121.293 599.072C131.04 573.293 141.298 557.122 151.513 550.595C207.65 514.718 269.745 721.225 326.217 780.453C352.677 808.199 376.984 802.099 400.116 730.308C524.15 345.412 354.609 330.228 279.967 341.985C258.833 345.312 245.008 349.472 247.207 346.699C247.177 346.536 251.984 328.198 251.954 328.026C263.424 282.966 282.418 240.97 309.302 207.405C313.12 202.637 314.435 194.587 313.901 174.809C313.596 163.406 322.644 148.386 335.711 132.142C373.173 85.5596 421.607 55.2842 473.324 47.0809C488.88 44.6153 505.037 43.0109 520.926 44.6244L604.705 50.462"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M141.491 1036.74L122.549 969.711C108.167 920.092 160.308 833.281 120.587 812.768C85.9779 794.893 5.1331 871.053 73.3271 725.096C88.0653 693.543 93.2653 625.342 106.978 589.074C116.933 562.742 127.355 546.607 137.807 539.999C194.947 503.859 258.439 716.023 316.404 777.117C343.369 805.535 368.448 800.105 392.078 726.791C518.757 333.71 346.4 321.645 270.191 333.818C248.618 337.263 234.534 341.659 236.792 338.922C236.77 338.795 241.68 320.113 241.665 319.986C253.432 274.283 272.821 231.671 300.336 197.769C304.228 192.974 305.588 184.843 305.038 164.72C304.726 153.19 313.945 137.944 327.309 121.501C365.469 74.2747 414.995 44.1171 467.701 35.9681C483.59 33.5117 500.015 32.2154 516.231 33.8652L601.652 40.5367"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M128.171 1032.28L108.798 964.326C94.1191 913.674 147.263 826.165 106.718 805.226C71.3878 786.98 -11.2324 865.977 58.3804 716.974C73.4306 684.759 78.6678 616.096 92.6632 579.076C102.825 552.2 113.411 536.093 124.108 529.394C182.251 492.991 247.14 710.82 306.598 773.773C334.068 802.861 359.927 798.111 384.047 723.265C513.364 321.989 338.206 313.052 260.422 325.642C238.419 329.204 224.059 333.827 226.384 331.126C226.369 331.045 231.391 312.009 231.376 311.928C243.44 265.59 263.222 222.343 291.369 188.125C295.343 183.293 296.733 175.081 296.175 154.613C295.856 142.965 305.268 127.51 318.907 110.85C357.855 63.08 408.383 32.9315 462.084 24.8369C478.316 22.3895 494.993 21.4014 511.551 23.0784L598.613 30.5838"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M114.859 1027.82L95.0625 958.951C80.0791 907.265 134.233 819.068 92.8562 797.694C56.8055 779.075 -27.5901 860.909 43.4416 708.862C58.7964 675.994 64.0707 606.859 78.3557 569.078C88.726 541.649 99.4751 525.578 110.41 518.797C169.556 482.122 235.841 705.617 296.792 770.437C324.775 800.196 351.399 796.117 376.01 719.748C507.963 310.287 329.983 304.304 250.653 317.484C228.212 321.119 213.592 326.023 215.977 323.358C215.969 323.312 221.103 303.942 221.095 303.896C233.456 256.924 253.58 212.989 282.418 178.498C286.459 173.631 287.9 165.336 287.328 144.533C287.009 132.759 296.606 117.104 310.52 100.217C350.248 51.9034 401.787 21.7731 456.476 13.7329C473.041 11.2945 489.978 10.6056 506.878 12.3279L595.583 20.6672"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M101.541 1023.35L81.3127 953.557C66.0322 900.838 121.189 811.943 78.9802 790.152C42.2015 771.162 -43.9621 855.833 28.4884 700.749C44.1478 667.219 49.4666 597.622 64.034 559.089C157.5 311.818 277.612 996.605 367.965 716.231C540.597 180.537 194.42 327.5 205.555 315.571L210.8 295.847C223.465 248.24 244.02 203.733 273.445 168.853C277.575 163.958 279.038 155.574 278.466 134.426C278.139 122.525 287.93 106.671 302.126 89.557C354.943 25.8608 428.196 -5.97364 502.199 1.53177L592.552 10.7051"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_16_909">
                        <rect width="797" height="1251" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </div>
        <div v-if="index == 4">
            <svg width="850" height="1100" viewBox="0 0 850 1100" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_16_962)">
                    <path
                        d="M338.54 82.8L223.17 251.2C213.89 264.75 195.38 268.21 181.83 258.93L13.4299 143.55C-0.120131 134.27 -3.57013 115.76 5.70987 102.21C78.7099 -4.33996 224.26 -31.54 330.82 41.46C344.37 50.74 347.83 69.25 338.55 82.8H338.54Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M346.42 95.3898L231.59 263C222.31 276.55 203.8 280.01 190.25 270.73L22.63 155.9C9.08 146.62 5.62 128.11 14.9 114.56L15.13 114.23C87.71 8.28981 232.42 -18.7502 338.36 53.8198L338.69 54.0498C352.24 63.3298 355.7 81.8398 346.42 95.3898Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M353.71 108.15L239.42 274.98C230.14 288.53 211.63 291.99 198.08 282.71L31.2501 168.41C17.7001 159.13 14.2401 140.62 23.5201 127.07L23.9701 126.41C96.1301 21.0901 240.01 -5.79993 345.33 66.3601L345.99 66.8101C359.54 76.0901 363 94.6001 353.72 108.15H353.71Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M360.45 121.07L246.7 287.11C237.42 300.66 218.91 304.12 205.36 294.84L39.3097 181.08C25.7597 171.8 22.2997 153.29 31.5797 139.74L32.2597 138.75C104 34.05 247.03 7.31997 351.73 79.05L352.72 79.73C366.27 89.01 369.73 107.52 360.45 121.07Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M366.64 134.13L253.42 299.39C244.14 312.94 225.63 316.4 212.08 307.12L46.8302 193.9C33.2802 184.62 29.8202 166.11 39.1002 152.56L40.0002 151.24C111.32 47.15 253.51 20.58 357.6 91.89L358.92 92.79C372.47 102.07 375.93 120.58 366.65 134.13H366.64Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M372.31 147.33L259.63 311.8C250.35 325.35 231.84 328.81 218.29 319.53L53.8204 206.85C40.2704 197.57 36.8104 179.06 46.0904 165.51L47.2204 163.86C118.11 60.3902 259.46 33.9702 362.93 104.86L364.58 105.99C378.13 115.27 381.59 133.78 372.31 147.33Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M377.46 160.65L265.32 324.34C256.04 337.89 237.53 341.35 223.98 332.07L60.2999 219.92C46.7499 210.64 43.2899 192.13 52.5699 178.58L53.9299 176.6C124.4 73.7402 264.9 47.4802 367.76 117.95L369.74 119.31C383.29 128.59 386.75 147.1 377.47 160.65H377.46Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M382.12 174.09L270.52 336.99C261.24 350.54 242.73 354 229.18 344.72L66.2804 233.12C52.7304 223.84 49.2704 205.33 58.5504 191.78L60.1304 189.47C130.18 87.2299 269.84 61.1299 372.08 131.17L374.39 132.75C387.94 142.03 391.4 160.54 382.12 174.09Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M386.3 187.62L275.24 349.74C265.96 363.29 247.45 366.75 233.9 357.47L71.7804 246.41C58.2304 237.13 54.7704 218.62 64.0504 205.07L65.8604 202.43C135.48 100.8 274.31 74.8601 375.93 144.48L378.57 146.29C392.12 155.57 395.58 174.08 386.3 187.63V187.62Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M390.02 201.24L279.49 362.57C270.21 376.12 251.7 379.58 238.15 370.3L76.8204 259.77C63.2704 250.49 59.8104 231.98 69.0904 218.43L71.1204 215.46C140.32 114.45 278.3 88.6599 379.32 157.86L382.29 159.89C395.84 169.17 399.3 187.68 390.02 201.23V201.24Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M393.3 214.94L283.31 375.48C274.03 389.03 255.52 392.49 241.97 383.21L81.4298 273.22C67.8798 263.94 64.4198 245.43 73.6998 231.88L75.9598 228.58C144.74 128.19 281.88 102.56 382.28 171.34L385.58 173.6C399.13 182.88 402.59 201.39 393.31 214.94H393.3Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M396.14 228.71L286.69 388.47C277.41 402.02 258.9 405.48 245.35 396.2L85.59 286.75C72.04 277.47 68.58 258.96 77.86 245.41L80.34 241.78C148.7 142 285 116.53 384.78 184.89L388.41 187.37C401.96 196.65 405.42 215.16 396.14 228.71Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M398.58 242.53L289.67 401.5C280.39 415.05 261.88 418.51 248.33 409.23L89.3605 300.32C75.8105 291.04 72.3505 272.53 81.6305 258.98L84.3405 255.02C152.28 155.86 287.73 130.54 386.9 198.48L390.86 201.19C404.41 210.47 407.87 228.98 398.59 242.53H398.58Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M400.61 256.39L292.24 414.58C282.96 428.13 264.45 431.59 250.9 422.31L92.7101 313.94C79.1601 304.66 75.7001 286.15 84.9801 272.6L87.9201 268.31C155.43 169.76 290.05 144.61 388.6 212.12L392.89 215.06C406.44 224.34 409.9 242.85 400.62 256.4L400.61 256.39Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M402.27 270.28L294.44 427.68C285.16 441.23 266.65 444.69 253.1 435.41L95.7003 327.58C82.1503 318.3 78.6903 299.79 87.9703 286.24L91.1303 281.63C158.22 183.7 292 158.7 389.93 225.79L394.54 228.95C408.09 238.23 411.55 256.74 402.27 270.29V270.28Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M403.56 284.2L296.26 440.82C286.98 454.37 268.47 457.83 254.92 448.55L98.2999 341.25C84.7499 331.97 81.2899 313.46 90.5699 299.91L93.9599 294.97C160.63 197.66 293.56 172.81 390.88 239.48L395.82 242.87C409.37 252.15 412.83 270.66 403.55 284.21L403.56 284.2Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M404.51 298.12L297.75 453.95C288.47 467.5 269.96 470.96 256.41 461.68L100.58 354.92C87.0302 345.64 83.5702 327.13 92.8502 313.58L96.4602 308.31C162.71 211.61 294.8 186.93 391.5 253.17L396.77 256.78C410.32 266.06 413.78 284.57 404.5 298.12H404.51Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M405.13 312.04L298.91 467.08C289.63 480.63 271.12 484.09 257.57 474.81L102.53 368.59C88.9804 359.31 85.5204 340.8 94.8004 327.25L98.6404 321.65C164.47 225.57 295.72 201.04 391.8 266.86L397.4 270.7C410.95 279.98 414.41 298.49 405.13 312.04Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M405.43 325.95L299.75 480.21C290.47 493.76 271.96 497.22 258.41 487.94L104.15 382.26C90.5995 372.98 87.1395 354.47 96.4195 340.92L100.48 334.99C165.88 239.52 296.29 215.15 391.76 280.56L397.69 284.62C411.24 293.9 414.7 312.41 405.42 325.96L405.43 325.95Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M405.43 339.83L300.29 493.3C291.01 506.85 272.5 510.31 258.95 501.03L105.48 395.89C91.9296 386.61 88.4696 368.1 97.7496 354.55L102.04 348.29C167.02 253.44 296.59 229.22 391.44 294.21L397.7 298.5C411.25 307.78 414.71 326.29 405.43 339.84V339.83Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M405.15 353.67L300.54 506.36C291.26 519.91 272.75 523.37 259.2 514.09L106.51 409.48C92.9599 400.2 89.4999 381.69 98.7799 368.14L103.3 361.55C167.86 267.31 296.59 243.26 390.82 307.82L397.41 312.34C410.96 321.62 414.42 340.13 405.14 353.68L405.15 353.67Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M404.61 367.46L300.54 519.36C291.26 532.91 272.75 536.37 259.2 527.09L107.3 423.02C93.7499 413.74 90.2899 395.23 99.5699 381.68L104.31 374.76C168.45 281.14 296.33 257.24 389.95 321.38L396.87 326.12C410.42 335.4 413.88 353.91 404.6 367.46H404.61Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M403.82 381.2L300.29 532.32C291.01 545.87 272.5 549.33 258.95 540.05L107.83 436.52C94.2802 427.24 90.8202 408.73 100.1 395.18L105.07 387.93C168.79 294.93 295.83 271.18 388.83 334.9L396.08 339.87C409.63 349.15 413.09 367.66 403.81 381.21L403.82 381.2Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M402.79 394.86L299.8 545.19C290.52 558.74 272.01 562.2 258.46 552.92L108.13 449.93C94.58 440.65 91.12 422.14 100.4 408.59L105.59 401.01C168.88 308.62 295.09 285.04 387.48 348.33L395.06 353.52C408.61 362.8 412.07 381.31 402.79 394.86Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M401.55 408.44L299.1 557.99C289.82 571.54 271.31 575 257.76 565.72L108.21 463.27C94.6601 453.99 91.2001 435.48 100.48 421.93L105.9 414.02C168.77 322.25 294.13 298.82 385.91 361.69L393.82 367.11C407.37 376.39 410.83 394.9 401.55 408.45V408.44Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M400.11 421.92L298.2 570.68C288.92 584.23 270.41 587.69 256.86 578.41L108.1 476.5C94.5497 467.22 91.0897 448.71 100.37 435.16L106.02 426.92C168.47 335.76 292.99 312.49 384.15 374.94L392.39 380.59C405.94 389.87 409.4 408.38 400.12 421.93L400.11 421.92Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M398.49 435.3L297.11 583.27C287.83 596.82 269.32 600.28 255.77 591L107.8 489.62C94.2499 480.34 90.7899 461.83 100.07 448.28L105.94 439.71C167.97 349.17 291.65 326.06 382.19 388.08L390.76 393.95C404.31 403.23 407.77 421.74 398.49 435.29V435.3Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M396.7 448.56L295.86 595.75C286.58 609.3 268.07 612.76 254.52 603.48L107.33 502.64C93.7802 493.36 90.3202 474.85 99.6002 461.3L105.7 452.4C167.31 362.48 290.14 339.52 380.07 401.13L388.97 407.23C402.52 416.51 405.98 435.02 396.7 448.57V448.56Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M394.76 461.69L294.46 608.09C285.18 621.64 266.67 625.1 253.12 615.82L106.72 515.52C93.1698 506.24 89.7098 487.73 98.9898 474.18L105.31 464.95C166.49 375.64 288.49 352.84 377.8 414.03L387.03 420.35C400.58 429.63 404.04 448.14 394.76 461.69Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M392.69 474.69L292.93 620.31C283.65 633.86 265.14 637.32 251.59 628.04L105.97 528.28C92.4198 519 88.9598 500.49 98.2398 486.94L104.79 477.38C165.55 388.69 286.71 366.05 375.4 426.81L384.96 433.36C398.51 442.64 401.97 461.15 392.69 474.7V474.69Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M390.49 487.53L291.27 632.36C281.99 645.91 263.48 649.37 249.93 640.09L105.1 540.87C91.5497 531.59 88.0897 513.08 97.3697 499.53L104.14 489.64C164.48 401.56 284.79 379.08 372.87 439.42L382.76 446.19C396.31 455.47 399.77 473.98 390.49 487.53Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M388.2 500.2L289.52 644.25C280.24 657.8 261.73 661.26 248.18 651.98L104.13 553.3C90.58 544.02 87.12 525.51 96.4 511.96L103.4 501.74C163.32 414.28 282.79 391.95 370.25 451.87L380.47 458.87C394.02 468.15 397.48 486.66 388.2 500.21V500.2Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M385.82 512.71L287.67 655.97C278.39 669.52 259.88 672.98 246.33 663.7L103.07 565.55C89.5204 556.27 86.0604 537.76 95.3404 524.21L102.57 513.66C162.07 426.82 280.7 404.65 367.54 464.14L378.09 471.37C391.64 480.65 395.1 499.16 385.82 512.71Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M383.37 525.02L285.76 667.5C276.48 681.05 257.97 684.51 244.42 675.23L101.94 577.62C88.3896 568.34 84.9296 549.83 94.2096 536.28L101.66 525.4C160.73 439.17 278.52 417.16 364.75 476.23L375.63 483.68C389.18 492.96 392.64 511.47 383.36 525.02H383.37Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M380.87 537.14L283.8 678.83C274.52 692.38 256.01 695.84 242.46 686.56L100.77 589.49C87.2196 580.21 83.7597 561.7 93.0397 548.15L100.72 536.94C159.37 451.33 276.32 429.47 361.93 488.12L373.14 495.8C386.69 505.08 390.15 523.59 380.87 537.14Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M378.34 549.05L281.81 689.95C272.53 703.5 254.02 706.96 240.47 697.68L99.5704 601.15C86.0204 591.87 82.5604 573.36 91.8404 559.81L99.7404 548.27C157.97 463.27 274.08 441.58 359.07 499.81L370.61 507.71C384.16 516.99 387.62 535.5 378.34 549.05Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M375.79 560.74L279.8 700.86C270.52 714.41 252.01 717.87 238.46 708.59L98.34 612.6C84.79 603.32 81.33 584.81 90.61 571.26L98.74 559.39C156.55 475.01 271.81 453.47 356.2 511.28L368.07 519.41C381.62 528.69 385.08 547.2 375.8 560.75L375.79 560.74Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M373.23 572.2L277.77 711.53C268.49 725.08 249.98 728.54 236.43 719.26L97.0997 623.8C83.5497 614.52 80.0897 596.01 89.3697 582.46L97.7297 570.26C155.12 486.5 269.54 465.11 353.31 522.5L365.51 530.86C379.06 540.14 382.52 558.65 373.24 572.2H373.23Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M370.68 583.42L275.76 721.97C266.48 735.52 247.97 738.98 234.42 729.7L95.8702 634.78C82.3202 625.5 78.8602 606.99 88.1402 593.44L96.7202 580.91C153.68 497.76 267.27 476.53 350.42 533.5L362.95 542.08C376.5 551.36 379.96 569.87 370.68 583.42Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M368.17 594.38L273.79 732.14C264.51 745.69 246 749.15 232.45 739.87L94.6896 645.49C81.1396 636.21 77.6796 617.7 86.9596 604.15L95.7696 591.29C152.31 508.76 265.05 487.69 347.59 544.23L360.45 553.04C374 562.32 377.46 580.83 368.18 594.38H368.17Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M365.7 605.07L271.86 742.05C262.58 755.6 244.07 759.06 230.52 749.78L93.5402 655.94C79.9902 646.66 76.5302 628.15 85.8102 614.6L94.8402 601.41C150.96 519.49 262.86 498.58 344.78 554.7L357.97 563.73C371.52 573.01 374.98 591.52 365.7 605.07Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M363.3 615.49L270 751.68C260.72 765.23 242.21 768.69 228.66 759.41L92.4698 666.11C78.9198 656.83 75.4598 638.32 84.7398 624.77L93.9998 611.26C149.7 529.96 260.76 509.2 342.06 564.9L355.57 574.16C369.12 583.44 372.58 601.95 363.3 615.5V615.49Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M360.97 625.62L268.21 761.02C258.93 774.57 240.42 778.03 226.87 768.75L91.4698 675.99C77.9198 666.71 74.4598 648.2 83.7398 634.65L93.2198 620.81C148.5 540.13 258.71 519.53 339.4 574.8L353.24 584.28C366.79 593.56 370.25 612.07 360.97 625.62Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M358.74 635.44L266.51 770.06C257.23 783.61 238.72 787.07 225.17 777.79L90.5499 685.56C76.9999 676.28 73.5399 657.77 82.8199 644.22L92.5299 630.05C147.38 549.98 256.76 529.54 336.83 584.4L351 594.11C364.55 603.39 368.01 621.9 358.73 635.45L358.74 635.44Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M356.62 644.96L264.93 778.79C255.65 792.34 237.14 795.8 223.59 786.52L89.7599 694.83C76.2099 685.55 72.7499 667.04 82.0299 653.49L91.9699 638.99C146.4 559.54 254.94 539.25 334.39 593.69L348.89 603.63C362.44 612.91 365.9 631.42 356.62 644.97V644.96Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M354.63 654.15L263.48 787.2C254.2 800.75 235.69 804.21 222.14 794.93L89.09 703.78C75.54 694.5 72.08 675.99 81.36 662.44L91.52 647.61C145.53 568.77 253.22 548.65 332.06 602.66L346.89 612.82C360.44 622.1 363.9 640.61 354.62 654.16L354.63 654.15Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M352.78 663.01L262.17 795.27C252.89 808.82 234.38 812.28 220.83 803L88.5704 712.39C75.0204 703.11 71.5604 684.6 80.8404 671.05L91.2304 655.89C144.82 577.67 251.67 557.7 329.89 611.29L345.05 621.68C358.6 630.96 362.06 649.47 352.78 663.02V663.01Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M351.09 671.52L261.02 803C251.74 816.55 233.23 820.01 219.68 810.73L88.2003 720.66C74.6503 711.38 71.1903 692.87 80.4703 679.32L91.0803 663.83C144.25 586.22 250.26 566.41 327.86 619.58L343.35 630.19C356.9 639.47 360.36 657.98 351.08 671.53L351.09 671.52Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M349.58 679.67L260.04 810.36C250.76 823.91 232.25 827.37 218.7 818.09L88.0099 728.55C74.4599 719.27 70.9999 700.76 80.2799 687.21L91.1199 671.39C143.86 594.4 249.03 574.75 326.02 627.49L341.84 638.33C355.39 647.61 358.85 666.12 349.57 679.67H349.58Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M348.27 687.45L259.27 817.36C249.99 830.91 231.48 834.37 217.93 825.09L88.0196 736.09C74.4696 726.81 71.0097 708.3 80.2897 694.75L91.3597 678.6C143.68 602.23 248.01 582.73 324.38 635.05L340.53 646.12C354.08 655.4 357.54 673.91 348.26 687.46L348.27 687.45Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M347.16 694.86L258.7 823.98C249.42 837.53 230.91 840.99 217.36 831.71L88.2404 743.25C74.6904 733.97 71.2304 715.46 80.5104 701.91L91.8004 685.43C143.7 609.67 247.19 590.33 322.95 642.23L339.43 653.52C352.98 662.8 356.44 681.31 347.16 694.86Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M346.28 701.87L258.36 830.2C249.08 843.75 230.57 847.21 217.02 837.93L88.6896 750.01C75.1396 740.73 71.6796 722.22 80.9596 708.67L92.4796 691.86C143.96 616.72 246.6 597.54 321.75 649.01L338.56 660.53C352.11 669.81 355.57 688.32 346.29 701.87H346.28Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M345.65 708.47L258.27 836.02C248.99 849.57 230.48 853.03 216.93 843.75L89.38 756.37C75.83 747.09 72.37 728.58 81.65 715.03L93.39 697.89C144.45 623.36 246.25 604.34 320.78 655.4L337.92 667.14C351.47 676.42 354.93 694.93 345.65 708.48V708.47Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M345.27 714.67L258.43 841.43C249.15 854.98 230.64 858.44 217.09 849.16L90.3302 762.32C76.7802 753.04 73.3202 734.53 82.6002 720.98L94.5702 703.51C145.2 629.6 246.17 610.73 320.08 661.37L337.55 673.34C351.1 682.62 354.56 701.13 345.28 714.68L345.27 714.67Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M345.17 720.43L258.86 846.41C249.58 859.96 231.07 863.42 217.52 854.14L91.5402 767.83C77.9902 758.55 74.5302 740.04 83.8102 726.49L96.0002 708.69C146.21 635.4 246.33 616.69 319.63 666.9L337.43 679.09C350.98 688.37 354.44 706.88 345.16 720.43H345.17Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M345.36 725.76L259.59 850.95C250.31 864.5 231.8 867.96 218.25 858.68L93.0597 772.91C79.5097 763.63 76.0497 745.12 85.3297 731.57L97.7497 713.44C147.54 640.76 246.82 622.21 319.5 672L337.63 684.42C351.18 693.7 354.64 712.21 345.36 725.76Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M345.86 730.64L260.63 855.05C251.35 868.6 232.84 872.06 219.29 862.78L94.88 777.55C81.33 768.27 77.87 749.76 87.15 736.21L99.8 717.75C149.17 645.69 247.61 627.29 319.67 676.66L338.13 689.31C351.68 698.59 355.14 717.1 345.86 730.65V730.64Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M346.68 735.05L261.99 858.67C252.71 872.22 234.2 875.68 220.65 866.4L97.0304 781.71C83.4804 772.43 80.0204 753.92 89.3004 740.37L102.17 721.58C151.12 650.13 248.71 631.9 320.16 680.84L338.95 693.71C352.5 702.99 355.96 721.5 346.68 735.05Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M347.85 739L263.7 861.83C254.42 875.38 235.91 878.84 222.36 869.56L99.5304 785.41C85.9804 776.13 82.5204 757.62 91.8004 744.07L104.9 724.95C153.42 654.12 250.18 636.04 321.01 684.56L340.13 697.66C353.68 706.94 357.14 725.45 347.86 739H347.85Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M349.37 742.46L265.76 864.51C256.48 878.06 237.97 881.52 224.42 872.24L102.37 788.63C88.8202 779.35 85.3602 760.84 94.6402 747.29L107.96 727.84C156.06 657.63 251.98 639.7 322.19 687.8L341.64 701.12C355.19 710.4 358.65 728.91 349.37 742.46Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M351.26 745.43L268.18 866.69C258.9 880.24 240.39 883.7 226.84 874.42L105.58 791.34C92.0302 782.06 88.5702 763.55 97.8502 750L111.4 730.22C159.08 660.62 254.15 642.85 323.75 690.54L343.53 704.09C357.08 713.37 360.54 731.88 351.26 745.43Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M353.55 747.89L271.01 868.37C261.73 881.92 243.22 885.38 229.67 876.1L109.19 793.56C95.6396 784.28 92.1796 765.77 101.46 752.22L115.24 732.11C162.5 663.13 256.73 645.52 325.71 692.78L345.82 706.56C359.37 715.84 362.83 734.35 353.55 747.9V747.89Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M356.24 749.83L274.24 869.52C264.96 883.07 246.45 886.53 232.9 877.25L113.21 795.25C99.6601 785.97 96.2001 767.46 105.48 753.91L119.48 733.47C166.32 665.1 259.71 647.65 328.07 694.49L348.51 708.49C362.06 717.77 365.52 736.28 356.24 749.83Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M359.36 751.24L277.9 870.15C268.62 883.7 250.11 887.16 236.56 877.88L117.65 796.42C104.1 787.14 100.64 768.63 109.92 755.08L124.15 734.31C170.57 666.56 263.11 649.26 330.86 695.68L351.63 709.91C365.18 719.19 368.64 737.7 359.36 751.25V751.24Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M362.91 752.12L281.99 870.24C272.71 883.79 254.2 887.25 240.65 877.97L122.53 797.05C108.98 787.77 105.52 769.26 114.8 755.71L129.25 734.61C175.24 667.48 266.95 650.34 334.09 696.33L355.19 710.78C368.74 720.06 372.2 738.57 362.92 752.12H362.91Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M366.92 752.44L286.53 869.78C277.25 883.33 258.74 886.79 245.19 877.51L127.85 797.12C114.3 787.84 110.84 769.33 120.12 755.78L134.8 734.35C180.37 667.83 271.24 650.85 337.76 696.42L359.19 711.1C372.74 720.38 376.2 738.89 366.92 752.44Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M371.41 752.19L291.56 868.74C282.28 882.29 263.77 885.75 250.22 876.47L133.67 796.62C120.12 787.34 116.66 768.83 125.94 755.28L140.84 733.52C185.99 667.62 276.01 650.79 341.92 695.94L363.68 710.84C377.23 720.12 380.69 738.63 371.41 752.18V752.19Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M376.38 751.37L297.07 867.13C287.79 880.68 269.28 884.14 255.73 874.86L139.97 795.55C126.42 786.27 122.96 767.76 132.24 754.21L147.37 732.12C192.1 666.83 281.28 650.17 346.57 694.89L368.66 710.02C382.21 719.3 385.67 737.81 376.39 751.36L376.38 751.37Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M381.85 749.96L303.08 864.94C293.8 878.49 275.29 881.95 261.74 872.67L146.76 793.9C133.21 784.62 129.75 766.11 139.03 752.56L154.39 730.15C198.7 665.48 287.04 648.97 351.71 693.28L374.12 708.64C387.67 717.92 391.13 736.43 381.85 749.98V749.96Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M387.85 747.96L309.62 862.15C300.34 875.7 281.83 879.16 268.28 869.88L154.09 791.65C140.54 782.37 137.08 763.86 146.36 750.31L161.94 727.57C205.82 663.52 293.32 647.16 357.38 691.05L380.12 706.63C393.67 715.91 397.13 734.42 387.85 747.97V747.96Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M394.39 745.35L316.7 858.76C307.42 872.31 288.91 875.77 275.36 866.49L161.95 788.8C148.4 779.52 144.94 761.01 154.22 747.46L170.03 724.39C213.49 660.95 300.15 644.76 363.59 688.22L386.66 704.03C400.21 713.31 403.67 731.82 394.39 745.37V745.35Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M401.48 742.11L324.32 854.73C315.04 868.28 296.53 871.74 282.98 862.46L170.36 785.3C156.81 776.02 153.35 757.51 162.63 743.96L178.66 720.56C221.7 657.74 307.52 641.7 370.34 684.74L393.74 700.77C407.29 710.05 410.75 728.56 401.47 742.11H401.48Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M409.14 738.24L332.52 850.08C323.24 863.63 304.73 867.09 291.18 857.81L179.34 781.19C165.79 771.91 162.33 753.4 171.61 739.85L187.87 716.12C230.49 653.91 315.46 638.03 377.67 680.65L401.4 696.91C414.95 706.19 418.41 724.7 409.13 738.25L409.14 738.24Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M417.38 733.73L341.3 844.78C332.02 858.33 313.51 861.79 299.96 852.51L188.91 776.43C175.36 767.15 171.9 748.64 181.18 735.09L197.67 711.03C239.87 649.44 324 633.72 385.59 675.91L409.65 692.4C423.2 701.68 426.66 720.19 417.38 733.74V733.73Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M426.23 728.57L350.69 838.84C341.41 852.39 322.9 855.85 309.35 846.57L199.08 771.03C185.53 761.75 182.07 743.24 191.35 729.69L208.06 705.3C249.83 644.32 333.13 628.76 394.1 670.53L418.49 687.24C432.04 696.52 435.5 715.03 426.22 728.58L426.23 728.57Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M435.7 722.73L360.7 832.21C351.42 845.76 332.91 849.22 319.36 839.94L209.88 764.94C196.33 755.66 192.87 737.15 202.15 723.6L219.09 698.88C260.44 638.52 342.89 623.11 403.25 664.46L427.97 681.4C441.52 690.68 444.98 709.19 435.7 722.74V722.73Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M443.73 717.43L369.26 826.12C359.98 839.67 341.47 843.13 327.92 833.85L219.23 759.38C205.68 750.1 202.22 731.59 211.5 718.04L228.66 692.99C269.59 633.25 351.2 618 410.94 658.92L435.99 676.08C449.54 685.36 453 703.87 443.72 717.42L443.73 717.43Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M451.24 711.99L377.31 819.9C368.03 833.45 349.52 836.91 335.97 827.63L228.06 753.7C214.51 744.42 211.05 725.91 220.33 712.36L237.72 686.98C278.23 627.85 359 612.76 418.13 653.27L443.51 670.66C457.06 679.94 460.52 698.45 451.24 712V711.99Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M458.4 706.41L385.01 813.53C375.73 827.08 357.22 830.54 343.67 821.26L236.55 747.87C223 738.59 219.54 720.08 228.82 706.53L246.43 680.82C286.52 622.31 366.44 607.37 424.96 647.46L450.67 665.07C464.22 674.35 467.68 692.86 458.4 706.41Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M465.27 700.75L392.42 807.09C383.14 820.64 364.63 824.1 351.08 814.82L244.74 741.97C231.19 732.69 227.73 714.18 237.01 700.63L254.85 674.59C294.51 616.69 373.6 601.91 431.5 641.58L457.54 659.42C471.09 668.7 474.55 687.21 465.27 700.76V700.75Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M471.9 695.06L399.59 800.61C390.31 814.16 371.8 817.62 358.25 808.34L252.7 736.03C239.15 726.75 235.69 708.24 244.97 694.69L263.04 668.32C302.28 611.04 380.53 596.42 437.81 635.66L464.18 653.73C477.73 663.01 481.19 681.52 471.91 695.07L471.9 695.06Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M478.32 689.41L406.55 794.18C397.27 807.73 378.76 811.19 365.21 801.91L260.44 730.14C246.89 720.86 243.43 702.35 252.71 688.8L271 662.1C309.82 605.44 387.22 590.97 443.89 629.79L470.59 648.08C484.14 657.36 487.6 675.87 478.32 689.42V689.41Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M484.6 683.87L413.36 787.85C404.08 801.4 385.57 804.86 372.02 795.58L268.04 724.34C254.49 715.06 251.03 696.55 260.31 683L278.83 655.97C317.23 599.92 393.79 585.61 449.84 624.01L476.87 642.53C490.42 651.81 493.88 670.32 484.6 683.87Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M490.77 678.48L420.07 781.67C410.79 795.22 392.28 798.68 378.73 789.4L275.54 718.7C261.99 709.42 258.53 690.91 267.81 677.36L286.55 650C324.53 594.57 400.25 580.42 455.68 618.39L483.04 637.13C496.59 646.41 500.05 664.92 490.77 678.47V678.48Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M496.89 673.33L426.73 775.74C417.45 789.29 398.94 792.75 385.39 783.47L282.98 713.31C269.43 704.03 265.97 685.52 275.25 671.97L294.22 644.28C331.77 589.46 406.65 575.47 461.47 613.02L489.16 631.99C502.71 641.27 506.17 659.78 496.89 673.33Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M503.01 668.46L433.39 770.08C424.11 783.63 405.6 787.09 392.05 777.81L290.43 708.19C276.88 698.91 273.42 680.4 282.7 666.85L301.9 638.83C339.03 584.63 413.07 570.79 467.27 607.93L495.29 627.13C508.84 636.41 512.3 654.92 503.02 668.47L503.01 668.46Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M509.17 663.94L440.09 764.78C430.81 778.33 412.3 781.79 398.75 772.51L297.91 703.43C284.36 694.15 280.9 675.64 290.18 662.09L309.6 633.74C346.31 580.16 419.51 566.48 473.09 603.19L501.44 622.61C514.99 631.89 518.45 650.4 509.17 663.95V663.94Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M515.42 659.84L446.88 759.89C437.6 773.44 419.09 776.9 405.54 767.62L305.49 699.08C291.94 689.8 288.48 671.29 297.76 657.74L317.41 629.06C353.7 576.09 426.05 562.57 479.02 598.86L507.7 618.51C521.25 627.79 524.71 646.3 515.43 659.85L515.42 659.84Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M521.81 656.21L453.8 755.48C444.52 769.03 426.01 772.49 412.46 763.21L313.19 695.2C299.64 685.92 296.18 667.41 305.46 653.86L325.33 624.85C361.2 572.5 432.71 559.13 485.06 595L514.07 614.87C527.62 624.15 531.08 642.66 521.8 656.21H521.81Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M528.39 653.12L460.92 751.6C451.64 765.15 433.13 768.61 419.58 759.33L321.1 691.86C307.55 682.58 304.09 664.07 313.37 650.52L333.47 621.18C368.91 569.44 439.59 556.24 491.32 591.68L520.66 611.78C534.21 621.06 537.67 639.57 528.39 653.12Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M535.21 650.62L468.28 748.32C459 761.87 440.49 765.33 426.94 756.05L329.24 689.12C315.69 679.84 312.23 661.33 321.51 647.78L341.83 618.11C376.85 566.99 446.68 553.94 497.8 588.96L527.47 609.28C541.02 618.56 544.48 637.07 535.2 650.62H535.21Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M542.32 648.79L475.93 745.7C466.65 759.25 448.14 762.71 434.59 753.43L337.68 687.04C324.13 677.76 320.67 659.25 329.95 645.7L350.5 615.7C385.1 565.2 454.09 552.3 504.6 586.9L534.6 607.45C548.15 616.73 551.61 635.24 542.33 648.79H542.32Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M549.76 647.69L483.91 743.81C474.63 757.36 456.12 760.82 442.57 751.54L346.45 685.69C332.9 676.41 329.44 657.9 338.72 644.35L359.5 614.02C393.68 564.13 461.83 551.4 511.72 585.57L542.05 606.35C555.6 615.63 559.06 634.14 549.78 647.69H549.76Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M557.58 647.37L492.26 742.71C482.98 756.26 464.47 759.72 450.92 750.44L355.58 685.12C342.03 675.84 338.57 657.33 347.85 643.78L368.85 613.12C402.61 563.85 469.91 551.27 519.19 585.03L549.85 606.03C563.4 615.31 566.86 633.82 557.58 647.37Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M565.83 647.9L501.05 742.45C491.77 756 473.26 759.46 459.71 750.18L365.16 685.4C351.61 676.12 348.15 657.61 357.43 644.06L378.66 613.08C411.99 564.42 478.46 552 527.12 585.34L558.1 606.57C571.65 615.85 575.11 634.36 565.83 647.91V647.9Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M574.56 649.34L510.32 743.11C501.04 756.66 482.53 760.12 468.98 750.84L375.21 686.6C361.66 677.32 358.2 658.81 367.48 645.26L388.93 613.95C421.84 565.91 487.47 553.65 535.51 586.56L566.82 608.01C580.37 617.29 583.83 635.8 574.55 649.35L574.56 649.34Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M583.82 651.75L520.12 744.73C510.84 758.28 492.33 761.74 478.78 752.46L385.8 688.76C372.25 679.48 368.79 660.97 378.07 647.42L399.75 615.78C432.24 568.36 497.02 556.25 544.45 588.74L576.09 610.42C589.64 619.7 593.1 638.21 583.82 651.76V651.75Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M593.66 655.2L530.5 747.4C521.22 760.95 502.71 764.41 489.16 755.13L396.96 691.97C383.41 682.69 379.95 664.18 389.23 650.63L411.13 618.66C443.2 571.85 507.14 559.9 553.95 591.97L585.92 613.87C599.47 623.15 602.93 641.66 593.65 655.21L593.66 655.2Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M604.12 659.75L541.5 751.16C532.22 764.71 513.71 768.17 500.16 758.89L408.75 696.27C395.2 686.99 391.74 668.48 401.02 654.93L423.15 622.63C454.8 576.44 517.9 564.64 564.09 596.29L596.39 618.42C609.94 627.7 613.4 646.21 604.12 659.76V659.75Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M615.25 665.46L553.16 756.09C543.88 769.64 525.37 773.1 511.82 763.82L421.19 701.73C407.64 692.45 404.18 673.94 413.46 660.39L435.82 627.76C467.04 582.18 529.3 570.55 574.88 601.77L607.51 624.13C621.06 633.41 624.52 651.92 615.24 665.47L615.25 665.46Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M627.11 672.38L565.56 762.22C556.28 775.77 537.77 779.23 524.22 769.95L434.38 708.4C420.83 699.12 417.37 680.61 426.65 667.06L449.23 634.1C480.03 589.14 541.45 577.66 586.41 608.46L619.37 631.04C632.92 640.32 636.38 658.83 627.1 672.38H627.11Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M639.73 680.6L578.72 769.65C569.44 783.2 550.93 786.66 537.38 777.38L448.33 716.37C434.78 707.09 431.32 688.58 440.6 675.03L463.41 641.74C493.79 597.39 554.37 586.07 598.71 616.45L632 639.26C645.55 648.54 649.01 667.05 639.73 680.6Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M653.17 690.16L592.7 778.43C583.42 791.98 564.91 795.44 551.36 786.16L463.09 725.69C449.54 716.41 446.08 697.9 455.36 684.35L478.39 650.73C508.35 607 568.08 595.84 611.81 625.8L645.43 648.83C658.98 658.11 662.44 676.62 653.16 690.17L653.17 690.16Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M668.1 701.62L608.17 789.1C598.89 802.65 580.38 806.11 566.83 796.83L479.35 736.9C465.8 727.62 462.34 709.11 471.62 695.56L494.88 661.61C524.42 618.5 583.31 607.49 626.42 637.03L660.37 660.29C673.92 669.57 677.38 688.08 668.1 701.63V701.62Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M682.27 713.88L622.87 800.58C613.59 814.13 595.08 817.59 581.53 808.31L494.83 748.91C481.28 739.63 477.82 721.12 487.1 707.57L510.59 673.29C539.7 630.79 597.76 619.94 640.26 649.06L674.54 672.55C688.09 681.83 691.55 700.34 682.27 713.89V713.88Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M693.46 725.4L634.6 811.31C625.32 824.86 606.81 828.32 593.26 819.04L507.35 760.18C493.8 750.9 490.34 732.39 499.62 718.84L523.33 684.23C552.02 642.35 609.23 631.66 651.12 660.35L685.73 684.06C699.28 693.34 702.74 711.85 693.46 725.4Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M701.9 736.25L643.58 821.38C634.3 834.93 615.789 838.39 602.239 829.11L517.109 770.79C503.559 761.51 500.1 743 509.38 729.45L533.32 694.51C561.59 653.24 617.959 642.71 659.229 670.98L694.169 694.92C707.719 704.2 711.18 722.71 701.9 736.26V736.25Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M707.82 746.48L650.039 830.82C640.759 844.37 622.25 847.83 608.7 838.55L524.359 780.77C510.809 771.49 507.35 752.98 516.63 739.43L540.789 704.16C568.639 663.51 624.17 653.13 664.82 680.98L700.09 705.14C713.64 714.42 717.1 732.93 707.82 746.48Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M711.45 756.16L654.21 839.71C644.93 853.26 626.42 856.72 612.87 847.44L529.32 790.2C515.77 780.92 512.31 762.41 521.59 748.86L545.98 713.26C573.41 673.23 628.09 663.01 668.13 690.43L703.73 714.82C717.28 724.1 720.74 742.61 711.46 756.16H711.45Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M713.02 765.34L656.32 848.11C647.04 861.66 628.53 865.12 614.98 855.84L532.21 799.14C518.66 789.86 515.2 771.35 524.48 757.8L549.09 721.87C576.09 682.45 629.94 672.39 669.36 699.39L705.29 724C718.84 733.28 722.3 751.79 713.02 765.34Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M712.75 774.08L656.58 856.06C647.3 869.61 628.79 873.07 615.24 863.79L533.26 807.62C519.71 798.34 516.25 779.83 525.53 766.28L550.37 730.02C576.95 691.22 629.96 681.31 668.76 707.9L705.02 732.74C718.57 742.02 722.03 760.53 712.75 774.08Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M710.87 782.43L655.24 863.63C645.96 877.18 627.45 880.64 613.9 871.36L532.7 815.73C519.15 806.45 515.69 787.94 524.97 774.39L550.04 737.8C576.2 699.61 628.36 689.87 666.55 716.03L703.14 741.1C716.69 750.38 720.15 768.89 710.87 782.44V782.43Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M707.61 790.47L652.52 870.88C643.24 884.43 624.73 887.89 611.18 878.61L530.77 823.52C517.22 814.24 513.76 795.73 523.04 782.18L548.33 745.26C574.07 707.69 625.39 698.1 662.96 723.84L699.88 749.13C713.43 758.41 716.89 776.92 707.61 790.47Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M703.2 798.25L648.65 877.88C639.37 891.43 620.86 894.89 607.31 885.61L527.68 831.06C514.13 821.78 510.67 803.27 519.95 789.72L545.47 752.47C570.79 715.52 621.27 706.08 658.22 731.4L695.47 756.92C709.02 766.2 712.48 784.71 703.2 798.26V798.25Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M697.86 805.82L643.85 884.66C634.57 898.21 616.06 901.67 602.51 892.39L523.67 838.38C510.12 829.1 506.66 810.59 515.94 797.04L541.68 759.46C566.57 723.12 616.21 713.85 652.55 738.74L690.13 764.48C703.68 773.76 707.14 792.27 697.86 805.82Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M691.83 813.25L638.36 891.31C629.08 904.86 610.57 908.32 597.02 899.04L518.96 845.57C505.41 836.29 501.95 817.78 511.23 804.23L537.2 766.32C561.67 730.6 610.47 721.48 646.19 745.95L684.1 771.92C697.65 781.2 701.11 799.71 691.83 813.26V813.25Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M685.33 820.59L632.39 897.86C623.11 911.41 604.6 914.87 591.05 905.59L513.78 852.65C500.23 843.37 496.77 824.86 506.05 811.31L532.25 773.07C556.3 737.96 604.26 729 639.36 753.05L677.6 779.25C691.15 788.53 694.61 807.04 685.33 820.59Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M678.58 827.9L626.179 904.38C616.899 917.93 598.39 921.39 584.84 912.11L508.359 859.71C494.809 850.43 491.35 831.92 500.63 818.37L527.049 779.8C550.679 745.31 597.79 736.5 632.28 760.13L670.849 786.55C684.399 795.83 687.86 814.34 678.58 827.89V827.9Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M671.83 835.25L619.97 910.95C610.69 924.5 592.18 927.96 578.63 918.68L502.93 866.82C489.38 857.54 485.92 839.03 495.2 825.48L521.85 786.58C545.06 752.71 591.33 744.06 625.21 767.26L664.11 793.91C677.66 803.19 681.12 821.7 671.84 835.25H671.83Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M665.28 842.69L613.96 917.6C604.68 931.15 586.17 934.61 572.62 925.33L497.71 874.01C484.16 864.73 480.7 846.22 489.98 832.67L516.85 793.44C539.64 760.18 585.07 751.69 618.33 774.48L657.56 801.35C671.11 810.63 674.57 829.14 665.29 842.69H665.28Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M659.18 850.28L608.4 924.41C599.12 937.96 580.61 941.42 567.06 932.14L492.93 881.36C479.38 872.08 475.92 853.57 485.2 840.02L512.3 800.46C534.66 767.82 579.25 759.48 611.9 781.85L651.46 808.95C665.01 818.23 668.47 836.74 659.19 850.29L659.18 850.28Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M653.75 858.07L603.5 931.41C594.22 944.96 575.71 948.42 562.16 939.14L488.82 888.89C475.27 879.61 471.81 861.1 481.09 847.55L508.41 807.67C530.35 775.64 574.1 767.47 606.13 789.41L646.01 816.73C659.56 826.01 663.02 844.52 653.74 858.07H653.75Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M649.219 866.14L599.51 938.7C590.23 952.25 571.719 955.71 558.169 946.43L485.609 896.72C472.059 887.44 468.6 868.93 477.88 855.38L505.429 815.17C526.949 783.76 569.86 775.74 601.27 797.26L641.479 824.81C655.029 834.09 658.49 852.6 649.21 866.15L649.219 866.14Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M645.81 874.53L596.64 946.3C587.36 959.85 568.85 963.31 555.3 954.03L483.53 904.86C469.98 895.58 466.52 877.07 475.8 863.52L503.58 822.98C524.68 792.19 566.74 784.32 597.54 805.42L638.08 833.2C651.63 842.48 655.09 860.99 645.81 874.54V874.53Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M643.76 883.31L595.13 954.29C585.85 967.84 567.34 971.3 553.79 962.02L482.81 913.39C469.26 904.11 465.8 885.6 475.08 872.05L503.08 831.18C523.76 801 564.98 793.3 595.16 813.97L636.03 841.97C649.58 851.25 653.04 869.76 643.76 883.31Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M643.29 892.53L595.2 962.73C585.92 976.28 567.41 979.74 553.86 970.46L483.66 922.37C470.11 913.09 466.65 894.58 475.93 881.03L504.16 839.83C524.41 810.27 564.8 802.72 594.36 822.97L635.56 851.2C649.11 860.48 652.57 878.99 643.29 892.54V892.53Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M644.63 902.25L597.08 971.66C587.8 985.21 569.29 988.67 555.74 979.39L486.33 931.84C472.78 922.56 469.32 904.05 478.6 890.5L507.05 848.97C526.88 820.02 566.42 812.63 595.37 832.46L636.9 860.91C650.45 870.19 653.91 888.7 644.63 902.25Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M648 912.54L600.98 981.17C591.7 994.72 573.19 998.18 559.64 988.9L491.01 941.88C477.46 932.6 474 914.09 483.28 900.54L511.96 858.68C531.37 830.35 570.07 823.12 598.4 842.53L640.26 871.21C653.81 880.49 657.27 899 647.99 912.55L648 912.54Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M651.85 920.66L605.37 988.5C596.09 1002.05 577.58 1005.51 564.03 996.23L496.19 949.75C482.64 940.47 479.18 921.96 488.46 908.41L517.37 866.22C536.36 838.5 574.22 831.43 601.93 850.42L644.12 879.33C657.67 888.61 661.13 907.12 651.85 920.67V920.66Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M655.07 925.9L609.13 992.96C599.85 1006.51 581.34 1009.97 567.79 1000.69L500.73 954.75C487.18 945.47 483.72 926.96 493 913.41L522.13 870.89C540.7 843.79 577.71 836.87 604.81 855.44L647.33 884.57C660.88 893.85 664.34 912.36 655.06 925.91L655.07 925.9Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M659.42 931.49L614.02 997.76C604.74 1011.31 586.23 1014.77 572.68 1005.49L506.41 960.09C492.86 950.81 489.4 932.3 498.68 918.75L528.04 875.9C546.18 849.42 582.36 842.66 608.84 860.8L651.69 890.16C665.24 899.44 668.7 917.95 659.42 931.5V931.49Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M664.81 937.38L619.95 1002.87C610.67 1016.42 592.16 1019.88 578.61 1010.6L513.12 965.74C499.57 956.46 496.11 937.95 505.39 924.4L534.97 881.22C552.69 855.35 588.03 848.75 613.89 866.47L657.07 896.05C670.62 905.33 674.08 923.84 664.8 937.39L664.81 937.38Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M671.15 943.52L626.82 1008.22C617.54 1021.77 599.03 1025.23 585.48 1015.95L520.78 971.62C507.23 962.34 503.77 943.83 513.05 930.28L542.86 886.77C560.16 861.52 594.65 855.07 619.91 872.37L663.42 902.18C676.97 911.46 680.43 929.97 671.15 943.52Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M678.34 949.85L634.55 1013.76C625.27 1027.31 606.76 1030.77 593.21 1021.49L529.3 977.7C515.75 968.42 512.29 949.91 521.57 936.36L551.6 892.52C568.48 867.88 602.13 861.6 626.77 878.47L670.61 908.5C684.16 917.78 687.62 936.29 678.34 949.84V949.85Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M686.27 956.32L643.02 1019.45C633.74 1033 615.23 1036.46 601.68 1027.18L538.55 983.93C525 974.65 521.54 956.14 530.82 942.59L561.08 898.42C577.54 874.4 610.35 868.27 634.37 884.72L678.54 914.98C692.09 924.26 695.55 942.77 686.27 956.32Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M694.86 962.88L652.15 1025.22C642.87 1038.77 624.36 1042.23 610.81 1032.95L548.47 990.24C534.92 980.96 531.46 962.45 540.74 948.9L571.23 904.4C587.26 881 619.23 875.02 642.64 891.06L687.14 921.55C700.69 930.83 704.15 949.34 694.87 962.89L694.86 962.88Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M704.02 969.48L661.85 1031.04C652.57 1044.59 634.06 1048.05 620.51 1038.77L558.95 996.6C545.4 987.32 541.94 968.81 551.22 955.26L581.93 910.43C597.54 887.64 628.67 881.82 651.46 897.44L696.29 928.15C709.84 937.43 713.3 955.94 704.02 969.49V969.48Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M713.63 976.06L672 1036.83C662.72 1050.38 644.21 1053.84 630.66 1044.56L569.89 1002.93C556.34 993.65 552.88 975.14 562.16 961.59L593.1 916.43C608.29 894.26 638.58 888.6 660.75 903.79L705.91 934.73C719.46 944.01 722.92 962.52 713.64 976.07L713.63 976.06Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M723.62 982.56L682.52 1042.55C673.24 1056.1 654.73 1059.56 641.18 1050.28L581.19 1009.18C567.64 999.9 564.18 981.39 573.46 967.84L604.62 922.35C619.39 900.79 648.83 895.29 670.39 910.06L715.88 941.22C729.43 950.5 732.89 969.01 723.61 982.56H723.62Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M733.88 988.95L693.32 1048.15C684.04 1061.7 665.53 1065.16 651.98 1055.88L592.78 1015.32C579.23 1006.04 575.77 987.53 585.05 973.98L616.44 928.16C630.79 907.22 659.39 901.87 680.33 916.22L726.15 947.61C739.7 956.89 743.16 975.4 733.88 988.95Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M744.32 995.15L704.3 1053.57C695.02 1067.12 676.51 1070.58 662.96 1061.3L604.54 1021.28C590.99 1012 587.53 993.49 596.81 979.94L628.43 933.79C642.35 913.47 670.12 908.28 690.44 922.2L736.59 953.82C750.14 963.1 753.6 981.61 744.32 995.16V995.15Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M754.84 1001.13L715.36 1058.76C706.08 1072.31 687.57 1075.77 674.02 1066.49L616.39 1027.01C602.84 1017.73 599.38 999.22 608.66 985.67L640.5 939.19C654 919.48 680.92 914.45 700.63 927.95L747.11 959.79C760.66 969.07 764.12 987.58 754.84 1001.13Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M765.35 1006.83L726.41 1063.67C717.13 1077.22 698.62 1080.68 685.07 1071.4L628.23 1032.46C614.68 1023.18 611.22 1004.67 620.5 991.12L652.57 944.31C665.65 925.22 691.73 920.34 710.82 933.42L757.63 965.49C771.18 974.77 774.64 993.28 765.36 1006.83H765.35Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M775.75 1012.2L737.34 1068.26C728.06 1081.81 709.55 1085.27 696 1075.99L639.94 1037.58C626.39 1028.3 622.93 1009.79 632.21 996.24L664.5 949.1C677.16 930.62 702.4 925.91 720.87 938.56L768.01 970.85C781.56 980.13 785.02 998.64 775.74 1012.19L775.75 1012.2Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M785.94 1017.18L748.07 1072.45C738.79 1086 720.28 1089.46 706.73 1080.18L651.46 1042.31C637.91 1033.03 634.45 1014.52 643.73 1000.97L676.25 953.5C688.49 935.64 712.88 931.08 730.74 943.32L778.21 975.84C791.76 985.12 795.22 1003.63 785.94 1017.18Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M795.83 1021.71L758.5 1076.2C749.22 1089.75 730.71 1093.21 717.16 1083.93L662.67 1046.6C649.12 1037.32 645.66 1018.81 654.94 1005.26L687.68 957.46C699.49 940.22 723.05 935.81 740.3 947.63L788.1 980.37C801.65 989.65 805.11 1008.16 795.83 1021.71Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M805.33 1025.76L768.54 1079.46C759.26 1093.01 740.75 1096.47 727.2 1087.19L673.5 1050.4C659.95 1041.12 656.49 1022.61 665.77 1009.06L698.74 960.93C710.13 944.3 732.85 940.06 749.48 951.45L797.61 984.42C811.16 993.7 814.62 1012.21 805.34 1025.76H805.33Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M814.33 1029.26L778.08 1082.18C768.8 1095.73 750.29 1099.19 736.74 1089.91L683.82 1053.66C670.27 1044.38 666.81 1025.87 676.09 1012.32L709.29 963.86C720.26 947.85 742.13 943.76 758.15 954.73L806.61 987.93C820.16 997.21 823.62 1015.72 814.34 1029.27L814.33 1029.26Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M822.75 1032.17L787.04 1084.3C777.76 1097.85 759.25 1101.31 745.7 1092.03L693.57 1056.32C680.02 1047.04 676.56 1028.53 685.84 1014.98L719.26 966.2C729.81 950.8 750.84 946.87 766.24 957.42L815.02 990.84C828.57 1000.12 832.03 1018.63 822.75 1032.18V1032.17Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M830.48 1034.42L795.3 1085.76C786.02 1099.31 767.51 1102.77 753.96 1093.49L702.62 1058.31C689.07 1049.03 685.61 1030.52 694.89 1016.97L728.54 967.86C738.67 953.08 758.86 949.31 773.64 959.43L822.75 993.08C836.3 1002.36 839.76 1020.87 830.48 1034.42Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M837.43 1035.97L802.79 1086.53C793.51 1100.08 775 1103.54 761.45 1094.26L710.89 1059.62C697.34 1050.34 693.88 1031.83 703.16 1018.28L737.03 968.84C746.73 954.68 766.08 951.06 780.25 960.76L829.69 994.63C843.24 1003.91 846.7 1022.42 837.42 1035.97H837.43Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M744.667 968.945L710.574 1018.72C701.292 1032.27 704.753 1050.78 718.304 1060.06L768.077 1094.15C781.627 1103.44 800.137 1099.97 809.419 1086.42L843.512 1036.65C852.794 1023.1 849.333 1004.59 835.783 995.308L786.009 961.215C772.459 951.933 753.949 955.394 744.667 968.945Z"
                        stroke="#231F20"
                        stroke-miterlimit="10"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_16_962">
                        <rect width="849.22" height="1099.97" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    </div>
</template>

<script>
let shapes = 4;
export default {
    props: {
        shapeIndex: {
            type: Number,
            default: 0,
        },
        color: {
            type: String,
        },
    },
    name: "Shapes",
    components: {},
    mixins: [],
    data() {
        return {
            index: parseInt(Math.random() * shapes),
        };
    },
    methods: {
        reload() {
            this.index = parseInt(Math.random() * shapes);
            this.setColor();
        },
        setColor() {
            this.$nextTick(() => {
                if (this.color) {
                    let el = this.$refs.svgContainer;
                    let svgs = el.querySelectorAll("svg");
                    svgs.forEach((svg) => {
                        let paths = svg.querySelectorAll("path");
                        paths.forEach((path) => {
                            if (this.color && (this.color.indexOf("#") !== 0 || this.color.indexOf("rgb") !== 0)) {
                                path.setAttribute("stroke", this.color);
                            } else {
                                path.setAttribute("stroke", null);
                                path.setAttribute("class", this.color);
                            }
                        });
                    });
                }
            });
        },
    },
    created() {},
    mounted() {
        if (this.shapeIndex) {
            this.index = this.shapeIndex;
        }
        this.setColor();
    },
};
</script>

<style scoped lang="scss"></style>
