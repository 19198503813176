<template>
    <div class="flex flex-col p-9 h-full max-w-md mx-auto items-center mt-9 md:justify-center md:mt-0">
        <div class="text-6xl">
            <i class="fa-thin fa-circle-user"></i>
        </div>
        <div class="text-lg py-9">Select a username for engaging with sessions. Choose any name you prefer.</div>
        <div class="mb-9 w-full flex flex-row">
            <!-- <div class="w-16 h-16 relative">
                <div class="w-16 absolute top-0 max-h-[192px] z-10 overflow-y-scroll overflow-x-hidden scrollbar">
                    <div v-for="color in colors" :class="`w-16 h-16 bg-${color}-200`"></div>
                </div>
                -->
            <input
                type="text"
                class="bg-white text-black-900 w-full text-2xl p-4"
                placeholder="Set your name for the event!"
                v-model="username"
                @keypress.enter="saveNewUsername"
            />
        </div>
        <div class="w-full">
            <div class="flex justify-between w-full gap-3">
                <a @click="saveNewUsername" class="profile-form-button grow">Save</a>
                <a @click="generateNewUserName" class="profile-form-button circle">
                    <i class="fa-solid fa-arrows-rotate"></i>
                </a>
                <a @click="$emit('close')" class="profile-form-button circle">
                    <i class="fa-solid fa-x"></i>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { generateRandomUsername, setUsername } from "../auth";
import { getColor, colors } from "../colors-table";
export default {
    props: {
        showUserNameInput: {
            type: Boolean,
            default: false,
        },
    },
    name: "ProfileForm",
    components: {},
    mixins: [],
    data() {
        return {
            colors: Object.values(colors).filter((v, i, a) => a.indexOf(v) === i),
        };
    },
    computed: {
        username: {
            get() {
                return this.$store.state.user.username;
            },
            set(value) {
                this.$store.state.user.username = value;
            },
        },
    },
    methods: {
        saveNewUsername() {
            this.$store.commit("setUsername", this.username);
            this.$emit("close");
        },
        saveNewUsername() {
            setUsername(this.username);
            this.$emit("close");
        },
        generateNewUserName() {
            this.username = generateRandomUsername();
        },
    },
    created() {},
    mounted() {},
};
</script>

<style scoped lang="scss"></style>
